<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-card style="height: 100%">
          <v-card-title>{{ $t('CreateFluxo.generalData') }}</v-card-title>
          <v-card-text>
            {{ $t('CreateFluxo.flowName') }}
            <v-text-field v-model="nome" solo></v-text-field>
            {{ $t('CreateFluxo.channel') }}
            <v-select
              solo
              v-model="canal"
              :items="canais"
              item-text="channel_Name"
              item-value="channel_Id"
            ></v-select>
            {{ $t('CreateFluxo.initialMessage') }}
            <v-textarea v-model="mensagemFluxo" counter solo></v-textarea>
            {{ $t('CreateFluxo.exitMessage') }}
            <v-textarea v-model="mensagemExitFluxo" counter solo></v-textarea>
            {{ $t('CreateFluxo.leaveMessage') }}
            <v-textarea v-model="mensagemLeaveFluxo" counter solo></v-textarea>
            <v-checkbox
              v-model="sendEndDate"
              :label="$t('CreateFluxo.sendEndDate')">
            </v-checkbox>
            <div class="d-flex">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="dataInicio"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataInicio"
                    :label="$t('CreateFluxo.startDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dataInicio" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    {{ $t('Guid.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu1.save(dataInicio)"
                  >
                  {{ $t('Guid.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu
                v-if="sendEndDate" 
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="dataFinal"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataFinal"
                    :label="$t('CreateFluxo.finalDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dataFinal" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    {{ $t('Guid.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(dataFinal)"
                  >
                    {{ $t('Guid.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </v-card-text>
          <v-card-actions style="float: right">
            <v-btn @click="cadastrar" color="primary"> {{ $t('Guid.next') }} </v-btn>
            <v-btn
              :disabled="canBeExport"
              color="blue-grey"
              @click="exportFlow">
              {{ $t('Guid.export') }}
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              @click="closeCreate"
            >
                {{ $t('Guid.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snack" timeout="3000">
      {{ $t(snackText) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snack = false">
          {{ $t('Guid.close') }}
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';

export default {
  props: {
    id: {
      required: false,
    },
    name: {
      required: false,
    },
    channel: {
      required: false,
    },
    message: {
      required: false,
    },
    startDate: {
      required: false,
    },
    endDate: {
      required: false,
    },
    flowExitMessage: {
      required: false,
    },
    flowLeaveMessage: {
      required: false,
    },
  },
  data() {
    return {
      token: JSON.parse(localStorage.getItem("authUser")).token,
      canais: [],

      flowId: "",
      nome: "",
      canal: "",
      mensagemFluxo: "",
      mensagemExitFluxo: "",
      mensagemLeaveFluxo: "",

      dataInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dataFinal: null,
      menu1: false,
      menu2: false,

      mensagemInicioAtendimento: "",
      client: [],
      functionality: [],
      havePermissions: true,
      canBeExport: true,
      sendEndDate: false,

      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  computed: {
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getCurrentCustomer'])
  },
  methods: {
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)], {
        type: "application/json",
      }));
      
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    exportFlow() {
      this.$http.
        get(`Export_Flow?Customer_Id=${this.client.customer_Id}&Flow_Id=${this.id}`,{
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.forceFileDownload(r, this.name);
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async cadastrar() {
      if(this.nome === "" && this.mensagemFluxo === "") {
        this.$router.push({ name: 'fluxo' })
      } else if((this.dataInicio == this.dataFinal || this.dataInicio > this.dataFinal) && this.sendEndDate) {
        this.snack = true;
        this.snackColor = "warning";
        this.snackText = "CreateFluxo.warningDates"
      }
      else {
        this.$router.push(
          {
            name: "view-fluxo",
            params: {
              id: this.id,
              name: this.nome,
              channel: this.canal,
              startDate: this.dataInicio,
              flowMessage: this.mensagemFluxo,
              endDate: this.sendEndDate ? this.dataFinal : null,
              flowExitMessage: this.mensagemExitFluxo,
              flowLeaveMessage: this.mensagemLeaveFluxo,
            },
          },
          () => {}
        );
      }
    },
    listChannel() {
      this.$http
        .get(`List_Channel?Customer_Id=${this.client.customer_Id}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.canais = r.data;
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    closeCreate() {
      this.$router.push({ name: 'fluxo' });
    },
    setInitialVars() {
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Flow");

      if (this.$store.state.currentCustomer == null) {
        this.$router.push({ name: 'fluxo' });
      } else {
        this.client = this.getCurrentCustomer;
      }

      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' })
      }

      this.havePermissions = false
    },
    reloadData(){
      this.flowId = this.id;
      this.nome = this.name;
      this.canal = this.channel;
      this.mensagemFluxo = this.message;
      this.dataInicio = this.setDate(this.startDate);
      this.dataFinal = this.setDate(this.endDate);
      this.mensagemExitFluxo = this.flowExitMessage;
      this.mensagemLeaveFluxo = this.flowLeaveMessage;
      this.canBeExport = false;
    },
    setDate(date) {
      let newDate = new Date(date + 'Z');

      if(newDate.getFullYear() == 0) {
        newDate = new Date();
        return newDate.toISOString().split('T')[0];
      } else {
        return newDate.toISOString().split('T')[0];
      }
    }
  },
  watch: {
    name(newId, oldId){
      if(newId != oldId) {
        this.reloadData();
      }
    }
  },
  mounted() {
    if(this.$store.state.permissions.length == 0) {
      setTimeout(() => {
        this.setInitialVars();
        
      },
      1000);
    } else {
      this.setInitialVars();
    }
    
    this.listChannel();
    if (this.id != null) {
      this.reloadData()
    }
  },
};
</script>

<style>
</style>