<template>
  <div class="text-center">
    <!-- <v-dialog :persistent="passExpired" v-model="dialog" width="450"> -->
    <v-dialog v-model="dialog" width="450">
      <v-card class="pa-5">
        <v-card-title class="text-h5">
          <v-row class="mt-5" justify->
            <v-col cols="10"> {{ $t('TrocarSenha.registerNewPassword') }} </v-col>
            <v-col class="d-flex justify-end">
              <!-- <v-btn fab dark x-small color="primary" @click="dialog = false">
                  <v-icon small>mdi-close</v-icon>
                </v-btn> -->
            </v-col>
          </v-row>
        </v-card-title>
        <v-col>
          <v-text-field
            :error="errorSenhaOld"
            :hint="errorSenhaOld ? textInvalidPassword : ''"
            persistent-hint
            v-model="oldpass"
            :label="$t('TrocarSenha.oldPassword')"
            outlined
            :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showOld ? 'text' : 'password'"
            @click:append="showOld = !showOld"
            dense
          ></v-text-field>
          <v-text-field
            :hint="regrasSenha(password)"
            :error="errorSenha"
            persistent-hint
            v-model="password"
            :label="$t('TrocarSenha.newPassword')"
            outlined
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            dense
          ></v-text-field>
          <v-text-field
            :error="errorSenhaR"
            :hint="errorSenhaR ? textPasswordDifferent : ''"
            persistent-hint
            v-model="passwordR"
            :label="$t('TrocarSenha.repeatPassword')"
            outlined
            :append-icon="showR ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showR ? 'text' : 'password'"
            @click:append="showR = !showR"
            dense
          ></v-text-field>
          <v-btn class="mb-10" block color="primary" @click="trocarSenha">
            {{ $t('Guid.save') }}
          </v-btn>
          <!-- <v-btn text @click="dialog = false"> Cancelar </v-btn> -->
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textInvalidPassword: this.$t('TrocarSenha.invalidPassword'),
      textPasswordDifferent: this.$t('TrocarSenha.passwordDifferent'),

      token: JSON.parse(localStorage.getItem("authUser")).token,
      passExpired: JSON.parse(localStorage.getItem("authUser")).pass_Expired,
      oldpass: "",
      password: "",
      passwordR: "",
      showOld: false,
      show: false,
      showR: false,
      dialog: true,

      dadosSenha: [],
      errorSenhaOld: false,
      errorSenha: false,
      errorSenhaR: false,

      multiLine: true,
      operator: {},
    };
  },
  methods: {
    regrasSenha(senha) {
      var errorMsg = "";
      if (senha.length >= 8) {
        var errors = [];
        // if(senha.match(/[A-Z]/g) && senha.match(/[0-9]/g) && senha.match(/[!|@|#|$|%|^|&|*|(|)|-|_]/g)){
        //  this.errorSenha = false
        // }
        // else{
        //  this.errorSenha = true
        //  return 'Falta letra MAIÚSCULA, NÚMERO e CARACTER ESPECIAL'
        // }

        if (!senha.match(/[A-Z]/g)) {
          errors.push(this.$t('TrocarSenha.capitalLetter'));
        }
        if (!senha.match(/[0-9]/g)) {
          errors.push(this.$t('TrocarSenha.number'));
        }
        if (!senha.match(/[!|@|#|$|%|^|&|*|(|)|-|_]/g)) {
          errors.push(this.$t('TrocarSenha.specialCharacter'));
        }

        if (errors.length == 0) {
          this.errorSenha = false;
        } else {
          errorMsg = this.$t('TrocarSenha.miss');
          for (let i = 0; i < errors.length; i++) {
            errorMsg += ` ${errors[i]}, `;
          }
          this.errorSenha = true;
        }
      } else {
        errorMsg = this.$t('TrocarSenha.minimumCharacters');
      }

      return errorMsg;
    },
    encodePaswoord(pass){
      let processedPassword = ""
      let newPassword = window.btoa(unescape(encodeURIComponent( pass )))
      let regex2 = /==/g;
      let regex1 = /=/g;

      if (newPassword.includes("==")) {
        processedPassword = newPassword.replace(regex2,"2")
      } else if(newPassword.includes("=")) {
        processedPassword = newPassword.replace(regex1,"1")
      } else {
        processedPassword = newPassword + 0;
      }

      return processedPassword
    },

    async changePassword(){
      let processedPasswordNew = this.encodePaswoord(this.password)

      let dataOperator = {
        user_Id: this.operator.user_Id,
        user_Name: this.operator.user_Name,
        user_Login: this.operator.user_Login,
        user_Pass: processedPasswordNew,
        user_Email: this.operator.user_Email,
        user_Celphone: this.operator.user_Celphone,
        att_Start: this.operator.att_Start,
        att_End: this.operator.att_End,
        start_Access: this.operator.start_Access,
        end_Access: this.operator.end_Access,
        user_SysAdm: this.operator.user_SysAdm,
        expiration_Date: this.operator.expiration_Date,
        function_Id: this.operator.function_Id,
        user_Function: this.operator.user_Function,
        customer: [{ 
          customer_Id: this.operator.customer[0].customer_Id,
          customer_Name: this.operator.customer[0].customer_Name,
          customer_Logo_URL: this.operator.customer[0].customer_Logo_URL,
          store: this.operator.customer[0].store,
          group_List: this.operator.customer[0].group_List,
        }],
        channel: this.operator.channel
      }

      var config = {
        headers: { 
          'Authorization': `bearer ${this.token}`,
          'Content-Type': 'application/json'
          },
        data : dataOperator
      };

      await this.$http
        .put(`User_Data_Perm`, dataOperator, config)
        .then(() => {
          this.dialog = false
        })
        .catch(() =>{
          console.log("Error")
        })
    },
    trocarSenha() {
      this.operator = this.$store.state.permissions

      let processedPasswordOld = this.encodePaswoord(this.oldpass)

      if (processedPasswordOld == this.operator.user_Pass){
        if (this.passwordR != "" && this.password != "") {
          if (this.password == this.passwordR && this.errorSenha == false) {
            this.errorSenhaR = false;
            if (
              this.password.match(/[A-Z]/g) &&
              this.password.match(/[0-9]/g) &&
              this.password.match(/[!|@|#|$|%|^|&|*|(|)|-|_]/g)
            ) {
              this.changePassword()
            }
          } else {
            this.errorSenhaR = true;
          }
        }
      }
      else{
        this.errorSenhaOld = true;
      }
    },
  },
  watch: {
    dialog(dialog) {
      this.$emit("trocarSenha", dialog);
    },
  },
};
</script>

<style>
</style>