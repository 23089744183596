<template>
  <div v-if="functionality.functionality_Level >= 2">
    <v-row>
      <v-col cols="12" md="12">
        <v-card style="height: 100%">
          <v-card-title>{{ $t('NewGroup.group') }}</v-card-title>
          <v-card-text>
            {{ $t('NewGroup.groupName') }}
            <v-text-field v-model="groupName" solo></v-text-field>
          </v-card-text>
          <v-card-title class="pb-0">{{ $t('NewGroup.channel') }}</v-card-title>
          <v-row>
            <v-col>
              <v-card-text class="py-0">
                <v-select
                  v-model="channelId"
                  :items="itemChannel"
                  item-text="channel_Name"
                  item-value="channel_Id"
                  required
                  :label="$t('NewGroup.channelName')"
                ></v-select>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card-text>
                <v-radio-group v-model="enable" row>
                  <v-radio
                    :label="$t('NewGroup.active')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :label="$t('NewGroup.unactive')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-card-actions style="float: right">
        <v-btn
          :block="$vuetify.breakpoint.width < '960' ? true : false"
          color="primary"
          @click="save"
        >
          {{ $t('Guid.save') }}
        </v-btn>
        <v-btn
          :block="$vuetify.breakpoint.width < '960' ? true : false"
          color="red"
          @click="closeGroup"
        >
          {{ $t('Guid.close') }}
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ $t(snackText) }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';
// import axios from 'axios';

export default {
  props: ['id'],
  data: () => ({
    channelId: 0,
    itemChannel: [],

    groupName: '',
    groupId: 0,
    enable: true,

    typeClient: true,

    token: JSON.parse(localStorage.getItem('authUser')).token,

    snack: false,
    snackColor: '',
    snackText: '',

    client: '',

    havePermissions: false,
  }),
  methods: {
    closeGroup() {
      this.$router.push({ name: 'listGroup' });
    },
    async groupDetails() {
      let group = this.getListGroups.find((f) => {
        return f.attendence_Group_Id == this.id;
      });
      this.groupName = group.attendence_Group_Name;
      this.groupId = group.attendence_Group_Id;
      this.channelId = group.attendence_Channel_Id;
    },
    existantChannels() {
      this.$http
        .get(
          `List_Channel?Customer_Id=${this.$store.state.currentCustomer.customer_Id}`,
          {
            headers: {
              accept: '*/*',
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then((r) => {
          this.itemChannel = r.data;
        })
        .catch((e) => {
          console.log('error canal', e);
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async save() {
      let group = {
        customer_Id: this.client.customer_Id,
        attendence_Group_Id: this.groupId,
        attendence_Group_Name: this.groupName,
        attendence_Channel_Id: this.channelId,
        enable: this.enable,
      };
      await this.$http
        .post(
          `User_Att_Group_Update?customer_Id=${group.customer_Id}&attendence_Group_Id=${group.attendence_Group_Id}&attendence_Group_Name=${group.attendence_Group_Name}&attendence_Channel_Id=${group.attendence_Channel_Id}&enable=${group.enable}`,
          {},
          {
            headers: {
              accept: '*/*',
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then((r) => {
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'NovoCadastro.snackTextSuccess';
          this.userFunctionId = r.data.function_Id;
          this.emitReload();
          router.push({ name: 'listGroup' });
        })
        .catch((e) => {
          console.log(e);
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = 'NovoCadastro.snackTextError';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setInitialVars() {
      this.listCustomers = this.getListDataInformation;
      if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
      } else {
        this.client = this.$store.state.currentCustomer;
      }
      this.functionality = this.getListUserFunctions.find(
        (func) => func.functionality_Name == 'Attendence Group'
      );
      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' });
      }

      this.havePermissions = false;
      this.existantChannels();
    },
    emitReload() {
      this.$store.dispatch('listGroup', localStorage.getItem('customer_Id'));
    },
  },
  computed: {
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListGroups']),
  },
  async created() {
    this.havePermissions = true;
    if (this.$store.state.permissions.length == 0) {
      setTimeout(async () => {
        await this.setInitialVars();
      }, 1000);
    } else {
      await this.setInitialVars();
    }
    if (this.id) {
      this.groupDetails();
    }
  },
};
</script>
