<template>
  <v-row class="justify-end">
    <v-col
      v-if="$vuetify.breakpoint.width > '960'"
      cols="2"
      align-self="center"
    >
      <v-img
        lazy-src="@/imgs/complementos/enl.png"
        max-width="100"
        src="@/imgs/complementos/enl.png"
      ></v-img>
    </v-col>
    <v-col
      cols="6"
      md="6"
      :class="$vuetify.breakpoint.width > '960' ? 'justify-center' : ''"
      class="d-flex align-center"
      ><b style="font-size: 1.2rem">{{ $t(nomePagina) }}</b></v-col
    >
    <v-col cols="2" class="d-flex justify-end align-center">
      <v-badge bordered color="primary" content="6" overlap>
        <v-icon> mdi-bell </v-icon>
      </v-badge>
      <span v-if="$vuetify.breakpoint.width > '960'" class="pl-5"
        >{{$t('MenuTopGerente.greeting')}} {{ user }}!</span
      >
      <v-menu offset-y left transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-menu</v-icon>
        </template>
        <v-list>
          <v-list-item
            class="ma-n2"
            v-for="(item, index) in perfilClick"
            :key="index"
          >
            <v-btn
              block
              text
              small
              color="primary"
              @click="funcoesPerfilClick(item)"
            >
              <v-list-item-title style="font-size: 12px">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="2" class="d-flex justify-end align-center">
      <v-list-item>
        <SelectorLenguaje>
          
        </SelectorLenguaje>
      </v-list-item>
    </v-col>
    <TrocarSenha
      v-if="alterarSenha"
      @trocarSenha="alterarSenha = $event"
    ></TrocarSenha>
  </v-row>
</template>

<script>
import TrocarSenha from "@/components/TrocarSenha.vue";
import SelectorLenguaje from "@/components/generic_components/SelectorLenguaje.vue";
import { CONNECTIONSIGNALR } from "../plugins/signalR"

export default {
  components: {
    TrocarSenha,
    SelectorLenguaje,
  },
  data() {
    return {
      perfilClick: [
      { title: "MenuTopChat.titlePassword", cod: "alterarSenha" },
        { title: "MenuTopChat.titleLogout", cod: "sair" },
      ],

      alterarSenha: false,

      token: JSON.parse(localStorage.getItem("authUser")).token,
      passExpired: JSON.parse(localStorage.getItem("authUser")).pass_Expired,
      user: JSON.parse(localStorage.getItem("authUser")).user_Name,
      startAtt: JSON.parse(localStorage.getItem("authUser")).start_Att,
      endAtt: JSON.parse(localStorage.getItem("authUser")).end_Att,
    };
  },
  methods: {
    funcoesPerfilClick(item) {
      if (item.cod == "alterarSenha") {
        this.alterarSenha = true;
      } else if (item.cod == "sair") {
        this.logout();
      }
    },
    async logout() {
      await CONNECTIONSIGNALR.stop();
      localStorage.removeItem("authUser");
      localStorage.removeItem("convAtual");
      this.$store.commit('setInitialData')
      this.$router.push({ name: "home" });
    },
  },
  computed: {
    nomePagina() {
      if (this.$route.name == "listaOperadores") {
        return "MenuTopGerente.operatorsList";
      } else if (this.$route.name == "cadastro") {
        return "MenuTopGerente.register";
      } else if (this.$route.name == "painelCliente") {
        return "MenuTopGerente.clientPanel";
      } else if (this.$route.name == "atualizar") {
        return "MenuTopGerente.update";
      } else if (this.$route.name == "listCampaign") {
        return "MenuTopGerente.campaignList"; 
      } else if (this.$route.name == "campaign") {
        return "MenuTopGerente.campaign"
      } else if (this.$route.name == "updateCampaign") {
        return "MenuTopGerente.updateCampaign"
      } else if (this.$route.name == "listGroup") {
        return "MenuTopGerente.listGroup"
      } else if (this.$route.name == "group") {
        return "MenuTopGerente.group"
      } else if (this.$route.name == "updateGroup") {
        return "MenuTopGerente.updateGroup"
      } else if (this.$route.name == "messagesTemplate") {
        return "MenuTopGerente.listTemplate"
      } else if (this.$route.name == "newMessageTemplate") {
        return "MenuTopGerente.newTemplate"
      } else if (this.$route.name == "updateMessageTemplate") {
        return "MenuTopGerente.updateTemplate"
      } else if (this.$route.name == "executeHsm") {
        return "MenuTopGerente.HSM"
      }
      return "MenuTopGerente.panel";
    },
  },
};
</script>

<style scoped>
</style>