var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.listCustomersConfiguration')))]),_c('v-card-text',[_c('v-select',{attrs:{"return-object":"","items":_vm.listCustomers,"item-text":"customer_Name","item-value":"customer_Id","label":_vm.$t('MenuPainelGerente.clientName'),"required":""},on:{"change":_vm.changeCustomer},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-list',[(_vm.panelFunction != undefined && _vm.panelFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'painelCliente'
                ? _vm.$router.push({ name: 'painelCliente' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.panel')))])],1):_vm._e(),(_vm.attendenceFunction != undefined && _vm.attendenceFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ name: 'atendimento' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chat-processing")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.atendimento')))])],1):_vm._e(),(_vm.campaignsFunction != undefined && _vm.campaignsFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'listCampaign'
                ? _vm.$router.push({ name: 'listCampaign' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-bell")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.campaign')))])],1):_vm._e(),(_vm.operatorsFunction != undefined && _vm.operatorsFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'listaOperadores'
                ? _vm.$router.push({ name: 'listaOperadores' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-headset")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.operators')))])],1):_vm._e(),(_vm.campaignsFunction != undefined && _vm.campaignsFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'listGroup'
                ? _vm.$router.push({ name: 'listGroup' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.groups')))])],1):_vm._e(),(_vm.reportsFunction != undefined && _vm.reportsFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'reports'
                ? _vm.$router.push({ name: 'reports' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-areaspline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.report')))])],1):_vm._e(),(_vm.flowsFunction != undefined && _vm.flowsFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                {
                  name: 'fluxo',
                },
                () => {}
              )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-sitemap")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.flows')))])],1):_vm._e(),(_vm.templateFunction != undefined && _vm.templateFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'messagesTemplate'
                ? _vm.$router.push({ name: 'messagesTemplate' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.templates')))])],1):_vm._e(),(_vm.reasonFunction != undefined && _vm.reasonFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'reasons'
                ? _vm.$router.push({ name: 'reasons' })
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-alpha-r-box")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.Reasons')))])],1):_vm._e(),(_vm.hsmFunction != undefined && _vm.hsmFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'execHSM'
                ? _vm.$router.push({ name: 'executeHsm'})
                : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-send-clock ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.HSM')))])],1):_vm._e(),(false)?_c('div',[(_vm.flowsFunction != undefined && _vm.flowsFunction.functionality_Level > 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$route.name != 'loadFiles'
                  ? _vm.$router.push({ name: 'loadFiles' })
                  : false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-upload")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.loadFiles')))])],1):_vm._e()],1):_vm._e(),_c('v-list-item',{staticClass:"mt-16",attrs:{"link":""},on:{"click":_vm.exit}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-run")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('MenuPainelGerente.quit')))])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }