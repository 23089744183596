<template>
  <div class="vuec-mylogin">
    <v-dialog v-model="dialog" max-width="450">
      <template v-slot:activator="{ on, attrs }">
        <div color="#9cd9e8" dark rounded x-large v-bind="attrs" v-on="on">
          <slot></slot>
        </div>
      </template>
      <v-card>
        <v-card-title class="text-h4 d-flex justify-center pt-10"
          >{{$t('MyLogin.login')}}</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row class="mt-5">
              <v-col>
                <v-text-field
                  v-model="user"
                  :rules="[rules.required]"
                  :label="$t('MyLogin.user')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="password"
                  outlined
                  dense
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="show ? 'text' : 'password'"
                  name="input-10-2"
                  :label="$t('MyLogin.password')"
                  value=""
                  class="input-group--focused"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="checkbox" :label="$t('MyLogin.rememberMe')"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="16">
                <v-btn
                  :loading="loadingLogin"
                  :disabled="loadingLogin"
                  color="primary"
                  block
                  @click="btnEnviar"
                >
                  {{$t('MyLogin.login')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <EsqueceuSenha>
                  <v-btn plain text color="primary" small>
                    {{$t('MyLogin.forgotPassword')}}
                  </v-btn>
                </EsqueceuSenha>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar" :multi-line="multiLine" timeout="3000">
        {{ $t(textSnackbar) }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            {{ $t('MyLogin.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import EsqueceuSenha from "./EsqueceuSenha.vue";

export default {
  components: { EsqueceuSenha },
  data() {
    return {
      user: "",
      password: "",
      checkbox: true,
      dialog: false,
      show: false,
      rules: {
        required: (value) => !!value || "Campo Obrigatório.",
      },

      multiLine: true,
      snackbar: false,
      textSnackbar: "",

      loadingLogin: false
    };
  },
  computed: {
    password64() {
      let processedPassword = ""
      let newPassword = window.btoa(unescape(encodeURIComponent( this.password )))
      let regex2 = /==/g;
      let regex1 = /=/g;
      
      if (newPassword.includes("==")) {
        processedPassword = newPassword.replace(regex2,"2")
      } else if(newPassword.includes("=")) {
        processedPassword = newPassword.replace(regex1,"1")
      } else {
        processedPassword = newPassword + 0;
      }
      return processedPassword;
    },
  },
  methods: {
    btnEnviar() {
      this.loadingLogin = true
      if (this.user.length > 0 && this.password.length > 0) {
        this.$http
          .get(
            `User_Validate?User_Login=${this.user}&User_Pass=${this.password64}`,
            {
              headers: {
                accept: "*/*",
              },
            }
          )
          .then((r) => {
            this.loadingLogin = false
            if (
              r.data.user_Name != null &&
              r.data.start_Att != null &&
              r.data.end_Att != null
            ) {
              localStorage.setItem("authUser", JSON.stringify(r.data));
              this.$store.dispatch('getPermissions', r.data.user_Id);
              this.$router.push({ name: "painelCliente" });
            } else {
              this.snackbar = true;
              this.textSnackbar = "MyLogin.incorrectCredentials";
            }
          })
          .catch(() => {
            this.loadingLogin = false
            this.snackbar = true;
            this.textSnackbar = "MyLogin.errorInLogin";
          })
          .finally(() => {
            this.loadingLogin = false
          });
      } else {
        this.loadingLogin = false
        this.snackbar = true;
        this.textSnackbar = "MyLogin.errorInLogin";
      }
    },
  },
};
</script>

<style>
</style>