<template>
  <div v-if="functionality.functionality_Level >= 2">
    <v-container>
      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="25%">
            <template v-slot:activator="{}">
              <v-btn
                color="primary"
                @click="openModal"
                v-if="cvsTable.length > 0"
              >
                Open Dialog
              </v-btn>
            </template>
            <v-card class="px-10 py-10">
              <v-card-text class="text-center" v-if="cvsTable.length > 0">
                <span class="font-weight-bold">
                  {{ valueCount }} {{ $t('Guid.of') }} {{ cvsTable.length }}
                  {{ $t('Guid.loading') }}...
                </span>
              </v-card-text>
              <v-card-text class="text-center font-weight-bold">
                <p style="color: rgb(235, 49, 49)" v-if="cvsTable.length > 0">
                  {{ $t('Guid.hsm_alert') }}
                </p>
                <p
                  v-else
                  style="color: rgb(15, 168, 15)"
                  class="text-center font-weight-bold mt-3"
                >
                  <span> {{ $t('Guid.hsm_finished') }} </span>
                </p>
              </v-card-text>
              <v-progress-linear
                v-if="cvsTable.length > 0"
                indeterminate
                color="teal"
                class="mb-10"
              ></v-progress-linear>
              <v-progress-linear
                v-else
                model-value="100"
                color="teal"
                rounded
              ></v-progress-linear>
              <v-card-actions class="mt-5" v-if="cvsTable.length === 0">
                <v-btn color="primary" block @click="dialog = false">
                  {{ $t('Guid.close') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              {{ $t('ExecHSM.selectFile') }}
              <v-text-field :disabled="true" v-model="fileName" solo>
              </v-text-field>
            </v-card-text>
            <v-card-text>
              <v-btn
                v-if="imported"
                :disabled="disabledImport"
                @click="onButtonClick"
                style="position: absolute; right: 0; top: 65px"
                class="ma-8"
                color="primary"
                >{{ $t('Guid.import') }}
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
              <v-btn
                v-if="cvsTable.length > 0"
                @click="sendHSM"
                style="position: absolute; right: 0; top: 65px"
                class="ma-8"
                color="primary"
                >{{ $t('Guid.send') }}
                <v-icon right dark> mdi-send-clock </v-icon>
              </v-btn>
              <v-btn
                v-if="cvsTable.length > 0"
                @click="clear"
                style="position: absolute; right: 120px; top: 65px"
                class="ma-8"
                color="gray"
                >{{ $t('Guid.clear') }}
                <v-icon right dark> mdi-cancel </v-icon>
              </v-btn>
              <input
                @change="onFileChanged"
                ref="fileUpload"
                class="d-none"
                type="file"
              />
              <v-row align="center" justify="start" class="mx-5">
                <v-btn
                  depressed
                  color="primary"
                  class="my-5"
                  v-if="show && !dialog && cvsTable.length > 0"
                  @click="openModal"
                >
                  {{ $t('Guid.see_load') }}
                </v-btn>
              </v-row>
            </v-card-text>
            <v-card-text v-if="cvsTable.length > 0">
              <v-data-table
                :headers="headers"
                :items="cvsTable"
                :sort-by-text="$t(headers.text)"
                class="elevation-1"
                :items-per-page="5"
              >
                <template #[`item.send`]="props">
                  <v-checkbox v-model="props.item.send"></v-checkbox>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snack" timeout="3000">
      {{ $t(snackText) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closeSnackbar">
          {{ $t('Guid.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fileName: '',
      dialog: false,
      show: false,
      cvsTable: [],
      valueCount: 0,
      token: JSON.parse(localStorage.getItem('authUser')).token,
      ext_opp: axios.create({
        baseURL: process.env.VUE_APP_ENV_API_ATT_EXT_OPP,
      }),
      exct_hsm: axios.create({
        baseURL: process.env.VUE_APP_ENV_API_ROUTE_HSM,
      }),
      disabledImport: false,
      imported: true,
      snack: false,
      snackColor: '',
      snackText: '',
      functionality: [],
    };
  },
  methods: {
    clear() {
      this.cvsTable = [];
      this.disabledImport = false;
      this.imported = true;
      this.fileName = '';
      this.$refs.fileUpload.value = null;
    },
    closeSnackbar() {
      this.snack = false;
      this.disabledImport = false;
    },
    onButtonClick() {
      window.addEventListener('focus', () => {}, {
        once: true,
      });
      this.$refs.fileUpload.click();
    },
    onFileChanged(e) {
      this.isImporting = true;
      this.fileName = e.target.files[0].name;
      this.selectedFile = e.target.files[0];
      let stringInFile = new FileReader();
      this.disabledImport = true;
      stringInFile.addEventListener('load', async () => {
        try {
          let line = [];
          let csv = stringInFile.result;
          let rows = csv.split('\n');
          if (rows[rows.length - 1] === '') {
            rows.pop();
          }
          csv = rows;
          for (let i = 1; i < csv.length; i++) {
            line = csv[i].split(';');
            for (let j = 0; j < line.length; j++) {
              line[line.length - 1] = line[line.length - 1].replace('\r', '');
              let hsmObject = {
                customer_id: line[0],
                client_identification: line[1],
                message: line[2],
                send_date: line[3],
                from: line[4],
                parameters: line[5],
                message_id: line[6],
                campaign_id: line[7],
                send: true,
              };
              this.cvsTable.push(hsmObject);
              break;
            }
          }
        } catch (error) {
          console.log(error);
        }
        this.imported = false;
        this.snack = true;
        this.snackColor = 'success';
        this.snackText = 'ExecHSM.snackImportSuccess';
      });
      stringInFile.readAsText(this.selectedFile);
    },
    countHSM(value) {
      if (value) {
        this.valueCount++;
      } else {
        this.dialog = false;
      }
    },
    closeModal() {
      this.dialog = false;
    },
    openModal() {
      this.dialog = true;
    },
    async sendHSM() {
      this.dialog = true;
      this.show = true;

      for (const item of this.cvsTable) {
        if (item.send) {
          this.execHSM(item);
          this.countHSM(item.send);
          await this.sleep(300);
        }
      }
      // Clear variables
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'ExecHSM.snackExcecSuccess';
      this.clear();
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    execHSM(line) {
      var config = {
        headers: {
          Authorization: `bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      };
      let hsmObject = {
        customer_id: line.customer_id,
        client_identification: line.client_identification,
        message: line.message,
        send_date: line.send_date,
        from: line.from,
        parameters: line.parameters,
        message_id: line.message_id,
        campaign_id: line.campaign_id,
      };
      this.exct_hsm
        .post(`Hsm_Send`, hsmObject, config)
        .then(() => {
          // TODO: code to control
        })
        .catch(() => {
          console.log('Error');
        });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('ExecHSM.table.customer'),
          value: 'customer_id',
          width: '16%',
          align: 'center',
          class: 'header',
        },
        {
          text: this.$t('ExecHSM.table.clientNumber'),
          value: 'client_identification',
          width: '20%',
          align: 'center',
          class: 'header',
        },
        {
          text: this.$t('ExecHSM.table.message'),
          value: 'message',
          width: '200px',
          align: 'center',
          class: 'header',
        },
        {
          text: this.$t('ExecHSM.table.sendDate'),
          value: 'send_date',
          width: '200px',
          align: 'center',
          class: 'header',
        },
        {
          text: this.$t('ExecHSM.table.from'),
          value: 'from',
          width: '14%',
          align: 'center',
          class: 'header',
          sortable: false,
        },
        {
          text: this.$t('ExecHSM.table.parameters'),
          value: 'parameters',
          width: '14%',
          align: 'center',
          class: 'header',
          sortable: false,
        },
        {
          text: this.$t('ExecHSM.table.idMessage'),
          value: 'message_id',
          width: '14%',
          align: 'center',
          class: 'header',
          sortable: false,
        },
        {
          text: this.$t('ExecHSM.table.campaignId'),
          value: 'campaign_id',
          width: '14%',
          align: 'center',
          class: 'header',
          sortable: false,
        },
        {
          text: this.$t('ExecHSM.table.send'),
          value: 'send',
          width: '14%',
          align: 'center',
          class: 'header',
          sortable: false,
        },
      ];
    },
    ...mapGetters(['getListUserFunctions']),
  },
  mounted () {
    if (this.getListUserFunctions.length > 0) {
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "HSM");
    }
  },
  watch: {
    getListUserFunctions () {
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "HSM");
    }
  }
};
</script>
<style></style>
