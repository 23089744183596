<template>
  <div class="vuec-PainelCliente" v-if="panelFunction != undefined && panelFunction.functionality_Level > 1">
    <v-container fluid class="pa-0">
      <v-row align="center" dense>
        <v-col>
          <v-card-text class="py-0">
            <v-col cols="12">
              <v-menu
                ref="menuAtivo"
                v-model="menuAtivo"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    v-model="startDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuAtivo = false">
                    {{ $t('Guid.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuAtivo.save(startDate)"
                  >
                  {{ $t('Guid.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text class="py-0">
            <v-col cols="12">
              <v-menu
                ref="menuInativo"
                v-model="menuInativo"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    v-model="endDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuInativo = false">
                    {{ $t('Guid.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuInativo.save(endDate)"
                  >
                  {{ $t('Guid.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-card-text>
        </v-col>
        <v-col>
          <v-select
            hide-details
            solo
            v-model="channel"
            :items="channels"
            item-text="channel_Name"
            item-value="channel_Code"
          ></v-select>
        </v-col>
        <v-col>
          <v-btn
            elevation="2"
            text
            color="primary"
            @click="updateGraph">
            {{ $t('PainelCliente.update') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-card>
            <v-card-title> {{ $t('PainelCliente.operators') }} </v-card-title>
            <v-card-text>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  {{ $t('PainelCliente.enabled') }}:
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ operadoresOnline }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchAgentDetails(1)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  {{ $t('PainelCliente.disabled') }}:
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ qtOperadores }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchAgentDetails(2)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  Total:
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ operatorsTotal }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchAgentDetails(0)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="attendence">
          <v-card>
            <v-card-title>
              {{ $t('PainelCliente.services') }}
            </v-card-title>
            <v-card-text>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  {{ $t('PainelCliente.number_Attended') }}
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ number_Attended }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchServices(2)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  {{ $t('PainelCliente.number_Attendence_Waitting') }}
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ number_Attendence_Waitting }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchServices(3)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  {{ $t('PainelCliente.number_In_Attendence') }}
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ number_In_Attendence }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchServices(1)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">
                  {{ $t('PainelCliente.total_Attendence') }}
                </v-col>
                <v-col cols="3" class="font-weight-black">
                  {{ total_Attendence }}
                </v-col>
                <v-col cols="2">
                  <v-btn @click="searchServices(0)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">{{ $t('PainelCliente.attendence_Waitting_Time_Sec') }}</v-col>
                <v-col cols="6" class="font-weight-black">{{ attendence_Waitting_Time_Sec }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card>
            <v-card-title>
              {{ $t('PainelCliente.interactions') }}
            </v-card-title>
            <v-card-text>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">{{ $t('PainelCliente.number_Interactions_Abandoned') }}</v-col>
                <v-col cols="3" class="font-weight-black">{{ number_Interactions_Abandoned }}</v-col>
                <v-col cols="2">
                  <v-btn @click="searchInteraction(3)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">{{ $t('PainelCliente.number_Interactions_Closed') }}</v-col>
                <v-col cols="3" class="font-weight-black">{{ number_Interactions_Closed }}</v-col>
                <v-col cols="2">
                  <v-btn @click="searchInteraction(2)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">{{ $t('PainelCliente.number_Interactions_Open') }}</v-col>
                <v-col cols="3" class="font-weight-black">{{ number_Interactions_Open }}</v-col>
                <v-col cols="2">
                  <v-btn @click="searchInteraction(1)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6" class="font-weight-black">{{ $t('PainelCliente.total_Interactions') }}</v-col>
                <v-col cols="3" class="font-weight-black">{{ total_Interactions }}</v-col>
                <v-col cols="2">
                  <v-btn @click="searchInteraction(0)" icon>
                    <v-icon size="small">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="agentStatus.length > 0">
        <v-col>
          {{ $t(textAgent) }}
          <v-data-table
            dense
            :v-model:items-per-page="itemsPerPage"
            :headers="headersAgents"
            :items="agentStatus"
            :sort-by-text="$t(headersAgents.text)"
            class="elevation-1">
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="servicesStatus.length > 0">
        <v-col>
          {{ $t(textServices) }}
          <v-data-table
            dense
            :v-model:items-per-page="itemsPerPage"
            :headers="headersServices"
            :items="servicesStatus"
            :sort-by-text="$t(headersServices.text)"
            class="elevation-1">
            <template v-slot:[`item.attendence_Id`]="{item}">
              <v-btn class="text-caption" @click="getFullService(item.attendence_Id)" icon>
                {{ item.attendence_Id }}
              </v-btn>
            </template>
            <template v-slot:[`item.client_Identify`]="{item}">
              <span class="text-caption" icon>
                {{ item.client_Identify }}
              </span>
            </template>
            <template v-slot:[`item.agent_Name`]="{item}">
              <span class="text-caption" icon>
                {{ item.agent_Name }}
              </span>
            </template>
            <template v-slot:[`item.attendence_Queue_Start`]="{item}">
              <span class="text-caption" icon>
                {{ item.attendence_Queue_Start }}
              </span>
            </template>
            <template v-slot:[`item.attendence_Start`]="{item}">
              <span class="text-caption" icon>
                {{ item.attendence_Start }}
              </span>
            </template>
            <template v-slot:[`item.attendence_Transfer`]="{item}">
              <span class="text-caption" icon>
                {{ item.attendence_Transfer }}
              </span>
            </template>
            <template v-slot:[`item.attendence_End`]="{item}">
              <span class="text-caption" icon>
                {{ item.attendence_End }}
              </span>
            </template>
            <template v-slot:[`item.attendence_Result`]="{item}">
              <span class="text-caption" icon>
                {{ item.attendence_Result }}
              </span>
            </template>
          </v-data-table>
          <v-btn
            class="ma-2"
            color="primary"
            @click="forceFileDownload(servicesStatus, $t(textServices))">
            {{$t('Guid.export')}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="interactionsStatus.length > 0">
        <v-col>
          {{ $t(textInteractions) }}
          <v-data-table
            dense
            :v-model:items-per-page="itemsPerPage"
            :headers="headersInteractions"
            :items="interactionsStatus"
            :sort-by-text="$t(headersInteractions.text)"
            class="elevation-1">
            <template #[`item.client_Operation_Id`]="props">
              <v-btn @click="getFullInteractions(props.item.client_Operation_Id)" icon>
                {{ props.item.client_Operation_Id }}
              </v-btn>
            </template>
          </v-data-table>
          <v-btn
            class="ma-2"
            color="primary"
            @click="forceFileDownload(interactionsStatus, $t(textInteractions))">
            {{$t('Guid.export')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>
    <v-dialog v-if="dialog"
        v-model="dialog"
        width="auto">
      <template v-slot:activator="{ props }">
          <v-btn v-show="false"
            color="primary"
            v-bind="props"
          />
        </template>
        <v-card>
          <v-card-title v-if="messageList.length > 0">{{ $t('PainelCliente.detailsMessage') }}</v-card-title>
          <v-card-title v-if="detailsInteraction.length > 0">{{ $t('PainelCliente.detailsInteractions') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="messageList.length > 0">
                <v-data-table
                  dense
                  :v-model:items-per-page="itemsPerPage"
                  :headers="headersFullServices"
                  :items="messageList"
                  :sort-by-text="$t(headersFullServices.text)"
                  class="elevation-1">
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="detailsInteraction.length > 0">
                <v-data-table
                  dense
                  :v-model:items-per-page="itemsPerPage"
                  :headers="headersFullInteractions"
                  :items="detailsInteraction"
                  :sort-by-text="$t(headersFullInteractions.text)"
                  class="elevation-1">
                </v-data-table>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="closeDialog()"
          >
            {{ $t("Guid.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      token: JSON.parse(localStorage.getItem("authUser")).token,
      operadoresOnline: 0,
      qtOperadores: 0,
      operatorsTotal: 0,

      listCustomers: [],
      client: [],

      number_Attended: 0,
      total_Attendence: 0,
      number_Attendence_Waitting: 0,
      number_In_Attendence: 0,
      number_Interactions_Abandoned: 0,
      number_Interactions_Closed: 0,
      number_Interactions_Open: 0,
      total_Interactions: 0,
      attendence_Waitting_Time_Sec: 0,

      ext_opp : axios.create({
          baseURL: process.env.VUE_APP_ENV_API_ATT_EXT_OPP
      }),

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      menuAtivo: false,
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      menuInativo: false,

      channels: [],
      channel: 0,

      agentStatus: [],
      servicesStatus: [],
      interactionsStatus: [],
      itemsPerPage: 10,

      messageList: [],
      detailsInteraction: [],

      dialog: false,

      textAgent: "",
      textServices: "",
      textInteractions: "",

      panelFunction: []

    };
  },
  computed: {
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getCurrentCustomer']),
    ...mapGetters(['getUserInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getChannelsForPanel']),
    headersAgents() {
      return [
        {
          text: this.$t("PainelCliente.agentsTable.userLogin"),
          value: "user_Login",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.agentsTable.user_Name"),
          value: "user_Name",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.agentsTable.user_Email"),
          value: "user_Email",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.agentsTable.user_Attending"),
          value: "user_Attending",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        }
      ]
    },
    headersServices(){
      return [
        {
          text: this.$t("PainelCliente.servicesTable.attendence_Id"),
          value: "attendence_Id",
          width: "5%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.client_Identify"),
          value: "client_Identify",
          width: "8%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.client_Name"),
          value: "client_Name",
          width: "10%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.attendence_Queue_Start"),
          value: "attendence_Queue_Start",
          width: "18%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.attendence_Start"),
          value: "attendence_Start",
          width: "18%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.attendence_Transfer"),
          value: "attendence_Transfer",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.attendence_End"),
          value: "attendence_End",
          width: "18%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.agent_Name"),
          value: "agent_Name",
          width: "10%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.servicesTable.attendence_Result"),
          value: "attendence_Result",
          width: "18%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        }
      ]
    },
    headersFullServices(){
      return [
        {
          text: this.$t("PainelCliente.fullServicesTable.message_Update"),
          value: "message_Update",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.fullServicesTable.message"),
          value: "message",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.fullServicesTable.message_Direction"),
          value: "message_Direction",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        }
      ]
    },
    headersInteractions(){
      return [
        {
          text: this.$t("PainelCliente.interactionsTable.client_Operation_Id"),
          value: "client_Operation_Id",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.interactionsTable.client_Name"),
          value: "client_Name",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.interactionsTable.client_Identify"),
          value: "client_Identify",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.interactionsTable.flow_Name"),
          value: "flow_Name",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.interactionsTable.interaction_Start"),
          value: "interaction_Start",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.interactionsTable.last_Interaction"),
          value: "last_Interaction",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        }
      ]
    },
    headersFullInteractions(){
      return [
        {
          text: this.$t("PainelCliente.fullInteractionsTable.message_Update"),
          value: "message_Update",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.fullInteractionsTable.message"),
          value: "message",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        },
        {
          text: this.$t("PainelCliente.fullInteractionsTable.message_Direction"),
          value: "message_Direction",
          width: "14%",
          align: "center",
          class: "font-weight-black",
          sortable: false,
        }
      ]
    }
  },
  methods: {
    changeCustomer() {
      this.updateGraph();
    },
    updateGraph(){
      let location = window.location.href.replace(window.location.origin,'')
      let painelActive = location == "/painel/cliente" ? true : false;
      if (painelActive) {
        this.operadoresOnline = 0;
        this.qtOperadores = 0;
        this.agentStatus= [];
        this.servicesStatus= [],
        this.interactionsStatus= [],

        this.messageList= [],
        this.detailsInteraction= [],
        this.getInitialDataForCharts()
      }
    },
    getInitialDataForCharts() {
      let dateStart = this.startDate+"T00:00:00"
      let dateEnd = this.endDate+"T23:59:59"
      this.ext_opp
        .get(`Light_Dashboard?customer_id=${localStorage.getItem("customer_Id")}&channel_id=${this.channel}&start_date_time=${this.utcMethod(dateStart)}&end_date_time=${this.utcMethod(dateEnd)}`, {
          headers: {
            accept: "*/*",
          },
      })
      .then((r) => {
        this.operadoresOnline = r.data.number_Agents_Enable
        this.operatorsTotal = r.data.total_Agents
        this.qtOperadores = r.data.number_Agents_Disable
        this.number_Attended= r.data.number_Attended,
        this.total_Attendence= r.data.total_Attendence,
        this.number_Attendence_Waitting= r.data.number_Attendence_Waitting,
        this.number_In_Attendence= r.data.number_In_Attendence,
        this.number_Interactions_Abandoned= r.data.number_Interactions_Abandoned,
        this.number_Interactions_Closed= r.data.number_Interactions_Closed;
        this.number_Interactions_Open= r.data.number_Interactions_Open;
        this.total_Interactions= r.data.total_Interactions;
        this.attendence_Waitting_Time_Sec = parseFloat(r.data.attendence_Waitting_Time_Sec).toFixed(2)
      })
      .catch((error) => {
        console.log(error)
      })
    },
    getLocalDate(start_Queue) {
      let newDate = new Date(start_Queue + 'Z');
      let valYear = newDate.getFullYear()
      let formatDate = null;
      if(valYear < 2000) {
        formatDate = ""
      } else {
        formatDate =
        [
          newDate.getFullYear(),
          newDate.getMonth()+1,
          newDate.getDate()
        ].join('-') +' ' +
        [newDate.getHours(),
          String(newDate.getMinutes()).padStart(2, '0'),
          String(newDate.getSeconds()).padStart(2, '0')].join(':').toString();
      }

      return formatDate;
    },
    searchAgentDetails(number) {
      this.agentStatus = [];
      this.servicesStatus = [];
      this.interactionsStatus= [];
      this.messageList = [];
      this.detailsInteraction = [];

      this.ext_opp.
        get(`Light_Dashboard_Agent?customer_id=${this.client.customer_Id}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        }).then((r) => {
          if (number == 0) {
            r.data.forEach(element => {
              if (element.user_Attending === 1) {
                element.user_Attending = "Disponible"
              } else if(element.user_Attending === 0) {
                element.user_Attending = "No Disponible"
              }
              this.agentStatus.push(element)
            })
            this.agentStatus = r.data
            this.textAgent = "PainelCliente.agentsTable.textTotal"
          } else if(number == 1) {
            r.data.forEach(element => {
              if (element.user_Attending === 1) {
                element.user_Attending = "Disponible"
                this.agentStatus.push(element)
              }
            });
            this.textAgent = "PainelCliente.agentsTable.textAvailable"
          } else if(number == 2) {
            r.data.forEach(element => {
              if (element.user_Attending === 0) {
                element.user_Attending = "No Disponible"
                this.agentStatus.push(element)
              }
            });
            this.textAgent = "PainelCliente.agentsTable.textUnavailable"
          }
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    searchServices(number) {
      this.agentStatus = [];
      this.servicesStatus = [];
      this.interactionsStatus= [];
      this.messageList = [];
      this.detailsInteraction = [];
      let dateStart = this.startDate+"T00:00:00"
      let dateEnd = this.endDate+"T23:59:59"

      this.ext_opp.
        get(`Light_Dashboard_Attendence?customer_id=${this.client.customer_Id}&channel_id=${this.channel}&start_date_time=${this.utcMethod(dateStart)}&end_date_time=${this.utcMethod(dateEnd)}&status_att=${number}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          r.data.forEach(element => {
            element.attendence_Start = this.getLocalDate(element.attendence_Start)
            element.attendence_End = this.getLocalDate(element.attendence_End)
            element.attendence_Transfer = this.getLocalDate(element.attendence_Transfer)
            element.attendence_Queue_Start = this.getLocalDate(element.attendence_Queue_Start)
          });
          this.servicesStatus = r.data
          if (number == 0) {
            this.textServices = "PainelCliente.servicesTable.totalServices"
          } else if(number == 1) {
            this.textServices = "PainelCliente.servicesTable.openServices"
          } else if(number == 2) {
            this.textServices = "PainelCliente.servicesTable.attendedServices"
          } else if(number == 3) {
            this.textServices = "PainelCliente.servicesTable.onHoldServices"
          }
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async getFullService(id) {
      this.messageList = [];
      await this.$http
        .get(
          `User_Att_Messages_List?Customer_Id=${localStorage.getItem("customer_Id")}&Attendence_Id=${id}`,
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then((r) => {
          r.data.forEach(element => {
            if (element.message_Direction == 0) {
              element.message_Direction = "Cliente"
            } else if(element.message_Direction == 1) {
              element.message_Direction = "ChatBot"
            } else if(element.message_Direction == 2) {
              element.message_Direction = "Asesor"
            }
            element.message_Update = this.getLocalDate(element.message_Update)
          })
          this.messageList = r.data
          this.dialog = true
        })
        .catch((e) => {
          if(e.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    searchInteraction(number) {
      this.agentStatus = [];
      this.servicesStatus = [];
      this.interactionsStatus= [];
      this.messageList = [];
      this.detailsInteraction = [];
      let dateStart = this.startDate+"T00:00:00"
      let dateEnd = this.endDate+"T23:59:59"

      this.ext_opp
        .get(`Light_Dashboard_Interaction?Customer_Id=${localStorage.getItem("customer_Id")}&channel_id=${this.channel}&&start_date_time=${this.utcMethod(dateStart)}&end_date_time=${this.utcMethod(dateEnd)}&status_interaction=${number}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          r.data.forEach(element => {
            element.interaction_Start = this.getLocalDate(element.interaction_Start);
            element.last_Interaction = this.getLocalDate(element.last_Interaction);
          })
          this.interactionsStatus = r.data
          if (number == 0) {
            this.textInteractions = "PainelCliente.interactionsTable.totalInterractions"
          } else if(number == 1) {
            this.textInteractions = "PainelCliente.interactionsTable.openInteractions"
          } else if(number == 2) {
            this.textInteractions = "PainelCliente.interactionsTable.closedInteractions"
          } else if(number == 3) {
            this.textInteractions = "PainelCliente.interactionsTable.abandonedIneractions"
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          //this.loading = false
        });
    },
    getFullInteractions(number) {
      this.ext_opp
        .get(`User_Messages_List?Customer_Id=${localStorage.getItem("customer_Id")}&Client_Operation_Id=${number}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          r.data.forEach(element => {
            if (element.message_Direction == 0) {
              element.message_Direction = "Cliente"
            } else if(element.message_Direction == 1) {
              element.message_Direction = "ChatBot"
            } else if(element.message_Direction == 2) {
              element.message_Direction = "Asesor"
            }
            element.message_Update = this.getLocalDate(element.message_Update);
          })
          this.detailsInteraction = r.data
          this.dialog = true
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          //this.loading = false
        });
    },
    closeDialog() {
      this.detailsInteraction = []
      this.messageList = []
      this.dialog = false
    },
    utcMethod(date) {
      let data = new Date(date);
      var dataBase = data.toISOString().replace(/\.\d{3}Z$/, '');

      return dataBase;
    },
    forceFileDownload(response, title) {
      const blob = new Blob(this.arrayToCSV(response), { type: 'text/csv' });
      const blobURL = window.URL.createObjectURL(blob);

      const link = document.createElement('a')
      link.href = blobURL
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(blobURL);
    },
    arrayToCSV(dataArray) {
      if (!Array.isArray(dataArray) || dataArray.length === 0) {
        return '';
      }

      const csvRows = [];

      // Agregar encabezados (si es necesario)
      const headers = Object.keys(dataArray[0]);
      csvRows.push(headers.join(','));
      csvRows.push('\n')
      // Agregar filas de datos
      for (const data of dataArray) {
        const values = headers.map(header => data[header]);
        csvRows.push(values.join(','));
        csvRows.push('\n')
      }

      // Unir filas usando saltos de línea
      return csvRows;
    }
  },
  mounted() {
    if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
    } else {
        this.client = this.$store.state.currentCustomer;
    }
    this.changeCustomer();

    if (this.$store.state.panelChannels.length > 0) {
      this.channels.push({"channel_Enable": true, "channel_Code": 0, "channel_Name": "Todos"});
      this.getChannelsForPanel.forEach(ch => {
        this.channels.push(ch)
      })
    }
    if (this.getListUserFunctions.length > 0) {
      this.panelFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Painel");
    }
    this.listCustomers = this.getListDataInformation;
  },
  watch: {
    getCurrentCustomer() {
      this.client = this.getCurrentCustomer;
      this.changeCustomer()
    },
    channel() {
      this.updateGraph()
    },
    startDate() {
      this.updateGraph()
    },
    endDate() {
      this.updateGraph()
    },
    getListUserFunctions () {
      this.panelFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Painel");
    },
    getChannelsForPanel() {
      this.channels = [];
      this.channels.push({"channel_Enable": true, "channel_Code": 0, "channel_Name": "Todos"});
      this.getChannelsForPanel.forEach(ch => {
        this.channels.push(ch)
      })
    },
    getListDataInformation() {
      this.client = this.getListDataInformation[0];
      this.listCustomers = this.getListDataInformation;
    }
  }
};
</script>