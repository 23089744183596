<template>
  <v-card style="height: 100%" :class="full ? 'full' : false">
    <div id="drawflow">
      <div
        style="position: absolute; z-index: 1; width: 100%"
        class="pa-3 d-flex justify-space-between"
      >
        <v-btn
          :disabled="isDisabled"
          color="primary" 
          @click="dialog = !dialog"
          >{{ $t('ViewFluxo.addActivity') }}
        </v-btn>
        <v-chip class="mr-12"> {{ name }} </v-chip>
      </div>

      <v-card
        class="d-flex flex-column"
        style="position: absolute; right: 0; z-index: 1"
        :style="!full ? { right: '-3.5rem' } : false"
      >
        <v-btn @click="$df.zoom_in()" x-large icon color="primary">
          <v-icon>mdi-magnify-plus-outline</v-icon>
        </v-btn>

        <v-btn @click="$df.zoom_out()" x-large icon color="primary">
          <v-icon>mdi-magnify-minus-outline</v-icon>
        </v-btn>

        <v-btn @click="full = !full" x-large icon color="primary">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn @click="fixedEditorNode" x-large icon color="primary">
          <v-icon>{{
            fixed ? "mdi-lock-outline" : "mdi-lock-open-variant-outline"
          }}</v-icon>
        </v-btn>
      </v-card>
      <v-btn
        :disabled="isDisabled"
        style="position: absolute; bottom: 0; right: 0; z-index: 1"
        class="ma-8"
        color="primary"
        @click="save"
        >{{ $t('Guid.save') }}
      </v-btn>
      <v-btn
        :disabled="isDisabled"
        style="position: absolute; bottom: 0; right: 5; z-index: 1"
        class="ma-8"
        color="primary"
        @click="closeViewFlow"
        >{{ $t('Guid.close' ) }}
      </v-btn>
    </div>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span style="font-size: 25px">{{ $t('ViewFluxo.activity') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('ViewFluxo.activityName')"
                  v-model="nomeAtividade"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="tipoAtividade"
                  @change="onChangeType"
                  :items="tipoAtividades"
                  item-text="step_Type_Name"
                  item-value="step_Type_Id"
                  :label="$t('ViewFluxo.type')"
                  required
                ></v-select>
              </v-col>
              <v-col v-if="showMessage">
                <v-textarea
                  :label="$t('ViewFluxo.message')"
                  v-model="mensagemAtividade"
                  counter
                  filled
                ></v-textarea>
              </v-col>
              <v-col cols="12" v-if="showMethodIntegration">
                <v-select
                  v-model="stepMethodIntegration"
                  :items="methodIntegrationList"
                  item-text="name"
                  item-value="name"
                  :label="$t('ViewFluxo.methodIntegration')"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="showStepIntegration">
                <v-textarea
                filled
                :label="$t('ViewFluxo.stepIntegration')"
                v-model="integration">
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('ViewFluxo.messageStepIntegration') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepIntegrationParameter">
                <v-textarea
                filled
                :label="$t('ViewFluxo.stepIntegrationParameter')"
                  v-model="integrationParameter"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('ViewFluxo.messageStepIntegrationParameter') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select  v-if="showGroups"
                  v-model="integrationParameter"
                  :items="groups"
                  item-text="attendence_Group_Name"
                  item-value="attendence_Group_Id"
                  :label="$t('ViewFluxo.groups')"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select  v-if="showCampaigns"
                  v-model="integrationHeaders"
                  :items="campaigns"
                  item-text="campaign_Name"
                  item-value="campaign_Id"
                  :label="$t('ViewFluxo.campaigns')"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="showStepIntegrationHeaders">
                <v-textarea
                filled
                :label="$t('ViewFluxo.stepIntegrationHeaders')"
                  v-model="integrationHeaders"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('ViewFluxo.messageStepIntegrationHeaders') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepIntegrationAuthorize">
                <v-textarea
                filled
                :label="$t('ViewFluxo.stepIntegrationAuthorize')"
                  v-model="integrationAuthorize"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('ViewFluxo.messageStepIntegrationAuthorize') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepCalculation">
                <v-text-field
                :label="$t('ViewFluxo.stepCalculation')"
                v-model="calculation">
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="showStepImgURL">
                <v-textarea
                filled
                :label="$t('ViewFluxo.stepImgURL')"
                v-model="imgURL">
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepOperationVariable">
                <v-text-field
                :label="$t('ViewFluxo.stepOperationVariable')"
                v-model="operationVariable">
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="showStepValidation">
                <v-text-field
                :label="$t('ViewFluxo.stepValidation')"
                v-model="validation">
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span> {{ $t('ViewFluxo.messageValidation') }}</span>
                  </v-tooltip>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            {{ $t('Guid.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="cadastrarAtividade">
            {{ $t('Guid.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-snackbar v-model="snack" timeout="3000">
      {{ $t(snackText) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snack = false">
          {{ $t('Guid.close') }}
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
/*eslint-disable */
import Drawflow from "drawflow";
// Do not remove because it is necessary for the styles and move the activities in the x and y axes
import styleDrawflow from "drawflow/dist/drawflow.min.css";
import Layout from "@/components/LayoutCreateFluxo.vue";
import Vue from "vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    Layout,
  },
  props: {
    id: {
      required: true,
    },
    name: {
      required: true,
    },
    channel: {
      required: true,
    },
    flowMessage: {
      required: true,
    },
    startDate: {
      required: true,
    },
    endDate: {
      required: false,
    },
    flowExitMessage: {
      required: false,
    },
    flowLeaveMessage: {
      required: false,
    },
  },
  data() {
    return {
      exportValue: null,
      token: JSON.parse(localStorage.getItem("authUser")).token,

      dialog: false,

      nomeAtividade: "",
      tipoAtividade: "",
      mensagemAtividade: "",
      stepMethodIntegration: "",
      methodIntegrationList: [
        { name: "GET" },
        { name: "POST" },
      ],
      integration: "",
      integrationParameter: "",
      integrationHeaders: "",
      integrationAuthorize: "",
      calculation: "",
      imgURL: "",
      operationVariable: "",
      validation: "",

      data: [],

      tipoAtividades: [],

      fixed: false,
      full: false,

      stepId: 0,
      allStepId: [],

      idAtualizado: null,

      dataAtual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      client: null,
      functionality: [],
      isDisabled: false,
      groups: [],
      campaigns: [],

      // A message is displayed when trying to store an activity with no response
      snack: false,
      snackColor: "",
      snackText: "",

      showMessage: false,
      showMethodIntegration: false,
      showStepIntegration: false,
      showStepIntegrationParameter: false,
      showGroups: false,
      showCampaigns: false,
      showStepIntegrationHeaders: false,
      showStepIntegrationAuthorize: false,
      showStepCalculation: false,
      showStepImgURL: false,
      showStepOperationVariable: false,
      showStepValidation: false,

    };
  },
  methods: {
    onChangeType() {
      switch (this.tipoAtividade) {
        case 1 || 14:
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = true;
          break;
        case 2 || 13: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = true;
          break;
        case 3:
          this.showMessage = false,
          this.showMethodIntegration = true,
          this.showStepIntegration = true,
          this.showStepIntegrationParameter = true,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = true,
          this.showStepIntegrationAuthorize = true,
          this.showStepCalculation = true,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 4: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = false;
          break;
        case 5:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 6: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = true,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 9:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 10: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = true,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = true,
          this.showStepValidation = false;
          break;
        case 11:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = true,
          this.showGroups = false,
          this.showCampaigns = true,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = true,
          this.showStepValidation = false;
          break;
        case 12: 
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 15: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = true;
          break;
        default:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
      }
    },
    listarFluxoPassos() {
      this.$http
        .get(`List_Flow_Steps?Flow_Id=${this.id}&Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.data = r.data;
          this.$df.addModule("Fluxograma");
          this.$df.changeModule("Fluxograma");

          let posX = 0;
          let posY = 0;
          for (let i = 0; i < r.data.length; i++) {
            // console.log(r.data[i]);
            let reg = /\|\!~/g;
            let message = r.data[i].step_Message.replace(reg,"\n");
            if (r.data[i].pos_X == 0 && r.data[i].pos_Y == 0) {
              posX >= 800 ? (posX = 1) : (posX += 380);
              posX <= 800 ? (posY += 200) : false;
            } else {
              posX = r.data[i].pos_X;
              posY = r.data[i].pos_Y;
            }
            this.$df.registerNode("Fluxo", Layout, {
              // stepId: r.data[i].step_Id,
              // stepName: r.data[i].step_Name,
              // message: r.data[i].step_Message,
            });
            this.$df.addNode(
              r.data[i].step_Id.toString(),
              1,
              r.data[i].transitions.length,

              posX,
              posY,

              r.data[i].step_Id.toString(),
              {
                stepId: r.data[i].step_Id,
                stepName: r.data[i].step_Name,
                stepType: r.data[i].step_Type,
                message: message,
                stepMethodIntegration: r.data[i].step_Integration_Method,
                stepIntegration: r.data[i].step_Integration,
                stepIntegrationParameter: r.data[i].step_Integration_Parameter,
                stepIntegrationHeaders: r.data[i].step_Integration_Headers,
                stepIntegrationAuthorize: r.data[i].step_Integration_Authorize,
                stepCalculation: r.data[i].step_Calculation,
                stepImgURL: r.data[i].step_Image_URL,
                stepOperationVariable: r.data[i].step_Operational_Variable,
                stepValidation:  r.data[i].step_Validation,
                transitions: r.data[i].transitions,
              },
              "Fluxo",
              "vue"
            );
          }
          for (let i = 0; i < r.data.length; i++) {
            for (let i2 = 0; i2 < r.data[i].transitions.length; i2++) {
              if (r.data[i].transitions[i2].next_Step_Id >= 0) {
                this.connection = i;
                this.$df.addConnection(
                  this.$df.getNodesFromName(r.data[i].step_Id.toString())[0],
                  this.$df.getNodesFromName(
                    r.data[i].transitions[i2].next_Step_Id.toString()
                  )[0],
                  `output_${i2 + 1}`,
                  "input_1"
                );
              }
            }
          }
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          for (let i = 1; i <= this.data.length; i++) {
            this.$df.updateConnectionNodes(`node-${i}`);
          }
        });
    },
    async cadastrarAtividade() {

      let y = this.$df?.drawflow?.drawflow?.Fluxograma?.data[this.stepId + 1]?.pos_y + 10;
      let x = this.$df?.drawflow?.drawflow?.Fluxograma?.data[this.stepId + 1]?.pos_x + 10;

      this.stepId += 1;
      this.$df.registerNode("Fluxo2", Layout, {});

      // let integrationResponse = [
      //   {
      //     transition_Value: "true",
      //     next_Flow_Id: 0,
      //     next_Step_Id: 0,
      //     variable_Value: "",
      //   },
      //   {
      //     transition_Value: "false",
      //     next_Flow_Id: 0,
      //     next_Step_Id: 0,
      //     variable_Value: "",
      //   }
      // ]

      this.$df.addNode(
        this.stepId.toString(),
        1,
        0,

        x,
        y,

        this.stepId.toString(),
        {
          stepId: this.stepId,
          stepName: this.nomeAtividade,
          stepType: this.tipoAtividade,
          message: this.mensagemAtividade,
          stepMethodIntegration: this.stepMethodIntegration,
          stepIntegration: this.integration,
          stepIntegrationParameter: this.tipoAtividade == 6 ? this.integrationParameter.toString():  this.integrationParameter,
          stepIntegrationHeaders: this.integrationHeaders,
          stepIntegrationAuthorize: this.integrationAuthorize,
          stepCalculation: this.calculation,
          stepImgURL: this.imgURL,
          stepOperationVariable: this.operationVariable,
          stepValidation:  this.validation,
          transitions: []// this.tipoAtividade == 3 ? integrationResponse : [],
        },
        "Fluxo2",
        "vue"
      );
      this.dialog = false;
      this.nomeAtividade = "";
      this.tipoAtividade = "";
      this.mensagemAtividade = "";
      this.stepMethodIntegration = "";
      this.integration = "";
      this.integrationParameter = "";
      this.stepIntegrationHeaders = "";
      this.stepIntegrationAuthorize = "";
      this.calculation = "";
      this.imgURL = "";
      this.operationVariable = "";
      this.validation = "";
    },
    fixedEditorNode() {
      if (!this.fixed) {
        this.$df.editor_mode = "fixed";
        this.fixed = true;
      } else {
        this.$df.editor_mode = "edit";
        this.fixed = false;
      }
    },
    async save () {
      this.isDisabled = true;
      let isOkReplys = this.validateReplys();
      let isOkInputs = this.validateInputs();

      if (isOkReplys && isOkInputs) {

        let flow_Steps = [];
        let newStep = {};
        let stepInitialNumber = 0;
        const data = this.$df.drawflow.drawflow["Fluxograma"].data;
  
        for (let i in data) {
          let transitions = [];
          let newTransition = {};
          
          for (let i2 in data[i].data.transitions) {
            const connections =
              data[i].outputs[`output_${parseInt(i2) + 1}`].connections;
            let output = await connections.map((value) => {
              return this.$df.getNodeFromId(value.node).name;
            });
  
            newTransition = {
              transition_Value: data[i].data.transitions[i2].transition_Value,
              next_Flow_Id : 0,
              next_Step_Id : output[0] ? parseInt(output[0], 10) : parseInt(data[i].data.transitions[i2].next_Step_Id, 0),
              variable_Value : data[i].data.transitions[i2].variable_Value
            }
  
            transitions.push(newTransition);
          }
  
          if (data[i].data.stepName == "Start") {
            stepInitialNumber = data[i].data.stepId;
          }
          
          newStep = {
            step_Id: data[i].data.stepId,
            step_Name: data[i].data.stepName,
            step_Type: data[i].data.stepType,
            step_Message: data[i].data.message.replace(/(\r\n|\n|\r)/gm,"|!~"),
            step_Integration_Method: data[i].data.stepMethodIntegration.toString(),
            step_Integration_Parameter: data[i].data.stepIntegrationParameter.toString(),
            step_Integration_Headers: data[i].data.stepIntegrationHeaders.toString(),
            step_Integration_Authorize: data[i].data.stepIntegrationAuthorize.toString(),
            step_Integration: data[i].data.stepIntegration,
            step_Calculation: data[i].data.stepCalculation,
            step_Image_URL: data[i].data.stepImgURL,
            step_Operational_Variable: data[i].data.stepOperationVariable,
            step_Validation: data[i].data.stepValidation,
            pos_X: data[i].pos_x.toFixed(0),
            pos_Y: data[i].pos_y.toFixed(0),
            additional_var: "",
            initial_Step: data[i].data.stepName == "Start" ? true : false,
            transitions: transitions
          }
  
          flow_Steps.push(newStep);
        }
  
        let customer = localStorage.getItem("customer_Id")
  
        let Data_Flow_Upd = {
          customer_Id: customer,
          flow_Id: this.id == null || this.id == undefined ? 0 : this.id,
          flow_Name: this.name,
          channel_Id: this.channel,
          initial_Step_Id: stepInitialNumber,
          identify_Initial_Message: this.flowMessage,
          start_DateTime: this.startDate,
          end_DateTime: this.endDate,
          exit_String: this.flowExitMessage,
          exit_Message: this.flowLeaveMessage,
          steps: flow_Steps
        }
  
        let config = {
          headers: {
            'Authorization': `bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }
  
        await this.$http
          .post('Data_Flow_Update', Data_Flow_Upd, config)
        .then(() =>{
          console.log("Salvo com Sucesso");
          this.isDisabled = false
          this.$router.push({ name: 'fluxo' });
          this.emitReload();
        })
        .catch((e) =>{
          console.log(e)
          this.isDisabled = false;
        });

      } else {
        this.isDisabled = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "ViewFluxo.snackTextActivityWrong";
      }

    },
    validateInputs() {
      let haveReplys = true;
      let data = this.$df.drawflow.drawflow["Fluxograma"].data;
      for(let node in data) {
        if (data[node].data.stepName != "Start" && data[node].inputs.input_1.connections.length == 0) {
          haveReplys = false;
        }
      }
      return haveReplys;
    },
    validateReplys() {
      let haveReplys = true;
      let data = this.$df.drawflow.drawflow["Fluxograma"].data;
      for(let node in data) {
        if (data[node].data.transitions == null || data[node].data.transitions.length == 0) {
          haveReplys = false;
        }
        if (data[node].data.transitions.length > 1){
          for(let j = 0; j< data[node].data.transitions.length - 1; j++){
            if (data[node].data.transitions[j].transition_Value == data[node].data.transitions[j+1].transition_Value){
              haveReplys = false;
            }
          }
        }
        
      }
      return haveReplys;
    },
    listStepType() {
      this.$http
        .get(`List_Step_Type?Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.tipoAtividades = r.data
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
      this.isLoadedListType = true;
    },
    emitReload() {
      this.$store.dispatch('listarFluxo', localStorage.getItem("customer_Id"));
    },
    setInitialVars() {

      if (this.$store.state.currentCustomer == null) {
        this.$router.push({ name: 'fluxo' });
      } else {
        this.client = this.$store.state.currentCustomer;
      }

      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Flow");
      if (this.functionality.functionality_Level < 2) {
        this.$router.push({ name: 'painelCliente' })
      }

      this.groups = this.getListGroups;
      this.campaigns = this.getListCampaigns;
      this.havePermissions = false;
    },
    closeViewFlow() {
      this.$router.push({ name: 'fluxo' });
    }
  },
  watch: {
    id() {
      this.$df.removeModule("Fluxograma");
      this.listarFluxoPassos();
    },
    functionality() {
      this.isDisabled = this.functionality.functionality_Level == 2 ? true : false
    }
  },
  computed: {
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListCampaigns']),
    ...mapGetters(['getListGroups']),
  },
  mounted() {
    this.setInitialVars();
    const id = document.getElementById("drawflow");
    Vue.prototype.$df = new Drawflow(id, Vue, this);
    this.$df.start();

    var that = this;
    this.$df.on("connectionCreated", function ({ output_id, output_class }) {
      let conexaoAtual =
        that.$df.getNodeFromId(output_id).outputs[output_class].connections;
      if (conexaoAtual.length > 1) {
        that.$df.removeSingleConnection(
          output_id,
          conexaoAtual[0].node,
          output_class,
          conexaoAtual[0].output
        );
      }
    });

    if (this.id == 0 || this.id == null) {
      this.$df.addModule("Fluxograma");
      this.$df.changeModule("Fluxograma");
      this.$df.registerNode("Fluxo2", Layout, {});
      this.$df.addNode(
        "0",
        1,
        0,

        100,
        100,

        "0",
        {
          stepId: 0,
          stepName: "Start",
          stepType: 1,
          message: "",
          stepMethodIntegration: "",
          stepIntegration: "",
          stepIntegrationParameter: "",
          stepIntegrationHeaders: "",
          stepIntegrationAuthorize: "",
          stepCalculation: "",
          stepImgURL: "",
          stepOperationVariable: "",
          stepValidation: "",
          transitions: [],
        },
        "Fluxo2",
        "vue"
      );
    } else {
      this.listarFluxoPassos();
    }

    // console.log(this.$df);

    this.listStepType();
  },
};
</script>

<style>
#drawflow {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.full {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
.drawflow .drawflow-node {
  display: flex;
  align-items: center;
  position: absolute;
  background: #0ff;
  width: 282px;
  min-height: 40px;
  border-radius: 4px;
  border: 2px solid #000;
  color: #000;
  z-index: 2;
  padding: 15px;
}
</style>