<template>
    <div v-if="functionality.functionality_Level >= 2">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="listGroupsInfo"
              :sort-by-text="$t(headers.text)"
              disable-pagination
              hide-default-footer
              class="elevation-1"
            >
              <template #[`item.attendence_Group_Id`]="props">
                <v-btn :disabled="isDisabled" @click="edit(props.item.attendence_Group_Id)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
  
  
      <v-btn
        :disabled="isDisabled"
        @click="$router.push({ name: 'group' })"
        style="float: right"
        class="my-2"
        color="primary"
        >{{ $t('ListGroup.newGroup') }}</v-btn
      >
  
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ $t(snackText) }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> {{ $t('ListCampaign.close') }} </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-else>
      <v-dialog v-model="havePermissions">
        <v-card>
            <v-progress-linear
            indeterminate
            color="blue darken-2"
            ></v-progress-linear>
        </v-card>
      </v-dialog>
    </div>
  </template>
    
    <script>
    import router from '@/router';
    import { mapGetters } from 'vuex';
    
    export default {
      data() {
        return {
          snack: false,
          snackColor: "",
          snackText: "",
    
          token: JSON.parse(localStorage.getItem("authUser")).token,
          
          groups: [],
          listChannels: [],
          client: "",
          functionality: [],
          listCustomers : [],
          havePermissions: false,

          isDisabled: false
        };
      },
      methods: {
        edit(id) {
          this.$router.push({ name: "updateGroup", params: { id: id } });
        },
        async listGroup() {
          this.$store.dispatch('listGroup', localStorage.getItem("customer_Id"));
        },
        async listChannel() {
          await this.$http
            .get(`List_Channel?Customer_Id=${this.client.customer_Id}`, {
              headers: {
                accept: "*/*",
                Authorization: `bearer ${this.token}`,
              },  
            })
            .then((r) => {
              this.listChannels = r.data
            })
            .catch(() => {
              //console.log("error obteniendo la lista de las campañas",e)
            })
            .finally(() => {
              //this.loading = false
            });
        },
        setInitialVars() {
          this.listCustomers = this.getListDataInformation;
          if (this.$store.state.currentCustomer == null) {
            this.client = this.getListDataInformation[0];
          } else {
            this.client = this.$store.state.currentCustomer;
          }
          this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence Group");
          if (this.functionality.functionality_Level < 2) {
            router.push({ name: 'painelCliente' })
          } else {
            this.listChannel()
            this.groups = this.getListGroups;
          }
          this.havePermissions = false;
        }
      },
      mounted() {
        this.havePermissions = true;
        if(this.$store.state.permissions.length == 0) {
          setTimeout(() => {
            this.setInitialVars();
          },
          1000);
        } else {
          this.setInitialVars();
        }
      },
      computed: {
        headers() {
          return [
            {
              text: this.$t("ListGroup.groupName"),
              value: "attendence_Group_Name",
              width: "20%",
              align: "center",
              class: "header",
            },
            {
              text: this.$t("ListGroup.channelName"),
              value: "channel_Name",
              width: "16%",
              align: "center",
              class: "header",
            },
            {
              text: this.$t("ListCampaign.edit"),
              value: "attendence_Group_Id",
              width: "14%",
              align: "center",
              class: "header",
              sortable: false,
            },
          ]
        },
        listGroupsInfo(){
          if (this.getListGroups.length == 0 || this.listChannels.length == 0) {
            return []
          }
          else {
            return this.getListGroups.map(ng => {
              return Object.assign({},ng,this.listChannels.reduce(function(count, channel) {
                if (channel.channel_Id == ng.attendence_Channel_Id){
                  return channel
                }
                else {
                  return count
                }
              }))
            })
          }
        },
        ...mapGetters(['getListDataInformation']),
        ...mapGetters(['getListUserFunctions']),
        ...mapGetters(['getListGroups']),
        ...mapGetters(['getCurrentCustomer'])
      },
      watch: {
      getCurrentCustomer() {
        this.client = this.getCurrentCustomer;
      },
      functionality() {
        this.isDisabled = this.functionality.functionality_Level == 2 ? true : false
      }
    }
    };
    </script>
    
    <style>
    .header :first-child {
      font-size: 17px;
      padding-left: 18px;
      color: black;
    }
    </style>