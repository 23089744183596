<template>
  <v-container
    class="pb-0"
    style="background-color: #9cd9e875; min-height: 100vh"
    :style="{
      overflow:
        $vuetify.breakpoint.width > '960' || menuMobile == 'conv'
          ? 'hidden'
          : '',
    }"
    fluid
  >
    <v-row style="background-color: #9cd9e8; justify-content: center">
      <v-col class="pa-0" cols="11">
        <MenuTopChat :menuMobileConfigActive="menuMobileConfigActive" />
      </v-col>
    </v-row>
    <v-row style="justify-content: center; height: 100%">
      <v-col
        v-if="
          !($vuetify.breakpoint.width < '960' && menuMobile != 'atendimento')
        "
        class="mr-1"
        cols="12"
        md="4"
      >
        <FilaAtendimento
          @processNewMessage="processNewMessage"
          @conversaDados="conversaDados = $event"
          @messageAuto = "messageAuto = $event"
          @errorMessage="errorMessage = $event"
          :errorMessage="errorMessage"
          :messageChat="messageChat"
          @convMobile="menuMobile = $event"
          :enviarMensagem="enviarMensagem"
          :historicButton="historicButton"
          :reloadServices="reloadServices"
          @logOutUnactive="logOutUnactive"
          :telephoneArray="telephoneArray"
          @correctNewMessage="correctNewMessage"
          @listMessages="listMessages"
          @attentionActive="attentionActive = $event"
          :messageWelcome="messageWelcome"
          :updateName="updateName"
          :client_Id="client_Id"
        />
      </v-col>
      <v-col
        v-if="!($vuetify.breakpoint.width < '960' && menuMobile != 'conv')"
        cols="12"
        md="7"
      >
        <MainChat
          @enviarMensagem="enviarMensagem = $event"
          @messageChat="messageChat = $event"
          @historicButton="historicButton = $event"
          @reloadServices="reloadServices = $event"
          @voltarMenuMobile="voltarMenuMobile = $event"
          :conversaDados="conversaDados"
          :messageAuto="messageAuto"
          @messageAuto="messageAuto = $event"
          :errorMessage="errorMessage"
          @errorMessage="errorMessage = $event"
          @InServices="removeData"
          :telephone="telephone"
          :notifyMessage="notifyMessage"
          @updateMessage="updateMessage"
          @notifyMessageInMyChats="notifyMessageInMyChats"
          @logOutUnactive="logOutUnactive"
          :newMessagesArray="newMessagesArray"
          :goToMessages="goToMessages"
          @listMessages="listMessages"
          @messageWelcome="messageWelcome = $event" 
          @updateClient="updateClient"
        />
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.width < '960' && menuMobile != 'conv'">
      <v-col class="pa-0 ma-0 mt-n11">
        <v-bottom-navigation :value="menuMobileAtual" grow color="primary">
          <v-btn @click="[(menuMobile = 'atendimento')]">
            <span>{{ $t('MyAtendimiento.atendimentos') }}</span>
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
          <v-btn
            :disabled="
              convAtual == null && conversaDados.length == 0 ? true : false
            "
            text
            @click="menuMobile = 'conv'"
          >
            <span>{{ $t('MyAtendimiento.currentConversation') }}</span>
            <v-icon
              v-if="
                convAtual == null && conversaDados.length == 0 ? true : false
              "
              >mdi-message-text-lock</v-icon
            >
            <v-icon v-else>mdi-message-text</v-icon>
          </v-btn>
          <v-btn @click="menuMobileConfigActive = !menuMobileConfigActive">
            <span>{{ $t('MyAtendimiento.settings') }}</span>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" timeout="3000">
      {{ $t(snackText) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snack = false">
          {{ $t('Guid.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import MenuTopChat from "@/components/MenuTopChat.vue";
import FilaAtendimento from "@/components/FilaAtendimentoChat.vue";
import MainChat from "@/components/MainAtendimentoChat.vue";
import { CONNECTIONSIGNALR } from "../plugins/signalR"
import router from '@/router';
import { mapGetters } from 'vuex';

export default {
  components: {
    MenuTopChat,
    FilaAtendimento,
    MainChat,
  },
  data() {
    return {
      conversaDados: {},
      messageAuto:"",
      errorMessage: false,
      convAtual: JSON.parse(localStorage.getItem("convAtual")),
      menuMobile: "atendimento",

      menuMobileConfigActive: null,
      voltarMenuMobile: false,

      enviarMensagem: false,
      historicButton: false,
      reloadServices: true,
      messageChat: "",

      snack: false,
      snackColor: '',
      snackText: '',
      
      client: "",
      functionality: [],
      listCustomers : [],
      havePermissions: true,
      telephone:"",
      notifyMessage: true,
      timeUnactive: Date.now(),
      telephoneArray: [],
      messageWelcome: "",
      newMessagesArray: [],
      goToMessages: false,
      interval: null,
      attentionActive:[],
      updateName: "",
      client_Id: ""
    };
  },
  computed: {
    menuMobileAtual() {
      if (this.menuMobile == "atendimento") {
        return 0;
      } else if (this.menuMobile == "conv") {
        return 1;
      }
      return 0;
    },
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getCurrentCustomer']),
  },
  watch: {
    voltarMenuMobile(voltarMenuMobile) {
      if (voltarMenuMobile) {
        this.menuMobile = "atendimento";
        this.voltarMenuMobile = false;
      }
    },
  },
  methods: {
    setInitialVars() {
      this.listCustomers = this.getListDataInformation;

      if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
      } else {
        this.client = this.$store.state.currentCustomer;
      }
      
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence");
      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' })
      }
      this.havePermissions = false;
    },
    removeData() {
      this.conversaDados = {}
    },
    processNewMessage(r,t,m) {
      this.newMessagesArray = m;
      this.telephone = t;
      this.notifyMessage = r;
    },
    correctNewMessage(t){
      var position = this.telephoneArray.indexOf(t);
      this.telephoneArray.splice(position,1)
      this.telephone = null;
      this.notifyMessage = null;
    },
    updateMessage() {
      this.telephone = null;
      this.notifyMessage = null;
    },
    notifyMessageInMyChats(t) {
      this.telephone = t;
      if(this.telephone !== null && this.telephone !== this.conversaDados.client_Name){
        this.telephoneArray.push(t)
      }
    },
    logOutUnactive() {
      this.timeUnactive = Date.now()
    },
    async logout() {
      this.$store.commit('setDisconnectedInactivity', true);
      await CONNECTIONSIGNALR.stop();
      localStorage.removeItem("authUser");
      localStorage.removeItem("convAtual");
      this.$store.commit('setInitialData')
      this.$router.push({ name: "home" });
    },
    listMessages(r) {
      this.goToMessages = r;
    },
    updateClient(newName) {
      this.conversaDados.client_Name = newName;
      this.$http
        .get(`/User_Att_Details/Client_Update?Customer_Id=${this.$store.state.currentCustomer.customer_Id}&Client_Identify=${this.conversaDados.client_Identify}&Client_Name=${this.conversaDados.client_Name}&Client_Id=${this.conversaDados.client_Id}`, {
          headers: {
            accept: '*/*',
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          if(r.status==200)
          {
            //SNACKBAR
            this.snack = true;
            this.snackColor = 'success';
            this.snackText = "MyAtendimiento.snackTextSuccess";
          }
          this.updateName = newName;
          this.client_Id = this.conversaDados.client_Id;
        })
        .catch((error) => {
          console.log(error);
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = "MyAtendimiento.snackTextError";
        })
        .finally(() => {
          //this.loading = false
        });
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 0) {
      setTimeout(() => {
        this.setInitialVars();
      },
      1500);
    } else {
      this.setInitialVars();
    }

    this.interval = setInterval(
      function () {
        if (Date.now() - this.timeUnactive >= 1800000 && this.attentionActive.length === 0){ //Thirty minutes
          this.timeUnactive = Date.now()
          this.logout()
        }
      }.bind(this),
      120000 //Two minutes
    );
  },
  beforeDestroy() {
    clearInterval(this.interval)
    CONNECTIONSIGNALR.stop();
  }
  
};
</script>

<style>
body {
  background-color: #9cd9e875;
}
</style>