<template>
    <v-container v-if="functionality.functionality_Level >= 2">
      <v-row>
        <v-col cols="12" v-if="functionality.functionality_Level >= 2">
          <v-card>
            <v-card-title>{{ $t('Reports.listCustomersConfiguration') }}</v-card-title>
            <v-card-text>
              <v-select
                :disabled="isImporting"
                @change="listarReports"
                return-object
                v-model="client"
                :items="listCustomers"
                item-text="customer_Name"
                item-value="customer_Id"
                :label="$t('Reports.clientName')"
                required
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="12">
          <v-card>
            <v-autocomplete
              v-model="reportSearch"
              :items="getListReports"
              item-text="report_Name"
              return-object
              dense
              filled
              :label="$t('Reports.search')"
            ></v-autocomplete>
            <v-list style="max-height: 70vh; overflow: auto" class="mt-n6">
              <v-list-group :value="true" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Reports.reports') }}</v-list-item-title>
                  </v-list-item-content>
                </template>
        
                <v-list-item
                  :disabled="isImporting"
                  style="min-height: 0"
                  class="d-flex flex-column px-0 py-5"
                  v-for="(report, i) in getListReports"
                  :key="i"
                  link
                  @click="routeWithNewInformation(i)"
                >
                  <v-list-item-title>{{ report.report_Name }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>

          </v-card>
        </v-col>
      </v-row>
    
    </v-container>
    <div v-else>
      <v-dialog v-model="havePermissions">
        <v-card>
          <v-progress-linear
            indeterminate
            color="blue darken-2"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import router from '@/router';
  import { mapGetters } from 'vuex';
  
  export default {
    data() {
      return {
        token: JSON.parse(localStorage.getItem("authUser")).token,
        reportSearch: "",
        listCustomers: [],
        client: null,
        functionality: [],
        havePermissions: false,
  
        // Import flows prop
        importNewFlow: false,
        selectedFile: null,
        exportedFlowId: 0,
        oldStepIds: [],
        newStepIds: [],
        isImporting: false
      };
    },
    computed: {
      ...mapGetters(['getListFlows']),
      ...mapGetters(['getListReports']),
      ...mapGetters(['getListDataInformation']),
      ...mapGetters(['getListUserFunctions'])
    },
    methods: {
      routeWithNewInformation(index) {
        this.$router.push({ name: 'update-reports',},() => {})   
        
        this.$store.commit('setCurrent_Report',this.getListReports[index].report_Data)
        
      },
      listarReports() {
        localStorage.removeItem("customer_Id");
        localStorage.setItem("customer_Id", this.client.customer_Id);
        this.$store.commit('setCurrentCostumer', this.client);
        this.$store.dispatch('listGroup', localStorage.getItem("customer_Id"));
        this.$store.dispatch('campaignList', localStorage.getItem("customer_Id"));
        this.$store.dispatch('listStepType', localStorage.getItem("customer_Id"));
      },
      setInitialVars() {
  
        this.listCustomers = this.getListDataInformation;
        this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Report");
  
        if (this.$store.state.currentCustomer == null) {
          if(this.getListDataInformation.length > 1) {
            this.client = null;
          } else if(this.getListDataInformation.length == 1) {
            this.client = this.getListDataInformation[0];
          } else if(this.getListDataInformation.length == 0) {
            this.client = null;
          }
        } else {
          this.client = this.$store.state.currentCustomer;
        }
  
        if (this.functionality.functionality_Level < 2) {
          router.push({ name: 'painelCliente' })
        }
  
        this.listarReports();
        this.havePermissions = false;
  
      },
    },
    filters: {
      formatData(value) {
        if (!value) return "";
        value = value.split("T")[0].split("-").reverse().join("/");
        return value;
      },
    },
    watch: {
      reportSearch(value) {
        value != ""
          ? this.$router.push(
              {
                name: "update-reports",
                // params: {
                //   id: value.flow_Id,
                //   name: value.flow_Name,
                //   channel: value.channel_Id,
                //   flowMessage: value.identify_Initial_Message,
                //   startDate: value.start_DateTime,
                //   endDate: value.end_DateTime,
                //   flowExitMessage: value.exit_String,
                //   flowLeaveMessage: value.exit_Message
                // },
              },
              () => {}
            )
          : false;
      },
    },
    mounted() {
      this.havePermissions = true;
      if(this.$store.state.permissions.length == 0) {
        setTimeout(() => {
          this.setInitialVars();
        },
        1000);
      } else {
        this.setInitialVars();
      }
    },
  };
  </script>
  
  <style>
  </style>