import * as signalR from "@microsoft/signalr";
// import * as signalR from "@microsoft/signalr/dist/browser/signalr";

const CONNECTIONSIGNALR = new signalR.HubConnectionBuilder()
.withUrl(`${process.env.VUE_APP_ENV_ATT}`,{
    transportType: signalR.HttpTransportType.WebSockets
})
.withAutomaticReconnect()
.build();
console.log(CONNECTIONSIGNALR.state);
export { CONNECTIONSIGNALR }
