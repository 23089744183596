<template>
  <div v-if="functionality.functionality_Level >= 2">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="height: 100%">
          <v-card-title>{{ $t('NewCampaign.campaign') }}</v-card-title>
          <v-card-text>
          {{ $t('NewCampaign.campaignName') }}
          <v-text-field v-model="campaignName" solo></v-text-field>
          <v-checkbox
            v-model="availableAgent"
            :label="$t('NewCampaign.agent')"
          ></v-checkbox>
          </v-card-text>
          <v-card-title class="pb-0">{{ $t('NovoCadastro.state') }}</v-card-title>
          <v-row>
            <v-col>
              <v-card-text class="py-0">
                <v-col cols="12">
                  {{ $t('NewCampaign.startDate') }}
                  <v-menu
                  ref="menuAtivo"
                  v-model="menuAtivo"
                  :close-on-content-click="false"
                  :return-value.sync="dateAtivo"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="dateAtivo"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateAtivo" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuAtivo = false">
                      {{ $t('Guid.cancel') }}
                      </v-btn>
                      <v-btn
                      text
                      color="primary"
                      @click="$refs.menuAtivo.save(dateAtivo)"
                      >
                      {{ $t('Guid.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-radio-group dense v-model="typeClient">
                    <v-radio :label="$t('NewCampaign.flow')" :value="true"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-text class="py-0">
                <v-col cols="12">
                  {{ $t('NewCampaign.endDate') }}
                  <v-menu
                  ref="menuInativo"
                  v-model="menuInativo"
                  :close-on-content-click="false"
                  :return-value.sync="dateInativo"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="dateInativo"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateInativo" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuInativo = false">
                      {{ $t('Guid.cancel') }}
                      </v-btn>
                      <v-btn
                      text
                      color="primary"
                      @click="$refs.menuInativo.save(dateInativo)"
                      >
                      {{ $t('Guid.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-radio-group dense v-model="typeClient">
                    <v-radio :label="$t('NewCampaign.email')" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card style="height: 100%" v-if="typeClient">
          <v-card-title>{{ $t('NewCampaign.flow') }}</v-card-title>
            <v-card-text>
            <v-select
              v-model="flow"
              :items="itemFlow"
              item-text="flow_Name"
              item-value="flow_Id"
              required
              @change = "changeFlow"
              :label="
               $t('NewCampaign.nameFlow')
              "
              ></v-select>
            </v-card-text>
            <v-card-title>{{ $t('NewCampaign.interval') }}</v-card-title>
            <v-card-text>
              {{ $t('NewCampaign.timeRange') }}
              <v-menu
              ref="menuInterval"
              v-model="menuInterval"
              :close-on-content-click="false"
              :return-value.sync="dateInterval"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  v-model="dateInterval"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateInterval" range no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuInterval = false">
                  {{ $t('Guid.cancel') }}
                  </v-btn>
                  <v-btn
                  text
                  color="primary"
                  @click="$refs.menuInterval.save(dateInterval.sort()); intervalFunction()"
                  >
                  {{ $t('Guid.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              {{ $t('NewCampaign.timeInterval') }}
              <v-text-field v-model="interval" solo :disabled = "true"></v-text-field>
            </v-card-text>
        </v-card>
        <v-card style="height: 100%" v-else>
          <v-card-title>{{ $t('NewCampaign.email') }}</v-card-title>
            <v-card-text>
              {{ $t('NewCampaign.subject') }}
              <v-text-field v-model="subject" solo></v-text-field>
              {{ $t('NewCampaign.template') }}
              <v-text-field v-model="template" solo></v-text-field>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
      <v-card style="height: 100%">
        <v-card-title>{{ $t('NewCampaign.group') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
              v-model="idGroup"
              :items="nivelAcesso"
              item-text="attendence_Group_Name"
              item-value="attendence_Group_Id"
              required
              :label="
               $t('NewCampaign.attendenceGroup')
              "
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>
    <v-row> 
      <v-card-actions style="float: right">
        <v-btn
          :block="$vuetify.breakpoint.width < '960' ? true : false"
          color="primary"
          @click="save"
        >
          {{ $t('Guid.save') }}
        </v-btn>
        <v-btn
          :block="$vuetify.breakpoint.width < '960' ? true : false"
          color="red"
          @click="closeCampaign"
        >
          {{ $t('Guid.close') }}
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ $t(snackText) }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  props: ["id"],
  data: () => ({
    flow: 0,
    flowName: "",
    itemFlow: [],
    stepId: 0,
    campaignId: "",
    campaignName: "",
    channelId: 0,
    originCampaignId: 0,
    subject: "",
    template: "",
    interval: 0,
    typeClient: true,
    availableAgent : true,

    nivelAcesso: [],
    idGroup: 0,

    dateAtivo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuAtivo: false,

    dateInativo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuInativo: false,

    dateInterval:[new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),],
    menuInterval: false,

    situacaoRadio: false,

    token: JSON.parse(localStorage.getItem("authUser")).token,

    snack: false,
    snackColor: "",
    snackText: "",

    client: "",
    storeClient: "",

    campaign: "",
    flowNew: "",

    havePermissions: false,
  }),
  methods:{
    closeCampaign(){
      this.$router.push({ name: 'listCampaign' });
    },
    intervalFunction(){
      this.interval = (Date.parse(this.dateInterval[1])-Date.parse(this.dateInterval[0]))/3.6e6
    },
    async campaignDetails() {
        await this.$http
        .get(`Campaign_List?Customer_Id=${this.$store.state.currentCustomer.customer_Id}`, {
            headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
            },
        })
        .then((r) => {
          this.campaign = r.data.find((f) => {
              return f.campaign_Id == this.id
          })
          this.campaignId = this.campaign.campaign_Id;
          this.dateAtivo = this.campaign.start_Date.split("T")[0];
          this.dateInativo = this.campaign.end_Date.split("T")[0];
          this.campaignName = this.campaign.campaign_Name;
          this.flow = this.campaign.flow_Id;
          this.stepId = this.campaign.step_Id;
          this.originCampaignId = this.campaign.origin_Campaign_Id;
          this.subject = this.campaign.subject;
          this.template = this.campaign.template; 
          this.interval = this.campaign.interval;
          this.idGroup = this.campaign.attendence_Group_Id;
          this.flowName = this.campaign.flow_Name;
          this.channelId = this.campaign.channel_Id;
          this.typeClient = this.channelId == 6 ? false : this.typeClient;
          this.availableAgent = this.campaign.enable_Agent
        })
        .catch(() => {
            //this.error = true
        })
        .finally(() => {
            //this.loading = false
        });
    },
    existantFlows(){
      this.$http
        .get(`List_Only_Flow?Customer_Id=${this.$store.state.currentCustomer.customer_Id}`, {
          headers: {
          accept: "*/*",
          Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.itemFlow = r.data
          this.flowNew = this.itemFlow.find((f) => {
            return f.flow_Id == this.flow
          }) 
          this.flow = this.flowNew.flow_Id;
          this.stepId = this.flowNew.initial_Step_Id;
          this.flowName = this.flowNew.flow_Name;
          this.channelId = this.flowNew.channel_Id;
        })
        .catch(() => {
            //this.error = true
        })
        .finally(() => {
            //this.loading = false
        });
    },
    changeFlow(){
      this.flowNew = this.itemFlow.find((f) => {
        return f.flow_Id == this.flow
      })
      this.flow = this.flowNew.flow_Id;
      this.stepId = this.flowNew.initial_Step_Id;
      this.flowName = this.flowNew.flow_Name;
      this.channelId = this.flowNew.channel_Id;
    },
    async save() {
      let component = this;
      let dataCampaign = {
        customer_Id: this.$store.state.currentCustomer.customer_Id,
        campaign_Id: this.id == null ? 0: this.id,
        campaign_Name: this.campaignName,
        flow_Id: this.flow,
        step_Id: this.stepId,
        flow_Name: this.flowName,
        start_Date: this.dateAtivo.concat('T00:00:00.000Z'),
        end_Date: this.dateInativo.concat('T23:59:59.590Z'),
        attendence_Group_Id: this.idGroup,
        channel_Id: this.typeClient ? this.channelId : 6,
        origin_Campaign_Id : this.originCampaignId,
        subject : this.subject,
        template : this.template,
        interval : this.interval,
        enable_Agent : this.availableAgent
      }
      // await this.$http
      // .post(
      //   `Campaign_Update`,
      //   {
      //     data: dataCampaign
      //   },
      //   {
      //     headers: {
      //       accept: "*/*",
      //       Authorization: `bearer ${this.token}`,
      //     },
      //   }
      // )
      // .then((r) => {
      //   this.snack = true;
      //   this.snackColor = "success";
      //   this.snackText = "NovoCadastro.snackTextSuccess";
      //   this.userFunctionId = r.data.function_Id;
      // })
      // .catch((e) => {
      //   console.log(e);
      //   this.snack = true;
      //   this.snackColor = "error";
      //   this.snackText = "NovoCadastro.snackTextError";
      // })
      // .finally(() => {
      //   this.loading = false
      // });
      var config = {
        method: 'post',
        url: `${process.env.VUE_APP_ENV_API_ROUTE}Campaign_Update`,
        headers: { 
          'Authorization': `bearer ${this.token}`,
          'Content-Type': 'application/json'
          },
        data : dataCampaign
      };
      await axios(config)
        .then((r) => {
          component.snack = true;
          component.snackColor = "success";
          component.snackText = "NovoCadastro.snackTextSuccess";
          component.userFunctionId = r.data.function_Id;
          this.emitReload();
          router.push({ name: 'listCampaign' });
        })
        .catch((e) => {
          console.log(e);
          component.snack = true;
          component.snackColor = "error";
          component.snackText = "NovoCadastro.snackTextError";
        });
      },
    async setInitialVars() {
      this.listCustomers = this.getListDataInformation;
      if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
      } else {
        this.client = this.$store.state.currentCustomer;
      }
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Campaign");
      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' })
      }
      
      this.havePermissions = false;
    },
    emitReload() {
      this.$store.dispatch('campaignList', localStorage.getItem("customer_Id"));
    },
  },
  computed:{
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListGroups']),
  },
  async created() {
    this.nivelAcesso = this.getListGroups;
    this.havePermissions = true;
    if(this.$store.state.permissions.length == 0) {
      setTimeout(async() => {
        await this.setInitialVars();
      },
      1000);
    } else {
      await this.setInitialVars();
    }
    
    if (this.id) {
      this.campaignDetails();
    }
    this.existantFlows();
  },
}
</script>