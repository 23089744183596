<template>
  <div class="vuec-NovoCadastro">
    <v-container fluid class="pa-0" v-if="functionality.functionality_Level >= 2">
      <v-row>
        <v-col cols="12" md="12">
          <v-card >
            <v-card-title>{{ $t('PaymentsClient.paymentsConfiguration') }}</v-card-title>
              <v-card-text>
                <v-select v-if="functionality.functionality_Level >= 2"
                  return-object
                  v-model="client"
                  :items="getListDataInformation"
                  item-text="customer_Name"
                  item-value="customer_Id"
                  :label="$t('PaymentsClient.clientName')"
                  required
                ></v-select>
                <v-select v-if="client != null && functionality.functionality_Level > 1"
                  @change="setStores"
                  return-object
                  v-model="storeClient"
                  :items="client.store"
                  item-text="store_Name"
                  item-value="store_Id"
                  :label="$t('PaymentsClient.storeName')"
                  required
                ></v-select>
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card style="height: 100%">
            <v-card-title>{{ $t('NovoCadastro.information') }}</v-card-title>
            <v-card-text>
              {{ $t('NovoCadastro.login') }}
              <v-text-field
                v-model="login"
                solo
              ></v-text-field>
              {{ $t('NovoCadastro.name') }}
              <v-text-field v-model="nomeCompleto" solo></v-text-field>
              {{ $t('NovoCadastro.password') }}
              <v-text-field
                :type="show ? 'text' : 'password'"
                v-model="senha"
                solo
              ></v-text-field>
              {{ $t('NovoCadastro.mail') }}
              <v-text-field v-model="email" solo></v-text-field>
              {{ $t('NovoCadastro.cellPhone') }}
              <v-text-field v-model="cellphone" solo></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card style="height: 100%">
            <v-card-title class="pb-0">{{ $t('NovoCadastro.state') }}</v-card-title>
            <v-row>
              <v-col>
                <v-card-text class="py-0">
                  <v-col cols="12">
                    <v-radio-group dense v-model="situacaoRadio">
                      <v-radio :label="$t('NovoCadastro.active')" :value="true"></v-radio>
                    </v-radio-group>
                    <v-menu
                      ref="menuAtivo"
                      v-model="menuAtivo"
                      :close-on-content-click="false"
                      :return-value.sync="dateAtivo"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateAtivo"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dateAtivo" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuAtivo = false">
                          {{ $t('Guid.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuAtivo.save(dateAtivo)"
                        >
                        {{ $t('Guid.ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text class="py-0">
                  <v-col cols="12">
                    <v-radio-group dense v-model="situacaoRadio">
                      <v-radio :label="$t('NovoCadastro.unactive')" :value="false"></v-radio>
                    </v-radio-group>
                    <v-menu
                      ref="menuInativo"
                      v-model="menuInativo"
                      :close-on-content-click="false"
                      :return-value.sync="dateInativo"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateInativo"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dateInativo" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuInativo = false">
                          {{ $t('Guid.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuInativo.save(dateInativo)"
                        >
                        {{ $t('Guid.ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-title>{{ $t('NovoCadastro.schedule') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-radio-group multiple dense v-model="diasSemanas" row>
                    <v-radio :label="$t('NovoCadastro.labelmonday')" :value="$t('NovoCadastro.monday')"></v-radio>
                    <v-radio :label="$t('NovoCadastro.labeltuesday')" :value="$t('NovoCadastro.tuesday')"></v-radio>
                    <v-radio :label="$t('NovoCadastro.labelwednesday')" :value="$t('NovoCadastro.wednesday')"></v-radio>
                    <v-radio :label="$t('NovoCadastro.labelthursday')" :value="$t('NovoCadastro.thursday')"></v-radio>
                    <v-radio :label="$t('NovoCadastro.labelfriday')" :value="$t('NovoCadastro.friday')"></v-radio>
                    <v-radio :label="$t('NovoCadastro.labelsaturday')" :value="$t('NovoCadastro.saturday')"></v-radio>
                    <v-radio :label="$t('NovoCadastro.labelsunday')" :value="$t('NovoCadastro.sunday')"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-menu
                    ref="menuTimeInicio"
                    v-model="menuTimeInicio"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="timeInicio"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeInicio"
                        :label="$t('NovoCadastro.startTime')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuTimeInicio"
                      v-model="timeInicio"
                      format="24hr"
                      full-width
                      @click:minute="$refs.menuTimeInicio.save(timeInicio)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    ref="menuTimeTermino"
                    v-model="menuTimeTermino"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="timeTermino"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeTermino"
                        :label="$t('NovoCadastro.stopTime')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuTimeTermino"
                      v-model="timeTermino"
                      format="24hr"
                      full-width
                      @click:minute="$refs.menuTimeTermino.save(timeTermino)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>{{ $t('NovoCadastro.role') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select
                  return-object
                  v-model="roleUser"
                  :items="userListFunctionality"
                  item-text="function_Name"
                  item-value="function_Id"
                  required
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title v-if="$store.state.permissions.function_Id == 1">{{ $t('NovoCadastro.roleManagement') }}</v-card-title>
            <v-card-text v-if="$store.state.permissions.function_Id == 1">
              <v-row>
                <v-col v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  {{ $t('NovoCadastro.loading') }}
                </v-col>
                <v-col v-else>
                  <v-sheet v-if="currentListRoleFunct.length > 0">
                    <v-sheet v-for="item in currentListRoleFunct" :key="item.Functionality_Code" class="ma-1 pa-1"> 
                      {{ $t('NovoCadastro.function') }} : {{ $t('Funcitons.'+item.functionality_Name) }} - {{ $t('NovoCadastro.accessLevel') }} : {{ item.functionality_Level }} 
                    </v-sheet>
                  </v-sheet>
                  <v-sheet v-else>
                    {{ $t('NovoCadastro.roleError') }}
                  </v-sheet>
                </v-col>
              </v-row> 
              <v-row>
                <v-col>
                  <v-icon @click="roleManagement">mdi-plus</v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title v-if="$store.state.permissions.function_Id == 1">{{ $t('NovoCadastro.reportsManagement') }}</v-card-title>
            <v-card-text v-if="$store.state.permissions.function_Id == 1">
              <v-row>
                <v-col>
                  <v-autocomplete 
                      disabled
                      return-object
                      v-model="currentReports"
                      :items="reports"
                      item-text="report_Name"
                      item-value="report_Id"
                      solo
                      filled
                      multiple
                      chips
                      deletable-chips
                      :label="$t('NovoCadastro.labelSelect')">
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-icon @click="reportsManagements">mdi-plus</v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card style="height: 100%">
            <v-card-title>{{ $t('NovoCadastro.group') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    return-object
                    v-model="gruposAtual"
                    :items="grupos"
                    item-text="attendence_Group_Name"
                    item-value="attendence_Group_Id"
                    solo
                    filled
                    multiple
                    chips
                    deletable-chips
                    :label="$t('NovoCadastro.labelSelect')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card style="height: 100%">
            <v-card-title>{{ $t('NovoCadastro.channels') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    return-object
                    v-model="currentChannels"
                    :items="channels"
                    item-text="channel_Name"
                    item-value="channel_Id"
                    solo
                    filled
                    multiple
                    chips
                    deletable-chips
                    :label="$t('NovoCadastro.labelSelect')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> 
      <v-row> 
        <v-card-actions style="float: right">
          <v-btn
            :block="$vuetify.breakpoint.width < '960' ? true : false"
            color="primary"
            @click="save"
          >
            {{ $t('Guid.save') }}
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.width < '960' ? true : false"
            color="red"
            @click="closeOperator"
          >
            {{ $t('Guid.close') }}
          </v-btn>
        </v-card-actions>
      </v-row>

      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ $t(snackText) }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
        </template>
      </v-snackbar>
    </v-container>

    <div v-else>
      <v-dialog v-model="havePermissions">
        <v-card>
          <v-progress-linear
            indeterminate
            color="blue darken-2"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar v-model="snack" timeout="3000">
      {{ $t(snackText) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snack = false">
          {{ $t('Guid.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-if="rolesManagement"
        v-model="rolesManagement"
        width="auto">
      <template v-slot:activator="{ props }">
          <v-btn v-show="false"
            color="primary"
            v-bind="props"
          />
        </template>
        <v-card height="30%" width="700px" style="overflow: hidden;" class=" align-center pa-3">
          <v-card-title>{{ $t('NovoCadastro.roleManagement') }}</v-card-title>
          <v-card-text>
            {{ $t('NovoCadastro.roleName') }}
            <v-text-field v-model="functionName" solo></v-text-field>
            <v-row v-for="(f, i) in listFunction" :key="i">
              <v-col v-if="listFunction.length > 0">
                {{f.functionality_Name}}
                <v-text-field
                  type="number"
                  :min="0"
                  :max="3"
                  v-model="f.functionality_Level"
                  label="nivel de acceso"
              ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            variant="text"
            @click="closeDialogRoles(true)"
          >
            {{ $t("Guid.save") }}
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="closeDialogRoles(false)"
          >
            {{ $t("Guid.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="reportsManagement"
        v-model="reportsManagement"
        width="auto">
      <template v-slot:activator="{ props }">
          <v-btn v-show="false"
            color="primary"
            v-bind="props"
          />
        </template>
        <v-card height="30%" width="700px" style="overflow: hidden;" class=" align-center pa-3">
          <v-card-title>{{ $t('NovoCadastro.reportsManagement') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="reportsManagement">
                <v-autocomplete
                    return-object
                    v-model="currentReports"
                    :items="reports"
                    item-text="report_Name"
                    item-value="report_Id"
                    solo
                    filled
                    multiple
                    chips
                    deletable-chips
                    :label="$t('NovoCadastro.labelSelect')">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="closeDialogReports()"
          >
            {{ $t("Guid.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';

export default {
  props: ["id"],
  data: () => ({
    show: false,
    login: "",
    nomeCompleto: "",
    email: "",
    cellphone: "",
    senha: "",

    dateAtivo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuAtivo: false,

    dateInativo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuInativo: false,

    situacaoRadio: false,

    grupos: [],
    gruposAtual: [],
    gruposAtualCp: [],

    diasSemanas: [],

    timeInicio: null,
    menuTimeInicio: false,

    timeTermino: null,
    menuTimeTermino: false,

    token: JSON.parse(localStorage.getItem("authUser")).token,

    snack: false,
    snackColor: "",
    snackText: "",

    client: "",
    storeClient: "",
    flux: "",
    report: "",
    campaign: "",
    attendence: "",
    user: "",
    payment: "",

    functionality: [],
    listCustomers : [],
    havePermissions: false,
    accessLevel: [0,1,2,3,4], 

    userSysAdm: false,

    roleUser: "",

    userListFunctionality: [],
    userFunctionId: "",
    newUserId: -1,

    channels: [],
    currentChannels: [],
    rolesManagement: false,
    reports: [],
    currentReports: [],
    reportsManagement: false,
    listFunction: [],

    functionName: "",
    user_exist_functionId: 0,
    currentListRoleFunct: [],

    loading: true
  }),
  methods: {
    closeOperator(){
      this.$router.push({ name: 'listaOperadores' });
    },
    ProcessObtainedPassword(password) {
      let lenght = password.length
      let newPassword = ""
      if (password.charAt(lenght-1) === "2") {
        newPassword = password.slice(0, lenght-1) + "=="
      } else if(password.charAt(lenght-1) === "1") {
        newPassword = password.slice(0, lenght-1) + "="
      } else if(password.charAt(lenght-1) === "0"){
        newPassword = password.slice(0, lenght-1)
      }
      return decodeURIComponent(atob(newPassword));
    },
    async alteraDados() {
      let processedPassword = ""
      let newPassword = window.btoa(unescape(encodeURIComponent( this.senha )))
      let regex2 = /==/g;
      let regex1 = /=/g;
      let proccessReports = [];
      
      let proccessFunctions = [];

      this.listFunction.forEach(funct => {
        if (funct.functionality_Level != "0") {
          proccessFunctions.push({"functionality_Level": funct.functionality_Level, "functionality_Name": funct.functionality_Name, "functionality_abrev": funct.functionality_abrev, "functionality_code": funct.functionality_id})
        }
      })

      if (this.currentReports.length > 0) {
        this.currentReports.forEach(report => {
          proccessReports.push(
            {
              "report_Code": report.report_Id,
              "report_Name": report.report_Name,
              "report_Data": report.report_Data,
              "report_Parameter": report.report_Parameter
            })
        })
      }
      
      if (newPassword.includes("==")) {
        processedPassword = newPassword.replace(regex2,"2")
      } else if(newPassword.includes("=")) {
        processedPassword = newPassword.replace(regex1,"1")
      } else {
        processedPassword = newPassword + 0;
      }
      let dataOperator = {}
      if (proccessReports.length > 0) {
        dataOperator = {
          user_Id: this.id == null ? 0: this.id,
          user_Name: this.nomeCompleto,
          user_Login: this.login,
          user_Pass: processedPassword,
          user_Email: this.email,
          user_Celphone: this.cellphone,
          att_Start: this.timeInicio,
          att_End: this.timeTermino,
          start_Access: this.dateAtivo,
          end_Access: this.dateInativo,
          user_SysAdm: this.userSysAdm,
          expiration_Date: this.dateInativo,
          function_Id: this.roleUser.function_Id,
          user_Function: proccessFunctions,
          customer: [{ 
            customer_Id: this.client.customer_Id,
            customer_Name: this.client.customer_Name,
            customer_Logo_URL: this.client.customer_Logo_URL,
            store: this.client.store,
            group_List: this.gruposAtual,
          }],
          channel: this.currentChannels,
          report: proccessReports,
        }
      } else {
        dataOperator = {
          user_Id: this.id == null ? 0: this.id,
          user_Name: this.nomeCompleto,
          user_Login: this.login,
          user_Pass: processedPassword,
          user_Email: this.email,
          user_Celphone: this.cellphone,
          att_Start: this.timeInicio,
          att_End: this.timeTermino,
          start_Access: this.dateAtivo,
          end_Access: this.dateInativo,
          user_SysAdm: this.userSysAdm,
          expiration_Date: this.dateInativo,
          function_Id: this.roleUser.function_Id,
          user_Function: proccessFunctions,
          customer: [{ 
            customer_Id: this.client.customer_Id,
            customer_Name: this.client.customer_Name,
            customer_Logo_URL: this.client.customer_Logo_URL,
            store: this.client.store,
            group_List: this.gruposAtual,
          }],
          channel: this.currentChannels
        }
      }

      var config = {
        headers: { 
          'Authorization': `bearer ${this.token}`,
          'Content-Type': 'application/json'
          },
        data : dataOperator
      };

      await this.$http
        .put(`User_Data_Perm`, dataOperator, config)
        .then((r) => {
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "NovoCadastro.snackTextSuccess";
          this.newUserId = r.data.user_Id;
          this.userFunctionId = r.data.function_Id;
          router.push({ name: 'listaOperadores' });
        })
        .catch(() =>{
          console.log("Error")
        })
    },
    ativarGrupo(idUser, idGrupo) {
      this.$http
        .post(
          `User_Att_Group_Activate?User_Id=${idUser.user_Id}&Attendence_Group_Id=${idGrupo.attendence_Group_Id}&Customer_Id=${this.client.customer_Id}`,
          {},
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then(() => {
          //console.log("ativar",r.data);
        })
        .catch((e) => {
          console.log(e)
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async desativarGrupo(idUser, idGrupo) {
      await this.$http
        .post(
          `User_Att_Group_Deactivate?Customer_Id=${this.client.customer_Id}&User_Id=${idUser.user_Id}&Attendence_Group_Id=${idGrupo.attendence_Group_Id}`,
          {},
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then(() => {
          //console.log("desativar",r.data)
        })
        .catch((e) => {
          console.log(e)
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async listFunctionality() {
      await this.$http
        .get(
          `Functionality_List`,
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then((e) => {
          this.userListFunctionality = e.data
          if (this.user_exist_functionId == 0) {
            this.roleUser = this.userListFunctionality.find((r)=>{
              return r.function_Id == this.$store.state.permissions.function_Id;
            })
          } else {
            this.roleUser = this.userListFunctionality.find((r)=>{
              return r.function_Id == this.user_exist_functionId;
            })
          }
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async save() {
      await this.alteraDados();
      if (this.newUserId!=-1) {
        if (this.gruposAtual != this.gruposAtualCp){

          // Desactivate groups
          this.gruposAtualCp.forEach(gp => {
            if (this.gruposAtual.find(g => g == gp) == null) {
              this.desativarGrupo(this.newUserId, gp);
            }
          })

          // Activate groups
          this.gruposAtual.forEach(ac => {
            if(this.gruposAtualCp.find(g => g== ac) == null) {
              this.ativarGrupo(this.newUserId, ac);
            }
          })
        }
      }
    },
    setInitialVars() {
      this.listCustomers = this.getListDataInformation;
      if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
      } else {
        this.client = this.$store.state.currentCustomer;
      }
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "User");
      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' })
      }
      
      this.havePermissions = false;
      
      this.listFunctionality();
      this.listChannel();
      this.listReports();
    },
    setStores(){
      let listStores = [];
      listStores.push(this.storeClient);
      this.client.store = listStores;
    },
    async changeUserPermissions(){
      this.flux = this.roleUser.user_Functions[0].function_Level;
      this.report = this.roleUser.user_Functions[1].function_Level;
      this.campaign = this.roleUser.user_Functions[2].function_Level;
      this.attendence = this.roleUser.user_Functions[3].function_Level;
      this.user = this.roleUser.user_Functions[4].function_Level;
      this.payment = this.roleUser.user_Functions[5].function_Level;
    },
    listChannel() {
      this.$http
        .get(`List_Channel?Customer_Id=${this.client.customer_Id}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          let ch = r.data
          ch.forEach((ch) => {
            this.channels.push({"channel_Code": ch.channel_Id, "channel_Name": ch.channel_Name})
          })
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    roleManagement() {
      this.rolesManagement = true
    },
    reportsManagements() {
      this.reportsManagement = true
    },
    closeDialogRoles(save) {
      if (save) {
        this.saveNewRole()
      }
      this.rolesManagement = false
    },
    closeDialogReports() {
      this.reportsManagement = false
    },
    async listReports() {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await this.$http
        .get(`List_Reports_User?Customer_Id=${this.client.customer_Id}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          this.reports = r.data.reverse();
        })
        .catch(() => {
          this.logout();
        })
        .finally(() => {
          //this.loading = false
        });
    },
    getFunctionList() {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      let array = []
      this.$http
        .get('User_Func_List',{
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          array = r.data;
          array.forEach((item) => {
            this.listFunction.push({
              "functionality_abrev": item.functionality_abrev,
              "functionality_id": item.functionality_id,
              "functionality_Name": item.functionality_name,
              "functionality_Level": 0
            })
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
    detalhesOperador(idUser) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      let reports = []
      this.$http
        .get(
          `User_Data_Perm?user_id=${idUser}`,
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${token}`,
            }
          }
        )
        .then(async (r) => {
          this.user_exist_functionId = r.data.function_Id;
          this.listFunction = r.data.user_Function
          this.roleUser = this.userListFunctionality.find((e)=>{
            return e.function_Id == r.data.function_Id;
          })

          await this.function_fuctionality_role(r.data.function_Id)

          this.login = r.data.user_Login;
          this.senha = this.ProcessObtainedPassword(r.data.user_Pass);
          this.senhaCopy = this.senha;
          this.nomeCompleto = r.data.user_Name;
          this.email = r.data.user_Email;
          this.cellphone = r.data.user_Celphone;
          this.situacaoRadio =
            r.data.user_End == "0001-01-01T00:00:00" ? true : false;
          this.dateAtivo = r.data.start_Access.split("T")[0];
          this.dateInativo = r.data.end_Access.split("T")[0];
          this.diasSemanas = [
            "Segunda-Feira",
            "Terça-Feira",
            "Quarta-Feira",
            "Quinta-Feira",
            "Sexta-Feira",
          ];
          reports =  r.data.report

          reports.forEach(report => {
            this.currentReports.push({
              "report_Id": report.report_Code,
              "report_Name": report.report_Name,
              "report_Data": report.report_Data,
              "report_Parameter": report.report_Parameter
            })
          })
          

          this.timeInicio = r.data.att_Start ;
          this.timeTermino = r.data.att_End;
          this.currentChannels = r.data.channel;
          this.gruposAtual = r.data.customer[0].group_List;
          this.gruposAtual = this.gruposAtual.filter(function (elem) {
            /* função que remove operadores multiplicados */ return (
              !this[JSON.stringify(elem.attendence_Group_Id)] &&
              (this[JSON.stringify(elem.attendence_Group_Id)] = true)
            );
          }, Object.create(null));
          this.gruposAtualCp = this.gruposAtual;

        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {

        });
    },
    async saveNewRole() {
      let proccessFunctions = [];

      this.listFunction.forEach(funct => {
        if (funct.functionality_Level != "0") {
          proccessFunctions.push({"functionality_Level": funct.functionality_Level, "functionality_Name": funct.functionality_Name, "functionality_abrev": funct.functionality_abrev, "functionality_code": funct.functionality_id})
        }
      })

      let functionality = {
        function_Name: this.functionName,
        function_Father: this.$store.state.permissions.function_Id,
        user_Functionality: proccessFunctions
      }

      var config = {
        headers: { 
          'Authorization': `bearer ${this.token}`,
          'Content-Type': 'application/json'
          },
        data : functionality
      };

      await this.$http
        .post(`Functionality_List`, functionality, config)
        .then(() => {
          this.listFunctionality();
        })
        .catch(() =>{
          console.log("Error")
        })
    },
    async function_fuctionality_role(functionId) {
      this.loading = true;
      await this.$http
        .get(`User_Func_List/GetById?roleId=${functionId}`, 
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          })
        .then((r) => {
          this.currentListRoleFunct = r.data
          this.loading = false;
        })
        .catch(() =>{
          console.log("Error")
        })
    }
  },
  computed:{
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListGroups']),
    ...mapGetters(['getCurrentCustomer']),
  },
  watch: {
    getCurrentCustomer() {
      this.client = this.getCurrentCustomer;
    },
    client() {
      this.listChannel();
    },
    getListDataInformation() {
      this.listCustomers = this.getListDataInformation
    },
    async roleUser(newRole, oldRole) {
      if (oldRole != null) {
        await this.function_fuctionality_role(newRole.function_Id)
      }
    }
  },
  mounted() {
    this.havePermissions = true;
    this.getFunctionList()
    if(this.$store.state.permissions.length == 0) {
      setTimeout(() => {
        this.setInitialVars();
      },
      1000);
    } else {
      this.setInitialVars();
    }

    this.grupos = this.getListGroups
    if (this.id) {
      this.detalhesOperador(this.id)
    }
  },
};
</script>

<style>
</style>