<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>{{ $t('NewLoadFiles.file') }}</v-card-title>
                    <v-card-text>
                        {{ $t('NewLoadFiles.fileName') }}
                        <v-text-field v-model="fileName" solo></v-text-field>
                    </v-card-text>
                    <v-card-text>
                        {{ $t('NewLoadFiles.pathFile') }}
                        <v-text-field :disabled="true" v-model="filePathName" solo>
                        </v-text-field>
                    </v-card-text>
                    <v-card-text>
                        <v-btn
                            @click="onButtonClick"
                            style="position: absolute; right: 0; top: 270px;"
                            class="ma-8"
                            color="primary"
                            >{{ $t('Guid.import') }}
                            <v-icon
                            right
                            dark
                            >
                                mdi-cloud-upload
                            </v-icon>
                        </v-btn>
                        <input 
                            @change="onFileChanged" 
                            ref="fileUpload" 
                            class="d-none" 
                            type="file" />
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-select
                                v-model="groupList"
                                :items="getListGroups"
                                item-text="attendence_Group_Name"
                                item-value="attendence_Group_Id"
                                required
                                multiple
                                :label="$t('NewLoadFiles.attendenceGroup')"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-radio-group v-model="enable" row>
                            <v-radio :label="$t('NewLoadFiles.active')" :value="true"></v-radio>
                            <v-radio :label="$t('NewLoadFiles.unactive')" :value="false"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row> 
            <v-card-actions style="float: right">
                <v-btn
                    :block="$vuetify.breakpoint.width < '960' ? true : false"
                    color="primary"
                    @click="save"
                >
                    {{ $t('Guid.save') }}
                </v-btn>
                <v-btn
                    :block="$vuetify.breakpoint.width < '960' ? true : false"
                    color="red"
                    @click="closeNewFile"
                >
                    {{ $t('Guid.close') }}
                </v-btn>
            </v-card-actions>
      </v-row>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ $t(snackText) }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
        </template>
      </v-snackbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ["id"],
    data: () => ({
        token: JSON.parse(localStorage.getItem("authUser")).token,
        currentFile: "",
        fileName: "",
        filePathName: "",
        reference: 0,
        groupList: [],
        idGroup: 0,
        enable: false,
        selectedFile: null,

        snack: false,
        snackColor: "",
        snackText: "",

    }),
    methods: {
        onButtonClick() {
            window.addEventListener('focus', () => {}, {
                once: true
            })
            this.$refs.fileUpload.click()
        },
        onFileChanged(e) {
            this.isImporting = true;
            this.filePathName = e.target.files[0].name;
            this.selectedFile = e.target.files[0]
            let stringInFile = new FileReader();

            stringInFile.addEventListener('load', async () => {

                try {
                    const text = stringInFile.result;
                    console.log(text)
                } catch (error) {
                    console.log(error);
                }
            });

            stringInFile.readAsText(this.selectedFile);
        },
        closeNewFile() {
            this.$router.push({ name: 'loadFiles' });
        },
        async save() {
            let attFile = {
                file_Id : this.id != null ? this.id : 0,
                file_Name : this.fileName,
                file_Reference : this.reference,
                enable : this.enable,
                attendence_Group_Id : []

            }
            await this.$http
             .get(`Att_File_Update?Customer_Id=${localStorage.getItem("customer_Id")}&Att_File=${attFile}`, {
                headers: {
                    accept: "*/*",
                    Authorization: `bearer ${this.token}`,
                },
             })
             .then(() => {
                // console.log("Se guardan los datos");
            })
            .catch(() => {
                // this.error = true
            })
            .finally(() => {
                // this.loading = false
            });
        }
    },
    computed: {
        ...mapGetters(['getListDataInformation']),
        ...mapGetters(['getListUserFunctions']),
        ...mapGetters(['getListGroups']),
        ...mapGetters(['getCurrentFiles']),
    },
    mounted() {
        this.getListGroups;

        if (this.id != null) {
            this.currentFile = this.getCurrentFiles.find(f => f.file_Id == this.id)
            this.fileName = this.currentFile.file_Name;
            this.reference = this.currentFile.File_Reference;
            this.groupList = this.currentFile.att_Group;
            this.enable = this.currentFile.enable;
        }
    }
}
</script>