<template>
  <v-row>
    <v-col v-if="$vuetify.breakpoint.width > '960'" cols="12">
      <span class="text-h5">{{ $t('FilaAtendimientoChat.attendanceQueue') }}</span>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('FilaAtendimientoChat.listCustomersConfiguration') }}</v-card-title>
        <v-card-text>
          <v-select
            @change="changeCustomer"
            return-object
            v-model="client"
            :items="listCustomers"
            item-text="customer_Name"
            item-value="customer_Id"
            :label="$t('FilaAtendimientoChat.clientName')"
            required
          ></v-select>
          <v-checkbox
            v-model="autoResponse"
            @click="autoResponseMethod"
            :label="$t('FilaAtendimientoChat.autoResponse')"
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-text>
        <v-list>
          <v-list-group :value="false" prepend-icon="mdi-account-group">
            <template v-slot:activator>
              <v-list-item-title>
                <v-badge color="primary" :content="qtMsgSolicitado">
                  <span>{{ $t('FilaAtendimientoChat.waitAttendance') }}</span>
                </v-badge>
              </v-list-item-title>
            </template>
            <template v-if="qtMsgSolicitado > 0">
              <v-list-item
                @click="atenderConversa(item)"
                v-for="(item, i) in listaAtendimentosFila"
                :key="i"
                link
              >
                <v-icon> mdi-chat </v-icon>
                <v-list-item-title class="d-flex justify-center">
                  {{ item.client_Name }}</v-list-item-title>
                <v-list-item class="d-flex flex-row-reverse">
                  {{ getLocalDate(item.start_Queue) }}
                </v-list-item>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item> {{ $t('FilaAtendimientoChat.empty') }} </v-list-item>
            </template>
          </v-list-group>
        </v-list>
        <v-list>
          <v-list-group :value="false" prepend-icon="mdi-account-sync">
            <template v-slot:activator>
              <v-list-item-title>
                <v-badge color="primary" :content="qtMinhasMensagens">
                  <span>{{ $t('FilaAtendimientoChat.myServices') }}</span>
                </v-badge>
              </v-list-item-title>
            </template>
            <template v-if="qtMinhasMensagens > 0">
              <div
                style="position: relative"
                v-for="(item, i) in listaAtendimentos"
                :key="i"
              >
                <v-row>
                  <v-col
                    @click="atenderConversa(item)"
                    cols="11"
                    align-self="center"
                    class="mr-n5"
                  >
                    <v-list-item link v-if="telephoneArray.includes(item.client_Name)">
                      <v-icon color="primary"> mdi-chat </v-icon>
                      <v-row>
                        <v-col
                          style="padding: 0"
                          class="d-flex justify-center"
                          cols="12"
                          lg="6"
                        >
                          <v-list-item-title
                            class="d-flex justify-center align-center"
                          >
                            {{ item.client_Name }}
                          </v-list-item-title>
                        </v-col>
                        <v-col style="padding: 0" cols="12" lg="6">
                          <v-list-item
                            style="
                              margin-top: 0;
                              min-height: 0px;
                              text-align: center
                            "
                            class="d-flex justify-center align-center"
                          >
                            {{ getLocalDate(item.start_Queue) }}
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item link v-else>
                      <v-icon> mdi-chat </v-icon>
                      <v-row>
                        <v-col
                          style="padding: 0"
                          class="d-flex justify-center"
                          cols="12"
                          lg="6"
                        >
                          <v-list-item-title
                            class="d-flex justify-center align-center"
                          >
                            {{ item.client_Name }}
                          </v-list-item-title>
                        </v-col>
                        <v-col style="padding: 0" cols="12" lg="6">
                          <v-list-item
                            style="
                              margin-top: 0;
                              min-height: 0px;
                              text-align: center
                            "
                            class="d-flex justify-center align-center"
                          >
                            {{ getLocalDate(item.start_Queue) }}
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-col>
                  <v-col class="px-0" cols="1" align-self="center">
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text small fab>
                          <v-icon color="grey"> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <v-list class="d-flex justify-center">
                        <v-list-group style="width: 100%">
                          <template v-slot:activator>
                            <v-icon class="pr-3"> mdi-account-sync </v-icon>
                            <v-list-item-title>{{ $t('FilaAtendimientoChat.transfer') }}</v-list-item-title>
                          </template>
                          <div>
                            <v-list-item
                              style="z-index: 999; background-color: white"
                              v-for="(grupo, i) in grupos"
                              :key="i"
                              link
                              @click="
                                proccessTransfer(
                                  grupo.attendence_Group_Id,
                                  item.attendence_Id
                                )
                              "
                            >
                              <v-list-item-title
                                v-text="grupo.attendence_Group_Name"
                              ></v-list-item-title>
                            </v-list-item>
                          </div>
                        </v-list-group>
                      </v-list>
                      <v-list>
                        <v-list-item>
                          <EncerrarAtendimento
                            @encerrandoAtendimento="
                            encerrandoAtendimento = $event"
                            :attendenceId="item.attendence_Id"
                            :clientName="item.client_Name"
                            @errorCloseService="errorCloseService"
                            @errorCampaign="errorCampaign"
                          >
                            {{ $t('FilaAtendimientoChat.close') }}
                          </EncerrarAtendimento>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </template>
            <template v-else>
              <v-list-item> {{ $t('FilaAtendimientoChat.empty') }} </v-list-item>
            </template>
          </v-list-group>
        </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ $t(snackText) }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> {{$t('Guid.close')}} </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-if="dialog"
        v-model="dialog"
        width="auto">
      <template v-slot:activator="{ props }">
        <v-btn v-show="false"
          color="primary"
          v-bind="props"
        />
      </template>
      <v-card>
        <v-card-title>{{ $t('FilaAtendimientoChat.dialog') }}</v-card-title>
        <v-card-text>{{ $t("FilaAtendimientoChat.transferWarning") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="text"
            @click="dialog = false"
          >
            {{ $t("Guid.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="transferirAtendimento"
          >
            {{ $t("Guid.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogAutoAsign"
        v-model="dialogAutoAsign"
        width="auto">
      <template v-slot:activator="{ props }">
        <v-btn v-show="false"
          color="primary"
          v-bind="props"
        />
      </template>
      <v-card>
        <v-card-title>{{ $t('FilaAtendimientoChat.dialogAutoAsign') }}</v-card-title>
        <v-card-text>{{ $t("FilaAtendimientoChat.autoAsignWarning") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="autoAsignTemplate"
          >
            {{ $t("Guid.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <audio ref="tingSound" src="@/assets/Ting-sound.mp3"></audio>
  </v-row>
</template>

<script>
import EncerrarAtendimento from "./EncerrarAtendimento.vue";
import { CONNECTIONSIGNALR } from "../plugins/signalR"
import { mapGetters } from 'vuex';

export default {
  components: {
    EncerrarAtendimento,
  },
  props: {
    enviarMensagem: {
      default: false,
    },
    historicButton: {
      default: false,
    },
    reloadServices:{
      default: true,
    },
    telephoneArray: {
      required: false,
    },
    errorMessage:{
      required:false,
    },
    messageChat: {
      required: false,
    },
    attentionActive:{
      required: false,
    },
    messageWelcome: {
      required: true,
    },
    updateName: {
      required: false,
    },
    client_Id: {
      required: false,
    },
  },
  data() {
    return {
      autoResponse: false,
      grupos: [],

      listaAtendimentos: [],
      listaAtendimentosFila: [],
      userAdm: JSON.parse(localStorage.getItem("authUser")).attendence_Adm,
      token: JSON.parse(localStorage.getItem("authUser")).token,
      user: JSON.parse(localStorage.getItem("authUser")).user_Name,
      startAtt: JSON.parse(localStorage.getItem("authUser")).start_Att,
      endAtt: JSON.parse(localStorage.getItem("authUser")).end_Att,

      conversaDados: [],
      listCustomers : [],

      encerrandoAtendimento: null,

      client:"",
      historic:false,
      historicArray:[],

      snack: false,
      snackColor: "",
      snackText: "",
      dialog: false,
      dialogAutoAsign: false,
      groupId: 0,
      clientId: 0,
      eventDate: null,
      newNotification: [],
      autoAssignFlag: false,
      signalAssign: false,
      messageActive: false,
    };
  },
  methods: {
    soundBell(){
      if (this.$store.state.sound){
        this.$refs.tingSound.play();
      }
    },
    changeCustomer() {
      localStorage.setItem("customer_Id", this.client.customer_Id);
      this.$store.commit('setCurrentCostumer', this.client);
    },
    setInitialVars() {
      this.listCustomers = this.getListDataInformation;
      if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
      } else {
        this.client = this.$store.state.currentCustomer;
      }
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence");
      this.havePermissions = false;
    },
    atenderConversa(data) {
      this.$emit("logOutUnactive")
      this.telephoneArray.forEach(element => {
        if(data.client_Name == element){
          this.$emit("correctNewMessage",element)
        }
      });
     
      if (this.conversaDados.length == 0 || this.conversaDados.attendence_Id != data.attendence_Id) {
        this.$emit("convMobile", "conv");
        this.conversaDados = data;
        this.$emit("conversaDados", this.conversaDados);
        this.historic=false
        this.historicArray = []
  
        this.meusAtendimentos();
        this.aguardaAtendimento();
      }
      //localStorage.setItem("convAtual", JSON.stringify(data)); // salva a conversa no localStorage
    },
    proccessTransfer(grupoId, clienteId) {
      this.groupId = grupoId;
      this.clientId = clienteId

      this.dialog = true;
    },
    transferirAtendimento() {
      const unactiveService = this.listaAtendimentos.filter(elem => elem.attendence_Id == this.clientId)[0]
      this.telephoneArray.forEach(element => {
        if(unactiveService.client_Name == element){
          this.$emit("correctNewMessage",element)
        }
      });
      if (this.clientId !== 0 && this.groupId !== 0) {
        this.$http
          .post(
            `User_Att_Transfer?Attendence_Id=${this.clientId}&Attendence_Group_Id=${this.groupId}&Customer_Id=${localStorage.getItem("customer_Id")}`,
            {},
            {
              headers: {
                Authorization: `bearer ${this.token}`,
              },
            }
          )
          .then(() => {
            this.dialog = false;
            this.historic=false;
            this.historicArray = [];
            this.clientId = 0;
            this.groupId = 0;
            this.$emit("conversaDados", []);
            this.conversaDados = [];
            this.meusAtendimentos();
            this.aguardaAtendimento();
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Transferido com Sucesso";
          })
          .catch((error) => {
            if(error.response.status == 401) {
              this.$store.commit('setDisconnectedByNetworkError', true)
              this.logout()
            }
          });
      }
    },
    meusAtendimentos() {
      this.$http
        .get(`User_Att_Active_List?Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          //console.log(r)
          this.listaAtendimentos = r.data;
          this.$emit("attentionActive", this.listaAtendimentos)
          if(this.historic){
            let atualizar = this.listaAtendimentos.find(
              (elem) => elem.attendence_Id == this.historicArray.attendence_Id
            );
            this.$emit("conversaDados", atualizar);
          }
        })
        .catch((error) => {
          if(error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    aguardaAtendimento() {
      this.$http
        .get(`User_Att_Waitting?Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          //console.log(r.data);
          this.listaAtendimentosFila = r.data;
          this.$emit("messageAuto", "")
          if(this.autoAssignFlag && this.autoResponse) {
            this.autoassign()
          }
        })
        .catch((e) => {
          if(e.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    gruposTransferirList() {
      this.$http
        .get(`User_Att_Group_List?Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          //console.log(r.data)
          this.grupos = r.data;
        })
        .catch((error) => {
          if(error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    getLocalDate(start_Queue) {
      let newDate = new Date(start_Queue + 'Z');
      let formatDate = 
        [
          newDate.getFullYear(),
          newDate.getMonth()+1,
          newDate.getDate()
        ].join('-') +' ' +
        [newDate.getHours(),
          String(newDate.getMinutes()).padStart(2, '0'),
          String(newDate.getSeconds()).padStart(2, '0')].join(':').toString();
      return formatDate;
    },
    async ConnetClientHub() {
      if (CONNECTIONSIGNALR.state == "Disconnected") {
        await CONNECTIONSIGNALR.start()
        .then(() => {
          CONNECTIONSIGNALR.invoke("User_Att_Activate", parseInt(localStorage.getItem("customer_Id"), 10), this.getUserInformation.user_Id);
          CONNECTIONSIGNALR.on("NewAtt", async (r, tel, messages) => {
            this.eventDate = Date.now();
            if(r) {
              this.autoAssignFlag = true;
              this.soundBell()
              this.listaAtendimentosFila = [],
              this.aguardaAtendimento();
              this.notificationAvailable();
              this.signalAssign = true;
            } else {
              await this.$emit("processNewMessage",r,tel,messages)
            }
          });
        })
        .catch(function(err) {
          console.log(err.toString());
          // setTimeout(this.ConnetClientHub, 5000);
        });
      }
    },
    autoassign() {
      if (this.listaAtendimentosFila.length > 0 && this.autoResponse && !this.messageActive && this.autoAssignFlag) {
        this.conversaDados = this.listaAtendimentosFila[0]
        this.$emit("logOutUnactive")
        this.telephoneArray.forEach(element => {
          if(this.conversaDados.client_Name == element){
            this.$emit("correctNewMessage",element)
          }
        });
      
        this.$emit("convMobile", "conv");
        this.$emit("conversaDados", this.conversaDados);
        
        let message = this.messageWelcome
        this.$emit("messageAuto", message.replace("{{Agent_Name}}", this.$store.state.permissions.user_Name))  

        this.autoAssignFlag = false
      }
    },
    autoAsignTemplate(){
      this.dialogAutoAsign = false
      this.autoResponse = false
    },
    autoResponseMethod(){
      if(this.messageWelcome == ""){
        this.dialogAutoAsign = true
      }
    },
    async logout() {
      await CONNECTIONSIGNALR.stop();
      localStorage.removeItem("authUser");
      localStorage.removeItem("convAtual");
      this.$store.commit('setInitialData')
      this.$router.push({ name: "home" });
    },
    notificationAvailable(){
      if(Notification.permission === "denied" || Notification.permission === "default"){
        Notification.requestPermission().then(function () {
          if(Notification.permission === "granted"){
            this.newNotification.push(null)
            this.showNotification()
          }
        });
      } else {
        this.newNotification.push(null)
        this.showNotification()
      }
    },
    showNotification(){ 
      if(this.newNotification.length === 1){
        var notification = new Notification(`${this.$t("FilaAtendimientoChat.newAttention")}`,{
          body: `${this.$t("FilaAtendimientoChat.attendenceWaiting")}`,
          icon: "http://24.199.64.76/DBG_Logo_Cuadrado.png"
        });
        
        setTimeout(() => {
          this.newNotification.splice(0)
          notification.close();
        }, 10 * 1000);

        notification.onclick = () => {
          notification.close();
          window.parent.focus();
          this.newNotification.splice(0)
        }
      }
    },
    errorCloseService() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "FilaAtendimientoChat.errorClosingServices";
    },
    errorCampaign() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "FilaAtendimientoChat.errorCampaign";
    }
  },
  computed: {
    qtMsgSolicitado() {
      if (this.listaAtendimentosFila.length > 0) {
        return this.listaAtendimentosFila.length;
      }
      return "0";
    },
    qtMinhasMensagens() {
      if (this.listaAtendimentos.length > 0) {
        return this.listaAtendimentos.length;
      }
      return "0";
    },
    ...mapGetters(['getUserInformation']),
    ...mapGetters(['getCurrentCustomer']),
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions'])
  },
  watch: {
    async getCurrentCustomer(){
      this.client = this.getCurrentCustomer;
      this.listaAtendimentos = [],
      this.listaAtendimentosFila = [],
      this.meusAtendimentos();
      this.aguardaAtendimento();
      await CONNECTIONSIGNALR.stop();
      await this.ConnetClientHub()
    },
    enviarMensagem() {
      let atualizar = this.listaAtendimentosFila.find(
        (elem) => elem.attendence_Id == this.conversaDados.attendence_Id
      );
      if (atualizar) {
        this.meusAtendimentos();
        this.aguardaAtendimento();
      }
    },
    historicButton(clientMain) {
      this.historic = true;
      this.historicArray = clientMain;
    },
    reloadServices(){
      if (this.reloadServices){
        this.meusAtendimentos();
        this.aguardaAtendimento();
      }
    },
    messageChat(data){
      this.messageActive = Boolean(data)
    },
    encerrandoAtendimento(elem) {
      if (this.conversaDados.attendence_Id == elem[1]) {
        this.$emit("conversaDados", []);
        this.historic=false
        this.historicArray = []
      }
      this.meusAtendimentos();
      this.aguardaAtendimento();
      
      if (this.listaAtendimentosFila.length > 0 && this.autoResponse){
        this.conversaDados = this.listaAtendimentosFila[0]
        this.$emit("conversaDados", this.conversaDados);
        
        let message = "Hola soy "+ this.$store.state.permissions.user_Name +", ¿En qué te puedo ayudar?"
        this.$emit("messageAuto", message)
      }
    },
    errorMessage() {
      if (this.errorMessage) {
        this.clientId = 0,
        this.grupoId = 0,
        this.listaAtendimentosFila = [],
        this.$emit("convMobile", "conv");
        this.historic=false;
        this.historicArray = [];
        this.$emit("conversaDados", {});
        this.autoAssignFlag = true;
        this.meusAtendimentos();
        this.aguardaAtendimento()
        this.$emit("errorMessage",false)
      }
    },
    getListDataInformation() {
      this.listCustomers = this.getListDataInformation
    },
    updateName(){
      this.listaatendimentos.forEach(item => {
        if (item.client_Id === this.client_Id) 
        {
          item.client_Name = this.updateName;
        }
      })
    },
  },
  async mounted() {
    this.eventDate = Date.now();
    if(this.$store.state.permissions.length == 0) {
      setTimeout(() => {
        this.setInitialVars();
      },
      1000);
    } else {
      this.setInitialVars();
    }
    await this.ConnetClientHub();

    if(document.cookie == "pause=true") {
      this.aguardaAtendimento();
    }
    this.meusAtendimentos();
    this.gruposTransferirList();

    setInterval(
      function () {
        if (Date.now() - this.eventDate >= 240000){ //Four minutes
          this.meusAtendimentos()
          this.aguardaAtendimento()
          this.$emit("listMessages", true)
        }
      }.bind(this),
      300000 //Five minutes
    );

    // setInterval(
    //   function () {
    //     if (this.listaAtendimentosFila.length > 0 && this.autoResponse) {
    //       this.$emit("messageAuto", "") 
    //     }
    //   }.bind(this),
    //   5000
    // );
  },
};
</script>

<style>
</style>