<template>
  <v-sheet width="250" min-height="250">
    <v-card-title
      style="word-break: break-word"
      class="d-flex justify-space-between"
    >
      {{ stepName }}
      <v-btn @click="showDialog" icon color="primary">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text> {{ message }} </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-for="(transition, i) in transitions" :key="i">
      <v-chip
        close
        @click:close="excluir(i)"
        :color="transition.next_Step_Id == -1 ? 'red' : 'primary'"
        label
        text-color="white"
      >
        <!-- <v-icon left> mdi-label </v-icon> -->
        <span class="text-truncate">{{ transition.transition_Value }}</span>
      </v-chip>
    </v-card-actions>
    <v-card-actions>
      <v-btn
        style="float: right"
        fab
        x-small
        color="primary"
        @click="dialogResposta = !dialogResposta"
        ><v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogResposta" max-width="600px">
      <v-card>
        <v-card-title>
          <span style="font-size: 25px">{{ $t('LayoutCreateFluxo.reply') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  filled
                  v-model="respota"
                  counter
                  :label="$t('LayoutCreateFluxo.message')"
                ></v-textarea>
                <v-text-field
                  :label="$t('LayoutCreateFluxo.variableValue')"
                  v-model="variableValue">
                </v-text-field>
                <v-switch
                  class="mt-n4"
                  v-model="finalSwitch"
                  :label="$t('LayoutCreateFluxo.finalReply')"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogResposta = false">
            {{ $t('Guid.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="cadastrarRespota">
            {{ $t('Guid.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" max-width="600px">
      <v-card>
        <v-card-title>
          <span style="font-size: 25px">{{ $t('LayoutCreateFluxo.activity') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('LayoutCreateFluxo.activityName')"
                  v-model="stepName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="stepType"
                  @change="onChangeType"
                  :items="tipoAtividades"
                  item-text="step_Type_Name"
                  item-value="step_Type_Id"
                  :label="$t('LayoutCreateFluxo.type')"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="showMessage">
                <v-textarea
                :label="$t('LayoutCreateFluxo.message')"
                  v-model="message"
                  counter
                  filled
                ></v-textarea>
              </v-col>
              <v-col cols="12" v-if="showMethodIntegration">
                <v-select
                  v-model="methodIntegration"
                  :items="methodIntegrationList"
                  item-text="name"
                  item-value="name"
                  :label="$t('LayoutCreateFluxo.methodIntegration')"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="showStepIntegration">
                <v-textarea
                  filled
                  :label="$t('LayoutCreateFluxo.stepIntegration')"
                  v-model="integration"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('LayoutCreateFluxo.messageStepIntegration') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepIntegrationParameter">
                <v-textarea
                  filled
                  :label="$t('LayoutCreateFluxo.stepIntegrationParameter')"
                  v-model="integrationParameter"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('LayoutCreateFluxo.messageStepIntegrationParameter') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select  v-if="showGroups"
                  v-model="group"
                  return-object
                  :items="groups"
                  item-text="attendence_Group_Name"
                  item-value="attendence_Group_Id"
                  :label="$t('LayoutCreateFluxo.groups')"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="showCampaigns">
                <v-select  
                  v-model="campaign"
                  return-object
                  :items="campaigns"
                  item-text="campaign_Name"
                  item-value="campaign_Id"
                  :label="$t('LayoutCreateFluxo.campaigns')"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="showStepIntegrationHeaders">
                <v-textarea
                  filled
                  :label="$t('LayoutCreateFluxo.stepIntegrationHeaders')"
                  v-model="integrationHeaders"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('LayoutCreateFluxo.messageStepIntegrationHeaders') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepIntegrationAuthorize">
                <v-textarea
                  filled
                  :label="$t('LayoutCreateFluxo.stepIntegrationAuthorize')"
                  v-model="integrationAuthorize"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t('LayoutCreateFluxo.messageStepIntegrationAuthorize') }}</span>
                  </v-tooltip>
                </v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepCalculation">
                <v-text-field
                :label="$t('LayoutCreateFluxo.stepCalculation')"
                  v-model="calculation"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="showStepImgURL">
                <v-textarea
                  filled
                  :label="$t('LayoutCreateFluxo.stepImgURL')"
                  v-model="imgURL"
                ></v-textarea>
              </v-col>
              <v-col cols="12" v-if="showStepOperationVariable">
                <v-text-field
                :label="$t('LayoutCreateFluxo.stepOperationVariable')"
                  v-model="operationVariable"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="showStepValidation">
                <v-text-field
                :label="$t('LayoutCreateFluxo.stepValidation')"
                  v-model="validation">
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="mr-1" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span> {{ $t('LayoutCreateFluxo.messageValidation') }}</span>
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col class="mt-n10">
                <div class="d-flex">
                  <v-card-title class="px-0"> {{ $t('LayoutCreateFluxo.replys') }} </v-card-title>
                  <v-card-actions>
                    <v-btn
                      style="float: right"
                      fab
                      x-small
                      color="primary"
                      @click="dialogResposta = !dialogResposta"
                      ><v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-card-actions>
                </div>
                <div v-for="(transition, i) in transitions" :key="i">
                  <v-textarea
                  :label="$t('LayoutCreateFluxo.reply') + ` ${i + 1}`"
                    v-model="transitions[i].transition_Value"
                    counter
                    filled
                  ></v-textarea>
                  <v-text-field
                    :label="$t('LayoutCreateFluxo.variableValue')"
                    v-model="transitions[i].variable_Value">
                  </v-text-field>
                  <v-switch
                    class="mt-n4"
                    v-model="transitions[i].next_Step_Id"
                    :value="-1"
                    :label="$t('LayoutCreateFluxo.finalReply')"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="rollbackFlow">
            {{ $t('Guid.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="atualizarFluxo">
            {{ $t('Guid.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
  },
  data: () => ({
    dialogEdit: false,
    dialogResposta: false,
    respota: "",
    variableValue:"",

    token: JSON.parse(localStorage.getItem("authUser")).token,

    stepId: "",
    stepName: "",
    stepType: "",
    message: "",
    methodIntegration: "",
    methodIntegrationList: [
      { name: "GET" },
      { name: "POST" },
    ],
    integration: "",
    integrationParameter: "",
    integrationHeaders: "",
    integrationAuthorize: "",
    calculation: "",
    imgURL: "",
    operationVariable: "",
    validation: "",
    transitions: [],
    finalSwitch: false,
    group: "",
    campaign: "",

    id: "",

    excluirResposta: false,

    tipoAtividades: [],

    client: null,
    functionality: [],
    groups: [],
    campaigns: [],

    // This is to avoid changing the v-model of the activity if the cancel button is pressed
    stepNameAct: "",
    stepTypeAct: "",
    messageAct: "",
    methodIntegrationAct: "",
    methodIntegrationListAct: [
      { name: "GET" },
      { name: "POST" },
    ],
    integrationAct: "",
    integrationParameterAct: "",
    integrationHeadersAct: "",
    integrationAuthorizeAct: "",
    calculationAct: "",
    imgURLAct: "",
    operationVariableAct: "",
    validationAct: "",
    groupAct: "",
    campaignAct: "",

    showMessage: false,
    showMethodIntegration: false,
    showStepIntegration: false,
    showStepIntegrationParameter: false,
    showGroups: false,
    showCampaigns: false,
    showStepIntegrationHeaders: false,
    showStepIntegrationAuthorize: false,
    showStepCalculation: false,
    showStepImgURL: false,
    showStepOperationVariable: false,
    showStepValidation: false,
  }),
  methods: {
    showDialog(){
      this.onChangeType();
      this.dialogEdit = !this.dialogEdit;
    },
    onChangeType() {
      switch (this.stepType) {
        case 1 || 14:
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = true;
          break;
        case 2 || 13: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = true;
          break;
        case 3:
          this.showMessage = false,
          this.showMethodIntegration = true,
          this.showStepIntegration = true,
          this.showStepIntegrationParameter = true,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = true,
          this.showStepIntegrationAuthorize = true,
          this.showStepCalculation = true,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 4: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = false;
          break;
        case 5:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 6: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = true,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 9:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 10: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = true,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = true,
          this.showStepValidation = false;
          break;
        case 11:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = true,
          this.showGroups = false,
          this.showCampaigns = true,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = true,
          this.showStepValidation = false;
          break;
        case 12: 
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
        case 15: 
          this.showMessage = true,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = true,
          this.showStepImgURL = true,
          this.showStepOperationVariable = true,
          this.showStepValidation = true;
          break;
        default:
          this.showMessage = false,
          this.showMethodIntegration = false,
          this.showStepIntegration = false,
          this.showStepIntegrationParameter = false,
          this.showGroups = false,
          this.showCampaigns = false,
          this.showStepIntegrationHeaders = false,
          this.showStepIntegrationAuthorize = false,
          this.showStepCalculation = false,
          this.showStepImgURL = false,
          this.showStepOperationVariable = false,
          this.showStepValidation = false;
          break;
      }

    },
    excluir(i) {
      this.$df.removeNodeOutput(this.id, `output_${i + 1}`);
      this.transitions.splice(i, 1);
      this.$df.updateConnectionNodes(`node-${this.id}`);
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.transitions =
        this.transitions;
    },
    async cadastrarRespota() {
      await this.$df.addNodeOutput(this.id);
      await this.transitions.push({
        transition_Value: this.respota,
        next_Flow_Id: 0,
        next_Step_Id: this.finalSwitch ? -1 : 0,
        variable_Value: this.variableValue,
      });
      this.finalSwitch = false
      this.dialogResposta = false;
      this.respota = "";
      this.$df.updateConnectionNodes(`node-${this.id}`);
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.transitions =
        this.transitions;
    },
    // This is to avoid changing the v-model of the activity if the cancel button is pressed
    changeCurrentValues() {
      this.stepNameAct = this.stepName;
      this.stepTypeAct = this.stepType;
      this.messageAct = this.message;
      this.methodIntegrationAct = this.methodIntegration;
      this.methodIntegrationListAct = this.methodIntegrationList;
      this.integrationAct = this.integration;
      this.integrationParameterAct = this.integrationParameter;
      this.integrationHeadersAct = this.integrationHeaders;
      this.integrationAuthorizeAct = this.integrationAuthorize;
      this.calculationAct = this.calculation;
      this.imgURLAct = this.imgURL;
      this.operationVariableAct = this.operationVariable;
      this.validationAct = this.validation;
      this.groupAct = this.group;
      this.campaignAct = this.campaign;
    },
    // This is to avoid changing the v-model of the activity if the cancel button is pressed
    rollbackFlow() {
      this.stepName = this.stepNameAct;
      this.stepType = this.stepTypeAct;
      this.message = this.messageAct;
      this.methodIntegration = this.methodIntegrationAct;
      this.methodIntegrationList = this.methodIntegrationListAct;
      this.integration = this.integrationAct;
      this.integrationParameter = this.integrationParameterAct;
      this.integrationHeaders = this.integrationHeadersAct;
      this.integrationAuthorize = this.integrationAuthorizeAct;
      this.calculation = this.calculationAct;
      this.imgURL = this.imgURLAct;
      this.operationVariable = this.operationVariableAct;
      this.validation = this.validationAct;
      this.group = this.groupAct;
      this.campaign = this.campaignAct;

      this.dialogEdit = false;
    },
    atualizarFluxo() {
      this.changeCurrentValues();
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.message =
        this.messageAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepName =
        this.stepNameAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepType =
        this.stepTypeAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.transitions =
        this.transitionsAct;
      // Added others fields
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepMethodIntegration =
        this.methodIntegrationAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepIntegration =
        this.integrationAct;
      if (this.stepTypeAct == 6) {
        this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepIntegrationParameter =
          this.group.attendence_Group_Id;
      } else {
        this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepIntegrationParameter =
          this.integrationParameterAct;
      }
      if (this.stepTypeAct == 11) {
        this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepIntegrationHeaders =
          this.campaign.campaign_Id;
      } else {
        this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepIntegrationHeaders =
          this.integrationHeadersAct;
      }
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepIntegrationAuthorize =
        this.integrationAuthorizeAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepCalculation =
        this.calculationAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepImgURL =
        this.imgURLAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepOperationVariable =
        this.operationVariableAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.stepValidation =
        this.validationAct;
      this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data.transitions =
        this.transitions;
      this.dialogEdit = false;
    },
    async setInitialVars() {

      if (this.$store.state.currentCustomer == null) {
        this.$router.push({ name: 'fluxo' });
      } else {
        this.client = this.$store.state.currentCustomer;
      }

      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Flow");
      if (this.functionality.functionality_Level < 2) {
        this.$router.push({ name: 'painelCliente' })
      }

      this.groups = this.getListGroups;
      this.campaigns = this.getListCampaigns;
      this.havePermissions = false;
    }
  },
  computed: {
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListGroups']),
    ...mapGetters(['getListCampaigns']),
    ...mapGetters(['getListStepType'])
  },
  mounted() {
    this.setInitialVars();
    this.$nextTick(() => {
      const id = this.$el.parentElement.parentElement.id;
      const data = this.$df.getNodeFromId(id.slice(5));
      this.stepId = data.data.stepId;
      this.stepName = data.data.stepName.trim();
      this.stepType = data.data.stepType;
      this.message = data.data.message.trim();
      this.methodIntegration = data.data.stepMethodIntegration;
      this.integration = data.data.stepIntegration.trim();
      this.integrationParameter = data.data.stepIntegrationParameter;
      this.integrationHeaders = data.data.stepIntegrationHeaders;
      this.integrationAuthorize = data.data.stepIntegrationAuthorize;
      this.calculation = data.data.stepCalculation.trim();
      this.imgURL = data.data.stepImgURL.trim();
      this.operationVariable = data.data.stepOperationVariable.trim();
      this.validation = data.data.stepValidation.trim();
      if (data.data.stepType == 6) {
        this.group = this.groups.find(g => g.attendence_Group_Id == this.integrationParameter)
      }
      if (data.data.stepType == 11) {
        this.campaign = this.campaigns.find(g => g.campaign_Id == this.integrationHeaders)
      }
      data.data.transitions
        ? (this.transitions = data.data.transitions)
        : false;
      this.id = data.id;
    // console.log(this.$df.drawflow.drawflow["Fluxograma"].data[this.id].data);
    // This is to avoid changing the v-model of the activity if the cancel button is pressed
      this.stepNameAct = data.data.stepName.trim();
      this.stepTypeAct = data.data.stepType;
      this.messageAct = data.data.message.trim();
      this.methodIntegrationAct = data.data.stepMethodIntegration;
      this.integrationAct = data.data.stepIntegration.trim();
      this.integrationParameterAct = data.data.stepIntegrationParameter;
      this.integrationHeadersAct = data.data.stepIntegrationHeaders;
      this.integrationAuthorizeAct = data.data.stepIntegrationAuthorize;
      this.calculationAct = data.data.stepCalculation.trim();
      this.imgURLAct = data.data.stepImgURL.trim();
      this.operationVariableAct = data.data.stepOperationVariable.trim();
      this.validationAct = data.data.stepValidation.trim();
    });
    this.tipoAtividades = this.getListStepType;
  },
};
</script>