import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from "vue-i18n"
import en from "../src/translations/locales/en.json"
import es from "../src/translations/locales/es.json"
import pt from "../src/translations/locales/pt.json"

import './plugins/axiso'
import './plugins/signalR'

const messages = {
  en,
  es,
  pt
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
