<template>
    <div v-if="functionality.functionality_Level >= 2">
        <v-row>
            <v-col cols="12" md="6">
                <v-card v-if="reason != null">
                    <v-card-title>{{ $t('NewReason.Reason') }}</v-card-title>
                    <v-card-text>
                        {{ $t('NewReason.name') }}
                        <v-text-field
                            v-model="reason.reason_description"
                            solo
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>{{ $t('NewReason.SubReason') }}</v-card-title>
                    <v-card-text>
                        <v-data-table 
                            :headers="headers"
                            :items="subReasons"
                            ::sort-by-text="$t(headers.text)"
                            class="elevation-1">
                            <template #[`item.enable`]="props">
                                <v-btn @click="edit(props.item.sub_reason_id)" icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-card-actions style="float: right">
                    <v-btn
                        :block="$vuetify.breakpoint.width < '960' ? true : false"
                        color="primary"
                        @click="newSubreason"
                        >
                        {{ $t('NewReason.SubReasons.new') }}
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row v-if="showSubReasonForm">
            <v-col>
                <v-card>
                    <v-card-title>{{ $t('NewReason.SubReasons.Information') }}</v-card-title>
                    <v-card-text>
                        {{ $t('NewReason.SubReasons.Name') }}
                        <v-text-field
                            v-model="subreasonName"
                            solo
                        ></v-text-field>
                    </v-card-text>
                </v-card>
                <v-card-actions style="float: right">
                    <v-btn
                        :block="$vuetify.breakpoint.width < '960' ? true : false"
                        color="primary"
                        @click="saveNewSubreason"
                        >
                        {{ $t('Guid.save') }}
                    </v-btn>
                    <v-btn
                        :block="$vuetify.breakpoint.width < '960' ? true : false"
                        color="primary"
                        @click="closeNewSubreason"
                        >
                        {{ $t('Guid.close') }}
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row>
            <v-card-actions style="float: right">
                <v-btn
                :block="$vuetify.breakpoint.width < '960' ? true : false"
                color="primary"
                @click="save"
                >
                    {{ $t('Guid.save') }}
                </v-btn>
                <v-btn
                :block="$vuetify.breakpoint.width < '960' ? true : false"
                color="red"
                @click="closeReason"
                >
                    {{ $t('Guid.close') }}
                </v-btn>
            </v-card-actions>
        </v-row>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ $t(snackText) }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default { 

    props: ["id"],
    data: () => ({
        reason: null,
        subReasons: [],
        enable: false,
        showSubReasonForm: false,
        subreasonName: "",
        subReasonId: 0,

        snack: false,
        snackColor: "",
        snackText: "",

        functionality: [],
        havePermissions: false
    }),
    methods: {
        getSubReasonOfReason() {
            this.subReasons = this.getCurrentSubReasons.filter(sub => sub.reason_id == this.id)
        },
        currentReason() {
            this.reason = this.getCurrentReasons.find(reason => reason.reason_id == this.id)
        },
        closeReason() {
            this.$router.push({ name: 'reasons' });
        },
        save() {
           let url = `/Reason_Att/Reasons?customer_id=${localStorage.getItem("customer_Id")}&Reason_Id_Int=${this.reason.reason_id}&Reason_Description_Var=${this.reason.reason_description}`;
           this.$http
                .get(url,{
                    headers: {
                        accept: "*/*",
                    }
                })
                .then(() => {
                    this.$router.push({ name: "reasons" });
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        newSubreason() {
            this.showSubReasonForm = true;
        },
        edit(id) {
            this.showSubReasonForm = true;
            this.subReasonId = id;
            let currentSubReason = this.subReasons.find(i=> i.sub_reason_id == this.subReasonId)
            this.subreasonName = currentSubReason.sub_reason_description;
        },
        saveNewSubreason() {
            let url = `/SubReason_Att/SubReasons?customer_Id=${localStorage.getItem("customer_Id")}&Sub_Reason_Id_Int=${this.subReasonId}&Reason_Id_Int=${this.reason.reason_id}&Sub_Reason_Description_Var=${this.subreasonName}`
            if (this.reason.reason_id !== 0) {
                this.$http
                .get(url, {
                    headers: {
                        accept: "*/*",
                    }
                })
                .then(() => {
                    this.$store.dispatch('ListSubreason', localStorage.getItem("customer_Id"));
                    this.showSubReasonForm = false;
                    this.snack = true;
                    this.snackColor = "success";
                    this.snackText = "NewReason.SubReasons.snackTextSuccess";
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "NewReason.SubReasons.snackTextError";
            }
        },
        closeNewSubreason() {
            this.showSubReasonForm = false;
            this.subReasonId = 0;
            this.subreasonName = ""
        },
        async setInitialVars() {
            this.listCustomers = this.getListDataInformation;
            if (this.$store.state.currentCustomer == null) {
                this.client = this.getListDataInformation[0];
            } else {
                this.client = this.$store.state.currentCustomer;
            }
            this.functionality = this.getListUserFunctions.find(
                (func) => func.functionality_Name == 'Reasons'
            );
            if (this.functionality.functionality_Level < 2) {
                this.$router.push({ name: 'painelCliente' });
            }

            this.havePermissions = false;
            this.getSubReasonOfReason()
            if (this.id) {
                this.currentReason()
            } else {
                this.reason = {
                    reason_id: 0,
                    "reason_description": ""
                }
            }
        }
    },
    computed: {
        headers() {
            return [
            {
                text: this.$t("NewReason.SubReasons.sub_reason_id"),
                value: "sub_reason_id",
                width: "14%",
                align: "center",
                class: "header",
                sortable: false,
            },
            {
                text: this.$t("NewReason.SubReasons.sub_reason_description"),
                value: "sub_reason_description",
                width: "16%",
                align: "center",
                class: "header",
            },
            {
                text: this.$t("NewReason.SubReasons.edit"),
                value: "enable",
                width: "14%",
                align: "center",
                class: "header",
                sortable: false,
            },
            ]
        },
        ...mapGetters(['getCurrentSubReasons']),
        ...mapGetters(['getCurrentReasons']),
        ...mapGetters(['getListUserFunctions'])
    },
    async mounted() {
        this.havePermissions = true;
        await this.setInitialVars()
    },
    watch: {
        getCurrentSubReasons() {
            this.subReasons = this.getCurrentSubReasons.filter(sub => sub.reason_id == this.id)
        }
    }
}
</script>

<style>
</style>