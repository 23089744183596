<template>
    <div v-if="functionality.functionality_Level >= 2">
        <v-container fluid class="pa-0">
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="templates"
                        :sort-by-text="$t(headers.text)"
                        disable-pagination
                        hide-default-footer
                        class="elevation-1">
                        <template #[`item.enable`]="props">
                            <v-btn :disabled="isDisabled" @click="edit(props.item.message_Id)" icon>
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <v-btn
            :disabled="isDisabled"
            @click="$router.push({ name: 'newMessageTemplate' })"
            style="float: right"
            class="my-2"
            color="primary"
            >{{ $t('MessagesTemplate.newTemplate') }}</v-btn
        >
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ $t(snackText) }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" text @click="snack = false"> {{ $t('ListCampaign.close') }} </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
    data() {
        return {
            snack: false,
            snackColor: "",
            snackText: "",
            client: "",

            token: JSON.parse(localStorage.getItem("authUser")).token,
            templates: [],
            functionality: [],
            isDisabled: false
        }
    },
    methods: {
        edit(id) {
            this.$router.push({ name: "updateMessageTemplate", params: { id: id } });
        },
        listTemplates() {
            this.$store.commit('setCurrentCostumer', this.client);
            localStorage.setItem("customer_Id", localStorage.getItem("customer_Id"));
        },
        setInitialVars() {
            this.listCustomers = this.getListDataInformation;
            if (this.$store.state.currentCustomer == null) {
                this.client = this.getListDataInformation[0];
            } else {
                this.client = this.$store.state.currentCustomer;
            }
            this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Template");
            if (this.functionality.functionality_Level < 2) {
                router.push({ name: 'painelCliente' })
            } else {
                this.listTemplates();
            }
            this.havePermissions = false;
            this.templates = this.getMessagesTemplates.filter(f => f.enable == true)
        }
    },
    mounted() {
        if(this.$store.state.permissions.length == 0) {
        setTimeout(() => {
            this.setInitialVars();
        },
        1000);
        } else {
            this.setInitialVars();
        }
    },
    computed: {
        headers() {
            return [
            {
                text: this.$t("MessagesTemplate.id"),
                value: "message_Id",
                width: "14%",
                align: "center",
                class: "header",
                sortable: false,
            },
            {
                text: this.$t("MessagesTemplate.templateName"),
                value: "message_Template_Name",
                width: "16%",
                align: "center",
                class: "header",
            },
            {
                text: this.$t("MessagesTemplate.message"),
                value: "message_Template",
                width: "16%",
                align: "center",
                class: "header",
            },
            {
                text: this.$t("MessagesTemplate.edit"),
                value: "enable",
                width: "14%",
                align: "center",
                class: "header",
                sortable: false,
            },
            ]
        },
        ...mapGetters(['getListDataInformation']),
        ...mapGetters(['getListUserFunctions']),
        ...mapGetters(['getCurrentCustomer']),
        ...mapGetters(['getMessagesTemplates']),
    },
    watch: {
        getCurrentCustomer() {
            this.client = this.getCurrentCustomer;
        },
        functionality() {
            this.isDisabled = this.functionality.functionality_Level == 2 ? true : false
        },
        getMessagesTemplates() {
            this.templates = this.getMessagesTemplates.filter(f => f.enable == true)
            return this.templates;
        }
    }}
</script>
<style>
</style>