<template>
  <v-container v-if="functionality.functionality_Level >= 2">
    <v-row>
      <v-col cols="12" v-if="functionality.functionality_Level >= 2">
        <v-card>
          <v-card-title>{{ $t('MenuPainelFluxo.listCustomersConfiguration') }}</v-card-title>
          <v-card-text>
            <v-select
              :disabled="isImporting"
              @change="listarFluxo"
              return-object
              v-model="client"
              :items="listCustomers"
              item-text="customer_Name"
              item-value="customer_Id"
              :label="$t('MenuPainelFluxo.clientName')"
              required
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-autocomplete
            v-model="fluxoSearch"
            :items="getListFlows"
            item-text="flow_Name"
            return-object
            dense
            filled
            :label="$t('MenuPainelFluxo.search')"
          ></v-autocomplete>
          <v-list style="max-height: 70vh; overflow: auto" class="mt-n6">
            <v-list-group :value="true" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('MenuPainelFluxo.flows') }}</v-list-item-title>
                </v-list-item-content>
              </template>
      
              <v-list-item
               :disabled="isImporting"
                style="min-height: 0"
                class="d-flex flex-column px-0 py-5"
                v-for="(fluxo, i) in getListFlows"
                :key="i"
                link
                @click="routeWithNewInformation(i)"
              >
                <v-list-item-title>{{ fluxo.flow_Name }}</v-list-item-title>
                <v-list-item-subtitle v-if="fluxo.end_DateTime != '0001-01-01T00:00:00'">
                  {{ $t('MenuPainelFluxo.date') }} {{ fluxo.end_DateTime | formatData }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-btn
            @click="$router.push({ name: 'create-fluxo' }, () => {})"
            :disabled="(client == null || isImporting)"
            color="primary"
            class="mt-1"
            style="float: right"
            >{{ $t('MenuPainelFluxo.register') }}</v-btn
          >
          <v-btn
            v-if="!isImporting"
            @click="onButtonClick"
            :disabled="(client == null || isImporting)"
            color="blue-grey"
            class="mt-1"
            style="float: left"
            >{{ $t('Guid.import') }}
            <v-icon
              right
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
          <v-btn
            v-if="isImporting"
            @click="onButtonClick"
            :disabled="(client == null || isImporting)"
            color="blue-grey"
            class="mt-1"
            style="float: left"
            >{{ $t('Guid.importing') }}
            <v-icon
              right
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
          <input 
            @change="onFileChanged" 
            ref="fileUpload" 
            class="d-none" 
            type="file" />
        </v-card>
      </v-col>
    </v-row>
  
  </v-container>
  <div v-else>
    <v-dialog v-model="havePermissions">
      <v-card>
        <v-progress-linear
          indeterminate
          color="blue darken-2"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      token: JSON.parse(localStorage.getItem("authUser")).token,
      fluxoSearch: "",
      listCustomers: [],
      client: null,
      functionality: [],
      havePermissions: false,

      // Import flows prop
      importNewFlow: false,
      selectedFile: null,
      exportedFlowId: 0,
      oldStepIds: [],
      newStepIds: [],
      isImporting: false
    };
  },
  computed: {
    ...mapGetters(['getListFlows']),
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions'])
  },
  methods: {
    routeWithNewInformation(index) {
      this.$router.push({
        name: 'update-fluxo',
        params: {
          id: this.getListFlows[index].flow_Id,
          name: this.getListFlows[index].flow_Name,
          channel: this.getListFlows[index].channel_Id,
          message: this.getListFlows[index].identify_Initial_Message,
          flowExitMessage: this.getListFlows[index].exit_String == "" ? "Salida": this.getListFlows[index].exit_String,
          startDate: this.getListFlows[index].start_DateTime,
          endDate: this.getListFlows[index].end_DateTime,
          flowLeaveMessage: this.getListFlows[index].exit_Message == "" ?  "Hasta luego, muchas gracias por comunicarse con nosotros" : this.getListFlows[index].exit_Message
        },
      },
      () => {})
                
    },
    listarFluxo() {
      localStorage.setItem("customer_Id", this.client.customer_Id);
      this.$store.commit('setCurrentCostumer', this.client);
      this.$store.dispatch('listarFluxo', localStorage.getItem("customer_Id"));
      this.$store.dispatch('listGroup', localStorage.getItem("customer_Id"));
      this.$store.dispatch('campaignList', localStorage.getItem("customer_Id"));
      this.$store.dispatch('listStepType', localStorage.getItem("customer_Id"));
    },
    setInitialVars() {

      this.listCustomers = this.getListDataInformation;
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Flow");

      if (this.$store.state.currentCustomer == null) {
        if(this.getListDataInformation.length > 1) {
          this.client = null;
        } else if(this.getListDataInformation.length == 1) {
          this.client = this.getListDataInformation[0];
        } else if(this.getListDataInformation.length == 0) {
          this.client = null;
        }
      } else {
        this.client = this.$store.state.currentCustomer;
      }

      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' })
      }

      this.listarFluxo();
      this.havePermissions = false;

    },
    onFileChanged(e) {
      let stepProcessed = [];
      this.isImporting = true;
      this.selectedFile = e.target.files[0];
      let stringInFile = new FileReader();
      let listSteps = [];
      let newStep = {};
      let flowName = "";
      let flowId = 0;
      let channel = 0;
      let initial_Id = 0;
      let identify_Message = "";
      let startDateTime = "";
      let exitString = "";
      let exitMessage = "";

      stringInFile.addEventListener('load', async () => {
        try {
          const text = stringInFile.result;
  
          const json = JSON.parse(text);

          for(let i = 0; i < json.length; i++) { 
            if (json[i].step_Name != "Exit") { 

              let index = stepProcessed.indexOf(json[i].step_Id);

              if (index == -1) {
                
                newStep = {
                  step_Id: json[i].step_Id,
                  step_Name: json[i].step_Name,
                  step_Type: json[i].step_Type_Id,
                  step_Message: json[i].step_Message,
                  step_Integration_Method: json[i].integration_Method,
                  step_Integration_Parameter: json[i].integration_Parameter,
                  step_Integration_Headers: json[i].stepIntegrationHeaders,
                  step_Integration_Authorize: json[i].integration_Header,
                  step_Integration: json[i].call_Integration,
                  step_Calculation: json[i].calculate,
                  step_Image_URL: json[i].image_URL,
                  step_Operational_Variable: json[i].operational_Variable_Name,
                  step_Validation: json[i].validation_Answer,
                  pos_X: json[i].pos_X.toFixed(0),
                  pos_Y: json[i].pos_Y.toFixed(0),
                  additional_var: "",
                  initial_Step: json[i].step_Name == "Start" ? true : false,
                  transitions: [
                    {
                      transition_Value: json[i].answer,
                      next_Flow_Id : 0,
                      next_Step_Id : json[i].next_Step_Id,
                      variable_Value : json[i].variable_Value
                    }
                  ]
                }

                listSteps.push(newStep);
                
                stepProcessed.push(json[i].step_Id);

              } else {
                listSteps[index].transitions.push({
                  transition_Value: json[i].answer,
                  next_Flow_Id : 0,
                  next_Step_Id : json[i].next_Step_Id,
                  variable_Value : json[i].variable_Value
                })
              }
            } else {
                flowName = json[i].flow_Name;
                flowId = json[i].flow_Id;
                channel = json[i].channel_Id;
                initial_Id = json[i].initial_Step_Id;
                identify_Message = json[i].identify_Message;
                startDateTime = json[i].flow_Start;
                exitString = json[i].answer;
                exitMessage = json[i].step_Message;
            }
          }

          let customer = localStorage.getItem("customer_Id")

          let Data_Flow_Upd = {
            customer_Id: customer,
            flow_Id: flowId,
            flow_Name: flowName,
            channel_Id: channel,
            initial_Step_Id: initial_Id,
            identify_Initial_Message: identify_Message,
            start_DateTime: startDateTime,
            end_DateTime: null,
            exit_String: exitString,
            exit_Message: exitMessage,
            steps: listSteps
          }

          let config = {
            headers: {
              'Authorization': `bearer ${this.token}`,
              'Content-Type': 'application/json'
            }
          }
  
          await this.$http
            .post('Data_Flow_Update', Data_Flow_Upd, config)
          .then(() =>{
            this.isImporting = false;
            this.listarFluxo();
          })
          .catch((e) =>{
            console.log(e)
          });

        } catch (error) {
          console.log(error);
        }

      });
      
      stringInFile.readAsText(this.selectedFile);
    },
    onButtonClick() {
      window.addEventListener('focus', () => {}, {
        once: true
      })
      this.$refs.fileUpload.click()
    },
  },
  filters: {
    formatData(value) {
      if (!value) return "";
      value = value.split("T")[0].split("-").reverse().join("/");
      return value;
    },
  },
  watch: {
    fluxoSearch(value) {
      value != ""
        ? this.$router.push(
            {
              name: "view-fluxo",
              params: {
                id: value.flow_Id,
                name: value.flow_Name,
                channel: value.channel_Id,
                flowMessage: value.identify_Initial_Message,
                startDate: value.start_DateTime,
                endDate: value.end_DateTime,
                flowExitMessage: value.exit_String,
                flowLeaveMessage: value.exit_Message
              },
            },
            () => {}
          )
        : false;
    },
  },
  mounted() {
    this.havePermissions = true;
    if(this.$store.state.permissions.length == 0) {
      setTimeout(() => {
        this.setInitialVars();
      },
      1000);
    } else {
      this.setInitialVars();
    }
  },
};
</script>

<style>
</style>