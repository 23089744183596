<template>
    <div class="locale-changer">
        <v-row aling="center">
            <v-icon>mdi mdi-translate</v-icon>
            <select v-model="$i18n.locale">
                <option value="" disabled>{{ $t('SelectorLenguaje.translation') }}</option>
                <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.value"> 
                    {{ $t(lang.label) }} 
                </option>
            </select>
        </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: 'locale-changer',
    data () {
      return { 
        langs: [
            {
                label:'SelectorLenguaje.portuguese',
                value:'pt'
            }, 
            {
                label:'SelectorLenguaje.english',
                value:'en'
            }, 
            {
                label:'SelectorLenguaje.spanish',
                value:'es'
            }
        ]}
    }
  }
  </script>