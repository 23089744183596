import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    myFlows: [],
    myReports: [],
    token: null,
    permissions: [],
    currentCustomer: null,
    groups: [],
    campaigns: [],
    stepTypes: [],
    messagesTemplates: [],
    files: [],
    current_Report:null,
    tokenMetabaseCurrentReport:null,
    sound:true,
    disconnectedInactivity: false,
    disconnectedByNetworkError: false,
    panelChannels: [],
    reasons: [],
    subReasons: []
  },
  getters: {
    getListReports(state) {
      return state.myReports
    },
    getListFlows(state) {
      return state.myFlows
    },
    getListDataInformation(state) {
      return state.permissions.customer
    },
    getListUserFunctions(state) {
      return state.permissions.user_Function
    },
    getListGroups(state) {
      return state.groups
    },
    getListCampaigns(state) {
      return state.campaigns
    },
    getListStepType(state) {
      return state.stepTypes
    },
    getCurrentCustomer(state) {
      return state.currentCustomer
    },
    getUserInformation(state) {
      return state.permissions
    },
    getMessagesTemplates(state) {
      return state.messagesTemplates
    },
    getCurrentFiles(state) {
      return state.files
    },
    getCurrentReport(state) {
      return state.current_Report
    },
    getTokenMetabaseCurrentReport(state) {
      return state.tokenMetabaseCurrentReport
    },
    getChannelsForPanel(state) {
      return state.panelChannels
    },
    getCurrentReasons(state){
      return state.reasons
    },
    getCurrentSubReasons(state) {
      return state.subReasons
    }
  },
  mutations: {
    setDisconnectedInactivity(state,data){
      state.disconnectedInactivity = data;
    },
    setDisconnectedByNetworkError(state,data) {
      state.disconnectedByNetworkError = data;
    },
    setListReports(state,data){
      state.myReports = data;
    },
    setListFlows(state, data) {
      state.myFlows = data;
    },
    setPermisions(state, data) {
      state.permissions = data;
    },
    setCurrentCostumer(state, data) {
      state.currentCustomer = data;
    },
    setGroups(state, data) {
      state.groups = data;
    },
    setCampaigns(state, data) {
      state.campaigns = data;
    },
    setStepTypes(state, data) {
      state.stepTypes = data;
    },
    setMessagesTemplates(state, data) {
      state.messagesTemplates = data
    },
    setLoadFiles(state, data) {
      state.files = data;
    },
    setCurrent_Report(state,report){
      state.current_Report = report
    },
    setTokenMetabaseCurrent_Report(state,token){
      state.tokenMetabaseCurrentReport = token
    },
    setSoundBell(state,sound){
      state.sound = sound
    },
    setInitialData(state) {
      state.permissions = [];
      state.currentCustomer = null;
      state.groups = [];
      state.campaigns = [];
      state.stepTypes = [];
      state.messagesTemplates = [];
      state.files = [];
      state.current_Report = null;
      state.tokenMetabaseCurrentReport = null;
      state.sound = true;
    },
    setChannelsForPanel(state, data) {
      state.panelChannels = data
    },
    setReasons(state, data) {
      state.reasons = data;
    },
    setSubReason(state, data) {
      state.subReasons = data;
    }
  },
  actions: {
    async listarFluxo({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`List_Flow?Active_Flow=true&Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          let fluxos = r.data.reverse();
          commit('setListFlows',fluxos);
        })
        .catch(() => {
          this.logout();
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async getPermissions({commit}, idUser){
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      return await axios
        .get(
          `User_Data_Perm?user_id=${idUser}`,
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${token}`,
            }
          }
        )
        .then((r) => {
          commit('setPermisions', r.data);
          commit('setListReports', r.data.report)
          commit('setChannelsForPanel', r.data.channel)
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {

        });
    },
    async listGroup({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`User_Att_Group_List?Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setGroups', r.data);
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async campaignList({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`Campaign_List?Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setCampaigns', r.data);
        })
        .catch(() => {
          //console.log("error obteniendo la lista de las campañas",e)
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async listStepType({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`List_Step_Type?Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setStepTypes', r.data);
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async listMessagesTemplates({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`Message_Template_List?Customer_Id=${idClient}`, {
        headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
        },
        })
        .then((r) => {
            commit('setMessagesTemplates', r.data)
        })
        .catch(() => {
        //this.error = true
        })
        .finally(() => {
        //this.loading = false
        });
    },
    async listLoadFiles({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`Att_File_List?Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setLoadFiles', r.data);
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async GetTokenMetabase({commit}, data) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`Token_Metabase?customer_Id=${data.customer_id}&visualization_type=${data.visualization_type}&visualization_id=${data.visualization_id}&key=${data.key}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setTokenMetabaseCurrent_Report', r.data);
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async ListReason({commit}, idClient) {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`Reason_Att?Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setReasons', r.data);
        })
        .catch(() => {
          // if(error.response.status == 401) {
          //   this.$store.commit('setDisconnectedByNetworkError', true)
          //   this.logout()
          // }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async ListSubreason({commit}, idClient){
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios
        .get(`SubReason_Att?Customer_Id=${idClient}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${token}`,
          },
        })
        .then((r) => {
          commit('setSubReason', r.data);
        })
        .catch(() => {
          // if(error.response.status == 401) {
          //   this.$store.commit('setDisconnectedByNetworkError', true)
          //   this.logout()
          // }
        })
        .finally(() => {
          //this.loading = false
        });
    }
  },
  modules: {
  }
})
