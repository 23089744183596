<template>
  <div v-if="functionality.functionality_Level >= 2">
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" v-if="functionality.functionality_Level >= 2">
          <v-card>
            <v-card-title>{{ $t('ListCampaign.listCustomersConfiguration') }}</v-card-title>
            <v-card-text>
              <v-radio-group v-model="typeList" row>
                <v-radio :label="$t('ListCampaign.all')" :value="true"></v-radio>
                <v-radio :label="$t('ListCampaign.active')" :value="false"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="currentCampaigns"
            :sort-by-text="$t(headers.text)"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template #[`item.channel_Id`]="props">
              <v-btn :disabled="isDisabled" @click="edit(props.item.campaign_Id)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>


    <v-btn
      :disabled="isDisabled"
      @click="$router.push({ name: 'campaign' })"
      style="float: right"
      class="my-2"
      color="primary"
      >{{ $t('ListCampaign.newOperator') }}</v-btn
    >

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ $t(snackText) }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> {{ $t('ListCampaign.close') }} </v-btn>
      </template>
    </v-snackbar>
  </div>
  <div v-else>
    <v-dialog v-model="havePermissions">
      <v-card>
          <v-progress-linear
          indeterminate
          color="blue darken-2"
          ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
  import router from '@/router';
  import { mapGetters } from 'vuex';
  
  export default {
    data() {
      return {
        snack: false,
        snackColor: "",
        snackText: "",
  
        token: JSON.parse(localStorage.getItem("authUser")).token,
        
        typeList: false,
        grupos: [],
        gruposAtual: [],
        campaigns: [],
        client: "",
        functionality: [],
        listCustomers : [],
        havePermissions: false,

        isDisabled: false
      };
    },
    methods: {
      edit(id) {
        this.$router.push({ name: "updateCampaign", params: { id: id } });
      },
      listCampaign() {
        this.$store.commit('setCurrentCostumer', this.client);
        localStorage.setItem("customer_Id", localStorage.getItem("customer_Id"));
        this.$store.dispatch('campaignList', localStorage.getItem("customer_Id"));
      },
      setInitialVars() {
        this.listCustomers = this.getListDataInformation;
        if (this.$store.state.currentCustomer == null) {
          this.client = this.getListDataInformation[0];
        } else {
          this.client = this.$store.state.currentCustomer;
        }
        this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Campaign");
        if (this.functionality.functionality_Level < 2) {
          router.push({ name: 'painelCliente' })
        } else {
          this.campaigns = this.getListCampaigns;
        }
        this.havePermissions = false;
        this.campaigns = this.getListCampaigns;
      },
      getLocalDate(start_Queue) {
        let newDate = new Date(start_Queue + 'Z');
        let formatDate = 
          [
            newDate.getFullYear(),
            newDate.getMonth()+1,
            newDate.getDate()
          ].join('-') +' ' +
          [newDate.getHours(),
            newDate.getMinutes(),
            newDate.getSeconds()].join(':').toString();
            
        return formatDate;
      },
    },
    mounted() {
      this.havePermissions = true;
      if(this.$store.state.permissions.length == 0) {
        setTimeout(() => {
          this.setInitialVars();
        },
        1000);
      } else {
        this.setInitialVars();
      }
    },
    computed: {
      headers() {
        return [
          {
            text: this.$t("ListCampaign.campaignId"),
            value: "campaign_Id",
            width: "14%",
            align: "center",
            class: "header",
            sortable: false,
          },
          {
            text: this.$t("ListCampaign.campaign"),
            value: "campaign_Name",
            width: "16%",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("ListCampaign.agentAvailable"),
            value: "enable_Agent",
            width: "14%",
            align: "center",
            class: "header",
            sortable: false,
          },
          {
            text: this.$t("ListCampaign.flow"),
            value: "flow_Name",
            width: "20%",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("ListCampaign.startDate"),
            value: "start_Date",
            width: "16%",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("ListCampaign.endDate"),
            value: "end_Date",
            width: "16%",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("ListCampaign.edit"),
            value: "channel_Id",
            width: "14%",
            align: "center",
            class: "header",
            sortable: false,
          },
        ]
      },
      currentCampaigns(){
        if (this.typeList) {
          return this.getListCampaigns
        }
        else {
          let currentActiveCampaigns = []

          for(var i=0;i< this.getListCampaigns.length; i++){ //Save active campaigns
              if(Date.parse(this.getLocalDate(this.getListCampaigns[i].start_Date)) < Date.now() && 
              Date.now() < Date.parse(this.getLocalDate(this.getListCampaigns[i].end_Date)) ){
                currentActiveCampaigns.push(this.getListCampaigns[i])
              }
            }
          return currentActiveCampaigns
        }
      },  
      ...mapGetters(['getListDataInformation']),
      ...mapGetters(['getListUserFunctions']),
      ...mapGetters(['getListCampaigns']),
      ...mapGetters(['getCurrentCustomer'])
    },
    watch: {
      getCurrentCustomer() {
        this.client = this.getCurrentCustomer;
      },
      functionality() {
        this.isDisabled = this.functionality.functionality_Level == 2 ? true : false
      },
      getListDataInformation() {
        this.listCustomers = this.getListDataInformation
      }
    }
  };
  </script>
  
  <style>
  .header :first-child {
    font-size: 17px;
    padding-left: 18px;
    color: black;
  }
  </style>