<template>
  <div v-if="functionality.functionality_Level >= 2">
    <v-container fluid class="pa-0">
      <v-row>        
        <v-col>
          <v-data-table
            :headers="headers"
            :items="operadores"
            :sort-by-text="$t(headers.text)"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template #[`item.attendence_Group_Name`]="props">
              <v-edit-dialog 
                large
                :return-value.sync="props.item.attendence_Group_Name"
                @save="save(props.item.user_Id)"
              >
                <v-btn :disabled="isDisabled" @click="detalhesOperador(props.item.user_Id)" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                <template v-slot:input>
                  <div class="my-4 text-h6">{{ $t('ListaOperadores.changeGroup') }}</div>
                  <v-autocomplete :disabled="isDisabled"
                    return-object 
                    v-model="gruposAtual"
                    :items="grupos"
                    item-text="attendence_Group_Name"
                    item-value="attendence_Group_Id"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    rounded
                    solo
                    multiple
                  >
                  </v-autocomplete>
                </template>
              </v-edit-dialog>
            </template>
            <template #[`item.user_Id`]="props">
              <v-btn :disabled="isDisabled" @click="edit(props.item.user_Id)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      :disabled="isDisabled"
      @click="$router.push({ name: 'cadastro' })"
      style="float: right"
      class="my-2"
      color="primary"
      >{{ $t('ListaOperadores.newOperator') }}</v-btn
    >

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ $t(snackText) }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> {{ $t('ListaOperadores.close') }} </v-btn>
      </template>
    </v-snackbar>
    
  </div>
  <div v-else>
    <v-dialog v-model="havePermissions">
      <v-card>
          <v-progress-linear
          indeterminate
          color="blue darken-2"
          ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",

      token: JSON.parse(localStorage.getItem("authUser")).token,

      grupos: [],
      gruposAtual: [],
      gruposAtualCp: [],
      operadores: [],
      client: "",
      functionality: [],
      listCustomers : [],
      havePermissions: false,
      tempId: -1,

      isDisabled: false
    };
  },
  methods: {
    edit(id) {
      this.$router.push({ name: "atualizar", params: { id: id } });
    },
    detalhesOperador(id) {
      this.$http
        .get(`User_Att_Details?User_Id=${id}&Customer_Id=${this.client.customer_Id}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.gruposAtual = r.data.group_List;
          this.gruposAtual = this.gruposAtual.filter(function (elem) {
            /* função que remove operadores multiplicados */ return (
              !this[JSON.stringify(elem.attendence_Group_Id)] &&
              (this[JSON.stringify(elem.attendence_Group_Id)] = true)
            );
          }, Object.create(null));

          this.tempId = id;
          this.gruposAtualCp = this.gruposAtual;
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    ativarGrupo(idUser, idGrupo) {
      this.$http
        .post(
          `User_Att_Group_Activate?User_Id=${idUser}&Attendence_Group_Id=${idGrupo.attendence_Group_Id}&Customer_Id=${this.client.customer_Id}`,
          {},
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then(() => {
          //console.log(r.data);
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    desativarGrupo(idUser, idGrupo) {
      this.$http
        .post(
          `User_Att_Group_Deactivate?Customer_Id=${this.client.customer_Id}&User_Id=${idUser}&Attendence_Group_Id=${idGrupo.attendence_Group_Id}`,
          {},
          {
            headers: {
              accept: "*/*",
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then(() => {
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "ListaOperadores.savedChange";
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    save(idUser) {
      if (this.gruposAtual != this.gruposAtualCp){

        // Desactivate groups
        this.gruposAtualCp.forEach(gp => {
          if (this.gruposAtual.find(g => g == gp) == null) {
            this.desativarGrupo(idUser, gp);
          }
        })

        // Activate groups
        this.gruposAtual.forEach(ac => {
          if(this.gruposAtualCp.find(g => g == ac) == null) {
            this.ativarGrupo(idUser, ac);
          }
        })
      }
    },
    listaOperadores() {
      this.$store.dispatch('listGroup', localStorage.getItem("customer_Id"));
      localStorage.setItem("customer_Id", this.client.customer_Id);
      this.$http
        .get(`User_Att_List?Customer_Id=${this.client.customer_Id}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.grupos = this.getListGroups;
          this.operadores = r.data;
          this.operadores = this.operadores.filter(function (elem) {
            /* função que remove operadores multiplicados por causa do grupo */ return (
              !this[JSON.stringify(elem.user_Id)] &&
              (this[JSON.stringify(elem.user_Id)] = true)
            );
          }, Object.create(null));
        })
        .catch(() => {
          //this.error = true
        })
        .finally(() => {
          //this.loading = false
        });
    },
    setInitialVars() {
      this.listCustomers = this.getListDataInformation;
      if (this.$store.state.currentCustomer == null) {
        this.client = this.getListDataInformation[0];
      } else {
        this.client = this.$store.state.currentCustomer;
      }
      this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "User");
      if (this.functionality.functionality_Level < 2) {
        router.push({ name: 'painelCliente' })
      } else {
        this.listaOperadores();
      }
      this.havePermissions = false;
    }
  },
  mounted() {
    this.havePermissions = true;
    if(this.$store.state.permissions.length == 0) {
      setTimeout(() => {
        this.setInitialVars();
      },
      1000);
    } else {
      this.setInitialVars();
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("ListaOperadores.user"),
          value: "user_Login",
          width: "16%",
          align: "center",
          class: "header",
        },
        {
          text: this.$t("ListaOperadores.fullName"),
          value: "user_Name",
          width: "20%",
          align: "center",
          class: "header",
        },
        {
          text: this.$t("ListaOperadores.startDate"),
          value: "user_Start",
          width: "200px",
          align: "center",
          class: "header",
        },
        {
          text: this.$t("ListaOperadores.endDate"),
          value: "user_End",
          width: "200px",
          align: "center",
          class: "header",
        },
        {
          text: this.$t("ListaOperadores.group"),
          value: "attendence_Group_Name",
          width: "14%",
          align: "center",
          class: "header",
          sortable: false,
        },
        {
          text: this.$t("ListaOperadores.edit"),
          value: "user_Id",
          width: "14%",
          align: "center",
          class: "header",
          sortable: false,
        },
      ]
    },
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListGroups']),
    ...mapGetters(['getCurrentCustomer'])
  },
  watch: {
    getCurrentCustomer() {
      this.client = this.getCurrentCustomer;
      
      this.listaOperadores()
    },
    functionality() {
      this.isDisabled = this.functionality.functionality_Level == 2 ? true : false
    },
    getListDataInformation() {
      this.listCustomers = this.getListDataInformation
    }
  }
};
</script>

<style>
.header :first-child {
  font-size: 17px;
  padding-left: 18px;
  color: black;
}
</style>