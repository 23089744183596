<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t('NewMessageTemplate.template') }}</v-card-title>
          <v-card-text>
            {{ $t('NewMessageTemplate.templateName') }}
            <v-text-field v-model="templateName" solo></v-text-field>
          </v-card-text>
          <v-card-text>
            {{ $t('NewMessageTemplate.templateMessage') }}
            <v-textarea v-model="templateMessage" solo></v-textarea>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  multiple
                  v-model="idGroup"
                  :items="groupList"
                  item-text="attendence_Group_Name"
                  item-value="attendence_Group_Id"
                  required
                  :label="$t('NewMessageTemplate.attendenceGroup')"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-radio-group v-model="enable" row>
              <v-radio
                :label="$t('NewMessageTemplate.active')"
                :value="true"
              ></v-radio>
              <v-radio
                :label="$t('NewMessageTemplate.unactive')"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-card-actions style="float: right">
        <v-btn
          :block="$vuetify.breakpoint.width < '960' ? true : false"
          color="primary"
          @click="save"
        >
          {{ $t('Guid.save') }}
        </v-btn>
        <v-btn
          :block="$vuetify.breakpoint.width < '960' ? true : false"
          color="red"
          @click="closeNewTemplate"
        >
          {{ $t('Guid.close') }}
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ $t(snackText) }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
  props: ['id'],
  data: () => ({
    templateName: '',
    templateMessage: '',
    groupList: [],
    idGroup: [],
    enable: false,

    token: JSON.parse(localStorage.getItem('authUser')).token,

    snack: false,
    snackColor: '',
    snackText: '',

    message: {},
  }),
  methods: {
    detalhesTemplate() {
      (this.message = this.getMessagesTemplates.find(
        (element) => element.message_Id == this.id
      )),
        (this.templateName = this.message.message_Template_Name),
        (this.templateMessage = this.message.message_Template),
        (this.idGroup = this.message.att_Group),
        (this.enable = this.message.enable);
    },
    save() {
      let url = `Message_Template_Update?Customer_Id=${localStorage.getItem(
        'customer_Id'
      )}&Message_Id=${this.id == null ? 0 : this.id}&Message_Template=${
        this.templateMessage
      }&Message_Template_Name=${this.templateName}&Enable=${this.enable}`;
      for (var i = 0; i < this.idGroup.length; i++) {
        url += `&att_Group=${this.idGroup[i]}`;
      }
      this.$http
        .get(url, {
          headers: {
            accept: '*/*',
            Authorization: `bearer ${this.token}`,
          },
        })
        .then(() => {
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'NewMessageTemplate.snackTextSuccess';
          this.emitReload();
          router.push({ name: 'messagesTemplate' });
        })
        .catch(() => {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = 'NewMessageTemplate.snackTextError';
        })
        .finally(() => {
          //this.loading = false
        });
    },
    closeNewTemplate() {
      this.$router.push({ name: 'messagesTemplate' });
    },
    emitReload() {
      this.$store.dispatch(
        'listMessagesTemplates',
        localStorage.getItem('customer_Id')
      );
    },
  },
  computed: {
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListGroups']),
    ...mapGetters(['getMessagesTemplates']),
  },
  mounted() {
    this.groupList = this.getListGroups;
    if (this.id) {
      this.detalhesTemplate();
    }
  },
};
</script>
