<template>
    <div v-if="functionality.functionality_Level >= 2">
        <v-container fluid class="pa-0">
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="getCurrentFiles"
                        :sort-by-text="$t(headers.text)"
                        disable-pagination
                        hide-default-footer
                        class="elevation-1">
                        <template #[`item.file_Id`]="props">
                            <v-btn @click="edit(props.item.file_Id)" icon>
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <v-btn
            @click="$router.push({ name: 'newUploadFile' })"
            style="float: right"
            class="my-2"
            color="primary"
            >{{ $t('LoadFiles.uploadFile') }}</v-btn
        >
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ $t(snackText) }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" text @click="snack = false"> {{ $t('ListCampaign.close') }} </v-btn>
            </template>
        </v-snackbar>
    </div>
    <div v-else>
    <v-dialog v-model="havePermissions">
      <v-card>
          <v-progress-linear
          indeterminate
          color="blue darken-2"
          ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("authUser")).token,
            snack: false,
            snackColor: "",
            snackText: "",

            havePermissions: false,
            client: "",
            functionality: [],
        }
    },
    methods: {
        edit(id) {
            this.$router.push({ name: "updateFile", params: { id: id } });
        },
        setInitialVars() {
            this.listCustomers = this.getListDataInformation;
            if (this.$store.state.currentCustomer == null) {
                this.client = this.getListDataInformation[0];
            } else {
                this.client = this.$store.state.currentCustomer;
            }
            this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Campaign");
            if (this.functionality.functionality_Level < 2) {
                this.$router.push({ name: 'painelCliente' })
            }
            this.havePermissions = false;
            this.getFileList();
        },
        getFileList() {
            this.currentFiles = this.getCurrentFiles;
        }
    },
    mounted() {
        this.havePermissions = true;
        if(this.$store.state.permissions.length == 0) {
        setTimeout(() => {
            this.setInitialVars();
        },
        1000);
        } else {
        this.setInitialVars();
        }
    },
    computed: {
        headers() {
            return [
            {
                text: this.$t("LoadFiles.fileName"),
                value: "file_Name",
                width: "16%",
                align: "center",
                class: "header",
            },
            {
                text: this.$t("LoadFiles.fileGroup"),
                value: "att_Group",
                width: "14%",
                align: "center",
                class: "header",
                sortable: false,
            },
            {
                text: this.$t("LoadFiles.edit"),
                value: "file_Id",
                width: "14%",
                align: "center",
                class: "header",
                sortable: false,
            },
            ]
        },
        ...mapGetters(['getListDataInformation']),
        ...mapGetters(['getListUserFunctions']),
        ...mapGetters(['getCurrentCustomer']),
        ...mapGetters(['getCurrentFiles']),
    },
    watch: {
        getCurrentCustomer() {
            this.client = this.getCurrentCustomer;
            this.currentFiles = this.getCurrentFiles;
        }
    }
}
</script>
<style>
</style>