import { render, staticRenderFns } from "./MenuTopGerente.vue?vue&type=template&id=69014528&scoped=true&"
import script from "./MenuTopGerente.vue?vue&type=script&lang=js&"
export * from "./MenuTopGerente.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69014528",
  null
  
)

export default component.exports