<template>
  <div class="vuec-PainelCliente"> 
    <v-container fluid class="pa-0">
      <v-col  cols="12">
        <v-row>
          <iframe
            :src="FrameReport"
            frameborder="0"
            :width="w_frame"
            :height="h_frame"
            allowtransparency
          ></iframe>
        </v-row>
        <v-row>
          <v-btn
            class="my-2"
            color="red"
            @click="closeCreate"
          >
              {{ $t('Guid.close') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>


<script>
import { mapGetters,mapActions  } from 'vuex';
  export default {
    props: {
    },
    data() {
      return {
        FrameReport:null,
        frame:null,
        w_frame:800,
        h_frame:600,
      }
    },
    async mounted() {
      this.frame = this.getCurrentReport
      await this.changeFrame()
    },
    created() {
      let anchoPantalla = window.innerWidth;
      let altoPantalla = window.innerHeight;
      this.w_frame = anchoPantalla * 0.8;
      this.h_frame = altoPantalla * 0.8;

    },
    computed:{
      ...mapGetters(['getCurrentReport','getTokenMetabaseCurrentReport'])
    },
    methods: {
      ...mapActions(['GetTokenMetabase']),
      closeCreate() {
        this.$router.push({ name: 'reports' });
      },
      async changeFrame(){
      this.client = localStorage.getItem("customer_Id")
      let jsonIframe = JSON.parse(this.getCurrentReport)
      
      let data = {
        customer_id:this.client,
        visualization_id:jsonIframe.visualization_id,
        visualization_type:jsonIframe.visualization_type,
        key:jsonIframe.METABASE_SECRET_KEY
      }

      await this.GetTokenMetabase(data);

      jsonIframe.iframeUrl = jsonIframe.iframeUrl.replace('@token',this.getTokenMetabaseCurrentReport)
      this.FrameReport = jsonIframe.iframeUrl;
    }
  },
  watch:{
    async getCurrentReport(){
      await this.changeFrame()
    }
  }
};

</script>