<template>
    <div v-if="functionality.functionality_Level >= 2">
        <v-container fluid class="pa-0">
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="getCurrentReasons"
                        :sort-by-text="$t(headers.text)"
                        disable-pagination
                        hide-default-footer
                        class="elevation-1"
                    >
                        <template #[`item.reason_id_pencil`]="props">
                        <v-btn :disabled="isDisabled" @click="edit(props.item.reason_id)" icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-btn
            :disabled="isDisabled"
            @click="$router.push({ name: 'newReason' })"
            style="float: right"
            class="my-2"
            color="primary"
            >{{ $t('Reason.new') }}
        </v-btn>
    </div>
    <div v-else>
    <v-dialog v-model="havePermissions">
      <v-card>
        <v-progress-linear
          indeterminate
          color="blue darken-2"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import router from '@/router';

export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("authUser")).token,
            client: "",
            reason: "Juan",
            reasons: [],
            isDisabled: false,

            functionality: [],
            havePermissions: false
        }
    },
    methods: {
        edit(id) {
            this.$router.push({ name: "updateReason", params: { id: id } });
            console.log(id)
        },
        async getListReason() {
            this.$http
                .get(`Reason_Att?Customer_Id=${this.client.customer_Id}`, {
                headers: {
                    accept: "*/*",
                    Authorization: `bearer ${this.token}`,
                },
            })
            .then((r) => {
                console.log(r.data)
                this.reasons = r.data;
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                //this.loading = false
            });
        },
        async setInitialVars() {
            this.listCustomers = this.getListDataInformation;
            if (this.$store.state.currentCustomer == null) {
                this.client = this.getListDataInformation[0];
            } else {
                this.client = this.$store.state.currentCustomer;
            }
            this.functionality = this.getListUserFunctions.find(func => func.functionality_Name == "Reasons");
            if (this.functionality.functionality_Level < 2) {
                this.$router.push({ name: 'painelCliente' })
            } else {
                this.getListReason();
            }
            this.havePermissions = false;
            this.$store.dispatch('ListReason', localStorage.getItem("customer_Id"));
        }
    },
    async mounted() {
        if(this.$store.state.permissions.length == 0) {
            setTimeout(async() => {
            await this.setInitialVars();
            },
            1000);
        } else {
            await this.setInitialVars();
        }
    },
    computed:{
        ...mapGetters(['getCurrentCustomer']),
        ...mapGetters(['getCurrentReasons']),
        ...mapGetters(['getListUserFunctions']),
        headers() {
        return [
          {
            text: this.$t("Reason.reason_id"),
            value: "reason_id",
            width: "14%",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("Reason.reason_description"),
            value: "reason_description",
            width: "16%",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("Reason.edit"),
            value: "reason_id_pencil",
            width: "14%",
            align: "center",
            class: "header",
            sortable: false,
          },
        ]
        },
    },
    watch: {
        getCurrentReasons() {
            this.reasons = this.getCurrentReasons;
        }
    }
}
</script>

<style>

</style>